import React from "react"

const CompanyList = ({ marginDisabled, gridSize, title, children }) => (
	<section className={"company-list module-margin" + (marginDisabled ? "module-margin-disabled " : "")}>
		<div className={"grid " + (gridSize ? gridSize : "")}>
			<div className="row">
				<div className="col">{title && <div className="t-h2 tac pb40">{title}</div>}</div>
			</div>
			<div className="row">
				<div className="col">{children}</div>
			</div>
		</div>
	</section>
)

export default CompanyList
