import React, { Component } from "react"

class FlipWarning extends Component {
	constructor(props) {
		super(props)
		this.state = {}

		this.handleFlip = this.handleFlip.bind(this)
	}

	handleFlip() {
		let isLandscape = window.orientation === 90 || window.orientation === -90,
			excludeTablets = window.innerHeight < 767

		// check if mobile device is in landscape mode and check that its not bigger than 767px height
		if (isLandscape && excludeTablets) {
			this.flipContainer.style.display = "block"
		} else {
			this.flipContainer.style.display = "none"
		}
	}

	componentDidMount() {
		this.handleFlip()
		window.addEventListener("resize", this.handleFlip)
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleFlip)
	}

	render() {
		return (
			<section
				className="flip-warning"
				ref={elm => {
					this.flipContainer = elm
				}}
			>
				<div className="h2">
					<span className="ico ico-font ico-flip" />
					Please rotate your device
				</div>
			</section>
		)
	}
}

export default FlipWarning
