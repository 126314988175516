import React from "react"
import HcCarousel from "./hcCarousel"

const GallerySlider = ({ options, slides }) => (
	<section className="gallery module-margin">
		<div className="grid med">
			<div className="row">
				<div className="col">
					<HcCarousel options={options}>
						{slides.map((slide, index) => {
							return (
								<article key={`gallery-${index}`}>
									<div className="media-background" title={slide.altTagData} style={{ backgroundImage: `url(${slide.image})` }} />
								</article>
							)
						})}
					</HcCarousel>
				</div>
			</div>
		</div>
	</section>
)

export default GallerySlider
