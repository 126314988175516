import React, { Component } from "react"
import Fade from "react-reveal/Fade"

class PresskitCard extends Component {
	constructor(props) {
		super(props)

		this.state = {
			cardImg: this.props.args.image
		}
	}

	render() {
		const {
			marginDisabled,
			delay,
			fraction,
			args: { fullname, image, jobtitle, url, imageAltText }
		} = this.props
		const { cardImg } = this.state
		const animationDuration = 300
		const animationFraction = 0.2

		return (
			<a href={url} download className={"presskit-card module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
				<div className="over-hidden">
					<Fade bottom delay={delay} duration={animationDuration} fraction={animationFraction}>
						<div className="image">
							<Fade delay={delay + animationDuration} duration={animationDuration} fraction={animationFraction}>
								<div className="image-container">
									{cardImg ? (
										<img
											src={image}
											onError={() => {
												this.setState({ cardImg: null })
											}}
											alt={imageAltText}
											className="img-responsive-max"
										/>
									) : null}
								</div>
							</Fade>
						</div>
					</Fade>
				</div>
				<div className="title">
					<span className="line" />
					<div className="t-h5">DOWNLOAD HEADSHOT &amp; BIO</div>
				</div>
				{fullname && (
					<div className="fullname">
						<div className="t-h3">{fullname}</div>
					</div>
				)}
				{jobtitle && (
					<div className="jobtitle">
						<h6 className="h10">{jobtitle}</h6>
					</div>
				)}
			</a>
		)
	}
}

export default PresskitCard
