import React from "react"

import Header from "../components/layout/header"
import Footer from "../components/layout/footer"
import Nav from "../components/layout/nav"
import ShareOverlay from "../components/layout/shareOverlay"
// import CookieOverlay from "../components/layout/cookieOverlay"
import SubscribeOverlay from "../components/layout/subscribeOverlay"
import VideoOverlay from "../components/layout/videoOverlay"
import FlipWarning from "../components/layout/flipWarning"
import BrowseHappy from "../components/layout/browseHappy"

const DefaultLayout = ({
	children,
	history,
	navigate,
	active,
	openNav,
	closeNav,
	busy,
	shareVisible,
	toggleShareOverlay,
	subscribeVisible,
	toggleSubscribeOverlay,
	videoOverlayVisible,
	videoOverlayUrl,
	closeVideoOverlay,
	toggleMenu,
	toggleSearch,
	toggleFilter,
	menuActive,
	searchActive,
	filterActive,
	filterPage,
	filterOptions,
	filterReset,
	toggleStopAnimation
}) => {
	const removeUi = ["/"].indexOf(location.pathname) !== 0

	return (
		<section className="site">
			<Header busy={busy} active={active} openNav={openNav} toggleMenu={toggleMenu} toggleSearch={toggleSearch} />

			<Nav
				navigate={navigate}
				history={history}
				openNav={openNav}
				closeNav={closeNav}
				active={active}
				busy={busy}
				menuActive={menuActive}
				searchActive={searchActive}
				filterActive={filterActive}
				filterPage={filterPage}
				filterOptions={filterOptions}
				filterReset={filterReset}
				toggleMenu={toggleMenu}
				toggleSearch={toggleSearch}
			/>

			<section className="main">{children}</section>

			{!removeUi ? null : <Footer toggleStopAnimation={toggleStopAnimation} toggleShare={toggleShareOverlay} toggleSubscribe={toggleSubscribeOverlay} />}

			<ShareOverlay shareVisible={shareVisible} toggleShare={toggleShareOverlay} />

			{/* <CookieOverlay /> */}

			{subscribeVisible && <SubscribeOverlay subscribeVisible={subscribeVisible} toggleSubscribe={toggleSubscribeOverlay} />}

			<VideoOverlay
				videoOverlayVisible={videoOverlayVisible}
				videoOverlayUrl={videoOverlayUrl}
				closeVideoOverlay={closeVideoOverlay}
			/>

			<FlipWarning />
			<BrowseHappy />
		</section>
	)
}

export default DefaultLayout
