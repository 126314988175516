import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { Helmet } from "react-helmet"

import ImageHero from "../layout/imageHero"
import Intro from "../layout/intro"
import RichText from "../layout/richText"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"

class CcpaPrivacyNotice extends Component {
	constructor(props) {
		super(props)

		this.state = {
			requestData: null
		}
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/ccpa")
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	render() {
		const { requestData } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const title = requestData.ccpaPage.title,
				hideTitle = requestData.ccpaPage.hideTitle,
				content = requestData.ccpaPage.content,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href

			return (
				<>
					<Helmet>
						<title>{metaTitle}</title>
						<link rel="canonical" href={metaUrl} />
						<meta name="description" content={metaDesc} />
						<meta property="og:url" content={metaUrl} />
						<meta property="og:title" content={metaTitle} />
						<meta property="og:description" content={metaDesc} />
						<meta property="og:image" content={metaImage} />
						<meta name="twitter:title" content={metaTitle} />
						<meta name="twitter:description" content={metaDesc} />
						<meta name="twitter:image" content={metaImage} />
						<meta name="twitter:image:alt" content={metaTitle} />
					</Helmet>
					<ImageHero marginDisabled gradientBg heroTitle={hideTitle ? "" : title} />
					<section className="site-content">
						<Intro gridSize="med">
							<div style={{display:'flex', flexDirection:'column', gap:'2rem'}}>
								<p><strong>LAST UPDATED: [12/15/2022]</strong></p><h2>California Consumer Privacy Act Privacy Notice&nbsp;</h2>
								<p></p>
								<p>FCB Worldwide, Inc. and its affiliates (&ldquo;<strong>FCB</strong>,&rdquo; &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us,</strong>&rdquo; or &ldquo;<strong>our</strong>&rdquo;) provide this Privacy Notice, which applies to California resident employees, employee applicants, owners, directors, officers, and contractors of FCB Worldwide, Inc., as well as, as applicable, those individuals&rsquo; emergency contacts and beneficiaries who are California residents. This Privacy Notice sets forth our practices with respect to information that can reasonably be linked with an individual (&ldquo;<strong>Personal Information</strong>&rdquo;), as required by the California Consumer Privacy Act (&ldquo;<strong>CCPA</strong>&rdquo;), as amended by the California Privacy Rights Act (&ldquo;CPRA&rdquo;), collectively herein referred to as the &ldquo;<strong>CA Privacy Law</strong>&rdquo;.&nbsp;</p>
								<p>Please note, there may be additional or different privacy notices or disclosures that govern our use of your Personal Information beyond the CA Privacy Law. Where collection, use, and disclosure of Personal Information is subject to the CA Privacy Law, this Privacy Notice shall prevail. For collection, use, and disclosure of Personal Information beyond the CA Privacy Law, those separate and relevant privacy notices and disclosures shall prevail.</p>
								<p><strong><u>Collection and Disclosure of Personal Information</u> </strong></p>
								<p>The following chart details which categories of Personal Information we collect and process, as well as which categories of Personal Information we disclose to third parties for our operational business and employment purposes, including within the 12 months preceding the date this Privacy Notice was last updated.</p>
								<table style={{borderSpacing:"1rem"}} >
									<thead>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Categories of Personal Information</strong></p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Disclosed to Which Categories of Third Parties for Operational Business Purposes</strong></p>
											</td>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Identifiers</strong>, such as name and government-issued identifier (e.g., Social Security number)</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Personal information, as defined in the California safeguards law,</strong> including without limitation, name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver&rsquo;s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Protected Class Information</strong>, such as characteristics of protected classifications under California or federal law, including without limitation, age, gender, medical conditions, marital status, sex (including pregnancy, childbirth, breastfeeding, and related medical conditions), race, color, religion or creed, ancestry, national origin, disability, genetic information, sexual orientation, gender identity and expression, citizenship, primary language, immigration status, military/veteran status, political affiliation/activities, domestic violence victim status, and requests for leave</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Commercial Information</strong>, such as transaction information and purchase history, travel expenses, including information about corporate credit card purchases, frequent flyer rewards, and other travel-related programs and expenses</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Biometric Information</strong>, such as face scans, fingerprints, and voiceprints</p>
												<p>Note: biometric information is sensitive personal information also referenced below</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Internet or Network Activity Information</strong>, such as browsing history and interactions with our websites and applications, access and usage information regarding websites, applications, and systems, information about online communications, including browsing and search history, timestamp information, IP&nbsp;address, and access and activity logs </p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Geolocation Data</strong>, such as latitude/longitude, precise location information, device location, approximate location derived from IP address, and GPS, WiFi, or Bluetooth Low Energy tracking</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Audio, electronic, visual and similar information,</strong> such as photographs, images, call and video recordings, including voicemail and security camera footage, information about the use of electronic devices and systems, key card usage, photos on websites or in employee directories</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Education Information</strong> subject to the federal Family Educational Rights and Privacy Act, such as student records and directory information</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Professional or Employment Information,</strong> such as work history, prior employer, information from reference checks, background screening information, employment application, membership in professional organizations, personnel files, personal qualifications and training, eligibility for promotions and other career-related information, work preferences, business expenses, wage and payroll information, benefit information, information on leaves of absence or PTO, performance reviews, information on internal investigations or disciplinary actions</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Inferences</strong> drawn from any of the Personal Information listed above to create a profile or summary about, for example, an individual&rsquo;s preferences, characteristics, predispositions and abilities</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
										<tr>
											<td style={{verticalAlign:'top'}}>
												<p><strong>Sensitive Personal Information:</strong></p>
												<p>&middot; Personal Information that reveals an individual&rsquo;s Social Security, driver&rsquo;s license, state identification card, or passport number; account log-in, financial account, debit card, or credit card number in combination with any required security or access code, password, or credentials allowing access to an account; precise geolocation; racial or ethnic origin, religious or philosophical beliefs, citizenship, immigration status, or union membership; the contents of mail, email, and text messages unless Company is the intended recipient of the communication; genetic data;</p>
												<p>&middot; The processing of biometric information for the purpose of uniquely identifying an individual;</p>
												<p>&middot; Personal Information collected and analyzed concerning an individual&rsquo;s health; and</p>
												<p>&middot; Personal Information collected and analyzed concerning an individual&rsquo;s sex life or sexual orientation.</p>
											</td>
											<td style={{verticalAlign:'top'}}>
												<p>Our affiliates; service providers providing services such as payroll, benefits, consulting, training, expense management, medical benefits, IT, and other services; professional advisors, such as accountants, auditors, bankers, and lawyers; public and governmental authorities, such as regulatory authorities and law enforcement; business partners, such as entities that help us to provide services to you (e.g., a corporate credit card).</p>
											</td>
										</tr>
									</tbody>
								</table>
								<p>We may also disclose the above categories of Personal Information to a third party in the context of any reorganization, financing transaction, merger, sale, joint venture, partnership, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings).</p>
								<p>We do not sell Personal Information of covered individuals, and we do not share or otherwise process Personal Information of covered individuals for purposes of cross-context behavioral advertising, as defined under the CA Privacy Law. We have not engaged in such activities in the 12 months preceding the date this Privacy Notice was last updated.&nbsp;</p>
								<p>We do not knowingly sell or share the Personal Information of minors under 16 years of age.</p>
								<p><strong><u>Sources of Personal Information </u></strong></p>
								<p>We collect Personal Information from you and from our affiliates, your colleagues and managers, references you provide, prior employers or schools, clients, background check providers, providers of employee benefits, social networks, publicly available databases, and cooperative databases. We collect Personal Information about our employees&rsquo; and other individuals&rsquo; emergency contacts and beneficiaries from such employees or other individuals.</p>
								<p><strong><u>Purposes for the Collection, Use and Disclosure of Personal Information</u></strong></p>
								<p>We may collect or use Personal Information for the purposes of operating, managing, and maintaining our business, managing our workforce and other employment purposes, and accomplishing our business purposes and objectives, including, for example, using Personal Information to:</p>
								<ul>
									<li>Plan and manage workforce activities and personnel generally, including for recruitment, employee onboarding, appropriate staffing, performance management, training and career development, payments and benefit administration, employee training, leaves and promotions;</li>
									<li>Conduct workforce assessments, including determining physical or mental fitness for work and evaluating work performance;</li>
									<li>Process payroll, manage wages and other awards such as stock options, stock grants and bonuses, reimburse expenses, and provide healthcare, pensions, savings plans and other benefits;</li>
									<li>Operate, maintain, monitor, and secure our facilities, equipment, systems, networks, applications, and infrastructure;</li>
									<li>Manage attendance, time keeping, leaves of absence, and vacation;</li>
									<li>Facilitate employee communication and workforce travel;</li>
									<li>Undertake quality and safety assurance measures, protect the health and safety of our workforce and others, and conduct risk and security control and monitoring;</li>
									<li>Conduct research, analytics, and data analysis, such as to assist in succession planning and to ensure business continuity, as well as to design and implement employee retention programs, diversity, equity, and inclusion initiatives, and environmental, social, and governance (&ldquo;<strong>ESG</strong>&rdquo;) initiatives;</li>
									<li>Perform identity verification, accounting, budgeting, audit, and other internal functions, such as internal investigations, disciplinary matters, and handling grievances and terminations;</li>
									<li>Operate and manage IT and communications systems and facilities, allocate Company assets and human resources, and undertake strategic planning and project management; and</li>
									<li>Comply with law, legal process, requests from governmental or regulatory authorities, internal policies, and other requirements, such as income tax deductions, recordkeeping, work permit and immigration regulations and reporting obligations, and the exercise or defense of legal claims.</li>
								</ul>
								<p>You may, subject to applicable law, make the following requests:</p>
								<ol>
									<li>You may request we disclose to you the following information covering the 12 months preceding your request:
										<ol style={{paddingLeft: '2rem'}}>
											<li>The categories of Personal Information we collected about you and the categories of sources from which we collected such Personal Information;</li>
											<li>The business or commercial purpose for collecting Personal Information about you; and</li>
											<li>The categories of Personal Information about you we otherwise disclosed and the categories of third parties to whom we disclosed such Personal Information.</li>
										</ol>
									</li>
									<li>You may request to correct inaccuracies in your Personal Information.</li>
									<li>You may request to have certain Personal Information you provided to us deleted.</li>
									<li>You may request to receive a copy of your Personal Information, including a copy of the Personal Information you provided to us in a portable format.</li>
								</ol>
								<p>We will not unlawfully discriminate against you for exercising a right conferred by the CA Privacy Law. Without limitation, we will not retaliate against you for exercising such a right. To make a request, please contact us at <a href="https://ncv.microsoft.com/khZ8jEBrCI">https://ncv.microsoft.com/khZ8jEBrCI</a> or 1-888-474-8778. We will verify and respond to your request consistent with applicable law, taking into account the type and sensitivity of the Personal Information subject to the request. We may need to request additional Personal Information from you in order to verify your identity and protect against fraudulent requests. If you maintain a password-protected account with us, we may verify your identity through our existing authentication practices for your account and require you to re-authenticate before disclosing or deleting your Personal Information. If you make a request to delete, we may ask you to confirm your request before we delete your Personal Information.</p>
								<p>We will verify and respond to your request consistent with applicable law.</p>
								<p>If an agent would like to make a request on your behalf as permitted by applicable law, the agent may use the submission methods noted above. As part of our verification process, we may request that the agent provide, as applicable, proof concerning their status as an authorized agent. In addition, we may require that you verify your identity as described above or confirm that you provided the agent permission to submit the request.</p>
								<p><strong></strong></p>
								<p><strong><u>Changes to this Privacy Notice </u></strong></p>
								<p>We may change or update this notice from time to time. When we do, we will post the updated notice on this page with a new &ldquo;Last Updated&rdquo; date.&nbsp;</p>
								<p></p>
								<p><strong><u>Contact Us</u></strong></p>
								<p>If you have questions regarding this Privacy Notice, please contact your local Human Resources department, HR Privacy Champion, or email us at <a style={{textDecoration:'underline'}} href="mailto:mb.datasecurity@fcb.com?subject=Taleo%20Privacy%20Agreement">mb.datasecurity@fcb.com</a>.</p>
							</div>
						</Intro>
					</section>
				</>
			)
		}
	}
}

CcpaPrivacyNotice.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(CcpaPrivacyNotice)
connectedPage = withRouter(connectedPage)

export default connectedPage
