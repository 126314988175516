import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { Helmet } from "react-helmet"

import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import OfficeCard from "../layout/officeCard"
import Grid from "../layout/grid"
import FilterByList from "../layout/filterbyList"
import ContactList from "../layout/contactList"
import ContactCard from "../layout/contactCard"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"

class Contact extends Component {
	constructor() {
		super()

		this.state = {
			requestData: null,
			selectedRegion: "North America",
			showAttention: true
		}

		this.setRegion = this.setRegion.bind(this)
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/contact")
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	setRegion(region) {
		this.setState({
			selectedRegion: region
		})
	}

	closeAttention(){
		this.setState({showAttention: false})
	}

	render() {
		const { requestData, selectedRegion, showAttention } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const offices = requestData.offices,
				title = requestData.contactPage.title,
				hideTitle = requestData.contactPage.hideTitle,
				heroImage = requestData.contactPage.heroImage,
				contacts = requestData.contactPage.contactPoints,
				regions = requestData.regions,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href,
				filteredOffices = offices ? offices.filter(item => item.region == selectedRegion) : []

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				{showAttention && (
				  <div style={{ background: 'rgba(255, 255, 255, .75)' , display: 'grid' , placeItems: 'center' ,
					margin: 'auto' , position: 'absolute' , inset: 0, 
					zIndex: 99999999, alignContent: 'flex-start', justifyItems: 'center', paddingTop: '10vh' 
					}}>
					<div className="ff-cp" style={{ background: 'white' , width: '80%' , border: '1px solid black' , padding: '24px'
						, position: 'relative' , display: 'grid', gap: '24px' }} onClick={_ => this.closeAttention()}>
						<div style={{position: 'absolute', right: '24px', top: '24px'}}><svg
								fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
								stroke="currentColor" style={{width: '24px', height: '24px', cursor: 'pointer'}}>
								<path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
							</svg>
						</div> 
						<p style={{paddingTop: '32px'}}>We have become aware that FCB’s name has been fraudulently used by individuals or groups pretending to be associated with our company on unofficial websites and apps. We are also aware that fraudulent accounts are using WhatsApp chats and fake websites to try and get people to pay money for advertising-related services or as part of the job application process.  These accounts are a scam. They are not affiliated with or in any way connected to FCB or any of our partner agencies.</p>
						<p>Any claims by the accounts that they are a product of, or otherwise associated with, FCB are false. Any
							websites
							or apps claiming a connection to FCB to help people earn money or encourage people to pay membership
							fees or
							provide personal and/or financial information are fraudulent. FCB will never ask for personal
							information or
							personal payments for services. If you believe you have been defrauded by these accounts, we recommend
							that you
							contact your local law enforcement authorities.</p>
						<p>We have taken action with relevant authorities to try to stop the fraudulent use of our brand.</p>
					</div>
				</div>
        		)}
				<ImageHero
					marginDisabled
					images={heroImage}
					heroTitle={hideTitle ? "" : title}
				/>
				<section className="site-content">
					{/* Contact Cards */}
					{contacts && contacts.length > 0 && (
						<ContactList gridSize="lrg" title="">
							<Grid
								gridItems={contacts}
								rowClass={"flush jcc"}
								noRows
								maxCols={4}
								size={"lrg"}
								args={args => ({
									data: args,
									marginDisabled: true,
									classes: "grey",
									textAlign: "left"
								})}
								Child={ContactCard}
							/>
						</ContactList>
					)}

					{/* Global offices */}
					{filteredOffices && filteredOffices.length > 0 && (
						<>
							<Intro marginDisabled gridSize="lrg">
								<RichText marginDisabled textAlign="left">
									<h2>Want to contact a specific office?</h2>
								</RichText>
							</Intro>
							<FilterByList
								gridSize="lrg"
								title="Filter by location"
								filters={regions}
								activeFilter={selectedRegion}
								handleFilter={this.setRegion}
								noItems={filteredOffices.length < 1}
								noItemsMsg="We do not currently have any offices in this region"
							>
								<Grid
									gridItems={[...filteredOffices]}
									rowClass={"flush start top"}
									colnum={2}
									size={"lrg"}
									Child={OfficeCard}
									uniqueKey={"displayName"}
								/>
							</FilterByList>
						</>
					)}
				</section>
			</>
			)
		}
	}
}

Contact.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Contact)
connectedPage = withRouter(connectedPage)

export default connectedPage
