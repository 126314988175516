const exportCode = module.exports;

exportCode.helloSlider = (sliderElement, settings) => {
        var sliderInstance = sliderElement,
            pages = [],
            currentSlide = 1,
            isChanging = false,
            keyLeft = {37:1, 100:1, 52:1},
            keyUp = {38:1, 33:1, 104:1},
            keyRight = {39:1, 102:1, 54:1},
            keyDown = {40:1, 34:1, 98:1},
            defaults = {
                rtl: false,
                pager: false,
                mouseControl: true,
                touchControl: true,
                keyControl: false,
				buttonControl: false,
				rotate: false,
				pin: false,
                autoSlideControl: false,
                autoInterval: 5,
				speed: 0.65,
                timeout: 1,
				pinMsgStart: 'Scroll to begin',
				pinMsgMiddle: 'Keep scrolling',
				pinMsgEnd: 'Never Finished',
				setHeight: '100vh',
				fullscreen: false
            };

		settings = settings || {};

		if (settings && typeof settings === "object") {
			settings = extendDefaults(settings, defaults);
		};

		var init = function() {
			//creating wrappers for sliderElement
			sliderInstance.classList.add('hello-slider');
			
			var sliderContainer = sliderInstance.children[0];

			sliderContainer.classList.add('hello-container');
			changeCss(sliderContainer, { height: settings.setHeight });

			//flag mouse scroll nav function
			if (settings.mouseControl && settings.fullscreen) {
				mouseWheelNavigate();
			};

			//flag touch scroll nav function
			if (settings.touchControl) {
				touchNavigate();
			};

			//flag keypress scroll nav function
			if (settings.keyControl && settings.fullscreen) {
				keyboardNavigate();
			};

			if (settings.fullscreen) {
				recalculateHeight();
			}

			//pager container and slides variables
			var indicatorContainer = document.createElement('div'),
				sliderChildren = sliderContainer.children;

			//add class name for indicator group
			if (settings.pager && sliderChildren.length > 1) {
				indicatorContainer.classList.add('hello-pager');
				sliderInstance.appendChild(indicatorContainer);
			}

			for (var i = 0; i < sliderChildren.length; i++) {
				sliderChildren[i].classList.add('hello-slide');
				if (i === 0) {
					sliderChildren[i].classList.add('hello-active');
				}
				changeCss(sliderChildren[i], { position: 'absolute' });
				pages.push(sliderChildren[i]);

				if (settings.pager && sliderChildren.length > 1) {
					//create indicators
					var indicator = document.createElement('a');
					indicator.classList.add('hello-bullet');
					indicator.setAttribute('data-slider-target-index', (i + 1));
					indicatorContainer.appendChild(indicator);

					//set section data index
					sliderChildren[i].setAttribute('data-slider-index', (i + 1));
				}
			};

			if (settings.pin && settings.fullscreen) {
				//create and set pin container
				var pinContainer = document.createElement('div');
				pinContainer.classList.add('hello-pin');
				sliderInstance.appendChild(pinContainer);
				//set pin message
				setPinMsg();
			};

			//flag arrow button nav function
			if (settings.buttonControl && sliderChildren.length > 1) {
				var buttonContainer = document.createElement('div'),
					prevButton = document.createElement('button'),
					nextButton = document.createElement('button'),
					nextSlideValue = (sliderContainer.children.length >= 10) ? sliderContainer.children.length : '0' + sliderContainer.children.length;

				buttonContainer.classList.add('hello-navigate');
				prevButton.classList.add('hello-button');
				prevButton.classList.add('hello-prev');
				nextButton.classList.add('hello-button');
				nextButton.classList.add('hello-next');
				sliderInstance.appendChild(buttonContainer);
				buttonContainer.appendChild(prevButton);
				buttonContainer.appendChild(nextButton);
				prevButton.textContent='0'+ currentSlide;
				nextButton.textContent=nextSlideValue;

				sliderInstance.getElementsByClassName('hello-prev')[0].addEventListener('click', function (e) {
					prevSlide();
				});

				sliderInstance.getElementsByClassName('hello-next')[0].addEventListener('click', function (e) {
					nextSlide();
				});
			};

			if (settings.pager && sliderChildren.length > 1) {
				var selectCurrentSlide = sliderInstance.querySelector('a[data-slider-target-index = "' + currentSlide +'"]');
				if (currentSlide === 1) {
					if (settings.autoSlideControl) {
						changeCss(selectCurrentSlide, {
							animationDuration: settings.autoInterval + 'ms'
						});
					};
					selectCurrentSlide.classList.add('hello-bullet-active');
				}
				[].forEach.call(sliderInstance.getElementsByClassName('hello-bullet'), function(elm) {
					elm.addEventListener('click', function() {
						var targetIndex = this.getAttribute('data-slider-target-index');
						changeSlide(targetIndex - currentSlide);
					});
				});
			};

            //if right to left is requested, add class to the instance and set the container width.
            if (settings.rtl) {
                sliderInstance.classList.add('rtl');
            };
		};

		//if true, then start auto slider
		if (settings.autoSlideControl) {
			//set from seconds to milliseconds;
			settings.autoInterval = settings.autoInterval * 1000;
			var autoSlideshow = new autoTimer(function() {
				changeSlide(1);
			}, settings.autoInterval);
		};

		//expanding default variables utility
		function extendDefaults(options, defaults) {
			for (var opt in defaults) {
				if (defaults.hasOwnProperty(opt) && !options.hasOwnProperty(opt)) {
					options[opt] = defaults[opt];
				};
			};
			return options;
		};

		//slide show auto timer
		function autoTimer(fn, t) {
			var timerObj = setInterval(fn, t);
			this.stop = function() {
				if (timerObj) {
					clearInterval(timerObj);
					timerObj = null;
				}
				return this;
			}

			// start timer using current settings (if it's not already running)
			this.start = function() {
				if (!timerObj) {
					this.stop();
					timerObj = setInterval(fn, t);
				}
				return this;
			}

			// start with new interval, stop current interval
			this.reset = function(newT) {
				t = newT;
				return this.stop().start();
			}
		}

		// control caousel with mouse wheel scroll scrolling
		var mouseWheelNavigate = function() {
			var whatWheel = 'onwheel' in document.createElement('div') ? 'wheel' : document.onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
			sliderInstance.addEventListener(whatWheel, function (e) {
                e.preventDefault()
                e.stopPropagation()
				var direction = e.wheelDelta || e.deltaY;
				if (direction > 0) {
					changeSlide(-1);
				} else {
					changeSlide(1);
				}
			});
		};

		var setPinMsg = function() {
			var pinContainer = sliderInstance.querySelector('.hello-pin');

			if (currentSlide <= 1) {
				pinContainer.innerHTML = '<div class="t-link c-white">' + settings.pinMsgStart + '</div>';
			} else if (currentSlide >= pages.length) {
				pinContainer.innerHTML = '<div class="t-link c-white">' + settings.pinMsgEnd + '</div>';
			} else {
				pinContainer.innerHTML = '<div class="t-link c-white">' + settings.pinMsgMiddle + '</div>';
			}
		};

		var touchNavigate = function() {
			// enable navigation for touch devices
			var touchStartPos = 0,
				touchStopPos = 0,
				touchMinLength = 0;

			if (settings.rtl) {
				touchMinLength = 50;
			} else {
				touchMinLength = 90;
			}

			document.addEventListener('touchstart', function (e) {
                e.stopPropagation();
				if (e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel') {
					var touch = e.touches[0] || e.changedTouches[0];
					if (settings.rtl) {
						touchStartPos = touch.pageX;
					} else {
						touchStartPos = touch.pageY;
					}
				}
			});
			document.addEventListener('touchend', function (e) {
                e.stopPropagation();
				if (e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel') {
					var touch = e.touches[0] || e.changedTouches[0];
					if (settings.rtl) {
						touchStopPos = touch.pageX;
					} else {
						touchStopPos = touch.pageY;
					}
				}
				if (touchStartPos + touchMinLength < touchStopPos) {
					changeSlide(-1);
				} else if (touchStartPos > touchStopPos + touchMinLength) {
					changeSlide(1);
				}
			});
		};

		var keyboardNavigate = function() {
			var hovering = false;

			sliderInstance.addEventListener("mouseenter", function() {
				return hovering = true;
			});

			sliderInstance.addEventListener("mouseleave", function() {
				return hovering = false;
			});

			// allow keyboard input
			window.addEventListener('keydown', function(e) {
				if (settings.rtl) {
                    if (keyLeft[e.keyCode || e.which] && hovering) {
						e.preventDefault()
                        changeSlide(-1);
                    } else if (keyRight[e.keyCode || e.which] && hovering) {
						e.preventDefault()
                        changeSlide(1);
                    }
                } else {
                    if (keyUp[e.keyCode || e.which] && hovering) {
						e.preventDefault()
                        changeSlide(-1);
                    } else if (keyDown[e.keyCode || e.which] && hovering) {
						e.preventDefault()
                        changeSlide(1);
                    }
                }
			});
		};

		var recalculateHeight = function() {
			var recalculateHeightFunction = function(e) {
				changeCss(sliderInstance.children[0], { height: document.documentElement.clientHeight + 'px' });
			};

			window.addEventListener('load', recalculateHeightFunction, false);
			window.addEventListener('resize', recalculateHeightFunction, false);
		};

		//next slide
		var nextSlide = function() {
			changeSlide(1);
		};

		//previous slide
		var prevSlide = function() {
			changeSlide(-1);
		};

		// handle css change
		var changeCss = function (obj, styles) {
			for (var _style in styles) {
				if (obj.style[_style] !== undefined) {
					obj.style[_style] = styles[_style];
				}
			}
		};

		// handle page/section change
		var changeSlide = function (direction) {
			var previousSlide = currentSlide;
			// already doing it, then stop
			if (isChanging) {
				return;
			} else {
				isChanging = true;

				if (settings.rotate) {
					// if first slide going backwards, then move to end
					if (direction == -1 && currentSlide <= 1) {
						currentSlide = (pages.length - 1);
						direction = 1;
					// if last slide going backwards, then move to start
					} else if (direction == 1 && currentSlide >= pages.length) {
						currentSlide = 2;
						direction = -1;
					}
				} else {
					if ((direction == -1 && currentSlide <= 1) || (direction == 1 && currentSlide >= pages.length)) {
						isChanging = false;
						return;
					}
				}

				//remove old active slide
				sliderInstance.children[0].children[previousSlide - 1].classList.add('hello-previous');
				sliderInstance.children[0].children[previousSlide - 1].classList.remove('hello-active');
				currentSlide += direction;

				//add new active slide
				changeCss(sliderInstance.children[0], { animationDuration: settings.speed + 's' });
				sliderInstance.children[0].children[currentSlide - 1].classList.add('hello-active');
				setTimeout(function() {
					sliderInstance.children[0].children[previousSlide - 1].classList.remove('hello-previous');
				}, (settings.speed * 1000));

				if (settings.pin && settings.fullscreen) {
					setPinMsg();
				}

				if (settings.pager && sliderInstance.children.length > 1) {
					var sliderBullets = sliderInstance.children[1].querySelectorAll('.hello-bullet'),
						currentSliderBullet = sliderInstance.children[1].querySelector('a[data-slider-target-index="' + currentSlide +'"]');

					// change dots
					for (var i = 0; i < sliderBullets.length; i++) {
						sliderBullets[i].classList.remove('hello-bullet-filled');
						sliderBullets[i].classList.remove('hello-bullet-active');
            			// removed to fix IOS carousel bug, not sure what or if this will break anything.
						// sliderBullets[i].style = '';
					};

					for (var i = 0; i < currentSlide; i++) {
						sliderBullets[i].classList.add('hello-bullet-filled');
					};

					if (settings.autoSlideControl) {
						changeCss(currentSliderBullet, {
							animationDuration: settings.autoInterval + 'ms'
						});
					}
					currentSliderBullet.classList.remove('hello-bullet-filled');
					currentSliderBullet.classList.add('hello-bullet-active');
				};

				if (settings.buttonControl && sliderInstance.children.length > 1) {
					let nextButton = sliderInstance.querySelector('.hello-next'),
						prevButton = sliderInstance.querySelector('.hello-prev');

					if (currentSlide >= 10) {
						prevButton.textContent=(currentSlide);
					} else {
						prevButton.textContent=('0' + currentSlide);
					}
					if (pages.length >= 10) {
						nextButton.textContent=(pages.length);
					} else {
						nextButton.textContent=('0' + pages.length);
					}

					if (!settings.rotate) {
						nextButton.classList.remove('disabled');
						prevButton.classList.remove('disabled');

						if (currentSlide === pages.length) {
							nextButton.classList.add('disabled');
						}

						if (currentSlide === 1) {
							prevButton.classList.add('disabled');
						}
					}
				}

				if (settings.autoSlideControl) {
					autoSlideshow.reset(settings.autoInterval);
				};

				// prevent double scrolling
				var delay = parseInt((settings.timeout * 1000) + (settings.speed * 1000));
				setTimeout(function() {
					isChanging = false;
				}, delay);
			}
		};

		// initialize hello slider
		if (document.readyState === 'complete') {
			init();
		} else {
			window.addEventListener('onload', init(), false);
		}
};
