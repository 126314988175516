import React from 'react';
import Fade from "react-reveal/Fade"

const renderInnerFade = (children, wrapperClasses, settings) => (
<div className={`fade-component__inner-wrapper ${ wrapperClasses || '' }`}>
  <Fade { ...settings }>
    { children }
  </Fade>
</div>
)

const FadeComponent = ({ className, children, fadeSettings, innerWrapperClasses, innerFadeSettings }) => (
<div className={`fade-component ${ className || '' }`}>
  <Fade { ...fadeSettings }>
    { innerFadeSettings
      ? renderInnerFade(children, innerWrapperClasses, innerFadeSettings)
      : children
    }
  </Fade>
</div>
)

export default FadeComponent;
