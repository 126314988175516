import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { TimelineLite, TweenMax, EasePack, Power2, ScrollToPlugin } from "gsap/all"
import { Helmet } from "react-helmet"

import Subnav from "../layout/subnav"
import ImageHero from "../layout/imageHero"
import NeverList from "../layout/neverList"
import NeverCard from "../layout/neverCard"
import NeverNav from "../layout/neverNav"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import _ from "lodash"
const { detect } = require("detect-browser")
const browser = detect()

class NeverFinishedStory extends Component {
	constructor(props) {
		super(props)

		this.state = {
			requestData: null,
			buttonDisable: false
		}

		this.openFilter = this.openFilter.bind(this)
		this.closeFilter = this.closeFilter.bind(this)
		this.scrollToFilter = this.scrollToFilter.bind(this)
	}

	openFilter() {
		let html = document.querySelector("html"),
			body = document.querySelector("body"),
			filter = document.getElementsByClassName("never-nav")
		// overlay = document.querySelector(".never-nav .overlay")

		this.setState({
			buttonDisable: false
		})

		body.setAttribute("data-var", window.pageYOffset)
		TweenMax.set(body, { top: `-${window.pageYOffset}px` })

		html.classList.add("site-fixed")
		body.classList.add("site-fixed")

		TweenMax.set(filter, { display: "block", opacity: "0" })
		TweenMax.to(filter, 0.5, { opacity: "1" })
	}

	closeFilter(afterScroll) {
		let html = document.querySelector("html"),
			body = document.querySelector("body"),
			filter = document.getElementsByClassName("never-nav"),
			tl = new TimelineLite()

		if (html.classList.contains("site-fixed")) {
			html.classList.remove("site-fixed")
		}
		if (body.classList.contains("site-fixed")) {
			body.classList.remove("site-fixed")
		}

		if (afterScroll !== true) {
			tl.to(window, 0, { scrollTo: { y: body.getAttribute("data-var"), autoKill: false } })
		}

		tl.to(filter, 0.5, { opacity: "0", delay: 0.1 }).to(filter, 0.1, {
			display: "none",
			onComplete: function() {
				if (body.classList.contains("hide-overflow")) {
					body.classList.remove("hide-overflow")
				}
			}
		})
	}

	scrollToFilter(id) {
		const closeFilter = this.closeFilter

		let html = document.querySelector("html"),
			body = document.querySelector("body"),
			target = document.getElementById(id).offsetTop + window.innerHeight,
			tl = new TimelineLite()

		this.setState({
			buttonDisable: true
		})

		html.classList.remove("site-fixed")
		body.classList.remove("site-fixed")
		TweenMax.set(body, { top: `0px` })
		tl.to(window, 0, { scrollTo: { y: body.getAttribute("data-var"), autoKill: false } })

		let distance = window.pageYOffset - target
		distance = distance < 0 ? -distance : distance

		let duration

		if (distance < 4000) {
			duration = 0.8
		} else if (distance < 10000) {
			duration = 1.5
		} else if (distance < 15000) {
			duration = 2.5
		} else {
			duration = 3
		}

		tl.to(window, 0.5, {
			onStart: function() {
				closeFilter(true)
			},
			delay: 0.1
		}).to(window, duration, {
			scrollTo: {
				y: target,
				autoKill: false,
				offsetY: 110
			},
			ease: Power2.easeOut
		})
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/never-finished")
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	render() {
		const { requestData, buttonDisable } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const title = requestData.neverFinishedPage.title,
				hideTitle = requestData.neverFinishedPage.hideTitle,
				heroImage = requestData.neverFinishedPage.heroImage,
				timelines = requestData.timelines,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href

			console.log(timelines)

			const tltest = JSON.parse(JSON.stringify(timelines))

			tltest.forEach(item => {
				item.year = item.year.substring(0, 4)
			})

			console.log(tltest)

			const timelineNav = _.uniqBy(tltest, "year")

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<ImageHero marginDisabled images={heroImage} heroTitle={hideTitle ? "" : title} />
				{/* <Subnav marginDisabled /> */}
				<section
					className={
						"site-content gradient" + (browser.name === "safari" || browser.name === "ios" ? " browser-safari " : " ")
					}
				>
					<NeverList openFilter={this.openFilter}>
						{timelines.map((item, index) => {
							return <NeverCard key={index} marginDisabled data={item} />
						})}
					</NeverList>
				</section>
				<NeverNav closeFilter={this.closeFilter}>
					{timelineNav.map((item, index) => {
						return (
							<button
								type="button"
								key={"button-" + index}
								className="btn btn-never-nav-link"
								onClick={() => this.scrollToFilter(item.year)}
								disabled={buttonDisable}
							>
								{item.year}
							</button>
						)
					})}
				</NeverNav>
			</>
			)
		}
	}
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(NeverFinishedStory)
connectedPage = withRouter(connectedPage)

export default connectedPage
