import React, { Component } from "react"
import { TweenMax, ScrollToPlugin } from "gsap/all"
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from "react-share"

class ShareOverlay extends Component {
	constructor(props) {
		super(props)
		this.state = {
			shareUrl: window.location.href
		}

		this.animating = this.animating.bind(this)
		this.animateIn = this.animateIn.bind(this)
		this.animateOut = this.animateOut.bind(this)
		this.handleOnClose = this.handleOnClose.bind(this)
	}

	animating() {
		if (this.props.shareVisible) {
			this.animateIn()
		}
	}

	animateIn() {
		let body = document.querySelector("body"),
			shareOverlay = document.getElementsByClassName("share-overlay"),
			shareOverlayBackground = document.querySelector(".share-overlay .overlay")

		body.classList.add("hide-overflow")

		TweenMax.fromTo(
			shareOverlay,
			0.8,
			{ display: "none", transform: "translateY(101vh)" },
			{ display: "block", transform: "translateY(0)" }
		)

		TweenMax.fromTo(shareOverlayBackground, 0.3, { autoAlpha: 0 }, { autoAlpha: 0.5 }).delay(0.9)
	}

	animateOut(cb) {
		let body = document.querySelector("body"),
			shareOverlay = document.getElementsByClassName("share-overlay"),
			shareOverlayBackground = document.querySelector(".share-overlay .overlay")

		TweenMax.to(shareOverlayBackground, 0.3, { autoAlpha: 0 })

		TweenMax.to(shareOverlay, 0.8, { transform: "translateY(100vh)" }).delay(0.4)

		TweenMax.to(shareOverlay, 0.1, { display: "none", onComplete: cb }).delay(1.3)

		body.classList.remove("hide-overflow")
	}

	handleOnClose(cb) {
		this.animateOut(cb)
	}

	componentDidMount() {
		this.animating()
	}

	componentDidUpdate() {
		this.animating()
	}

	render() {
		const { toggleShare } = this.props
		const { shareUrl } = this.state

		return (
			<section className="share-overlay">
				<div className="overlay" onClick={() => this.handleOnClose(toggleShare)} />
				<div className="share-container">
					<div className="content">
						<div className="t-h3">Feeling Social?</div>
						<div className="buttons">
							<FacebookShareButton url={shareUrl} className="btn btn-share-icon bg1">
								<span className="ico ico-font ico-facebook" />
							</FacebookShareButton>
							<TwitterShareButton url={shareUrl} className="btn btn-share-icon bg2">
								<span className="ico ico-font ico-twitter" />
							</TwitterShareButton>
							<LinkedinShareButton url={shareUrl} className="btn btn-share-icon bg3">
								<span className="ico ico-font ico-linkedin" />
							</LinkedinShareButton>
							<EmailShareButton url={shareUrl} className="btn btn-share-icon bg4">
								<span className="ico ico-font ico-email" />
							</EmailShareButton>
						</div>
					</div>
				</div>
				<button className="btn btn-share-close" onClick={() => this.animateOut(toggleShare)}>
					<span className="ico ico-font ico-close" />
				</button>
			</section>
		)
	}
}

export default ShareOverlay
