'use strict';

import '../styles/main.scss';
import '../favicon/apple-touch-icon.png';
import '../favicon/favicon-32x32.png';
import '../favicon/favicon-16x16.png';
import '../favicon/site.webmanifest';
import '../favicon/favicon.ico';
import '../sitemap.xml';
import '../robots.txt';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history';
import reducers from './reducers';
import rootSaga from './sagas/index'
import AppContainer from './containers/appContainer';

import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/all"
gsap.registerPlugin(ScrollToPlugin)

const sagaMiddleware = createSagaMiddleware()
const devTools = window.devToolsExtension ? window.devToolsExtension() : f => f;
const history = createBrowserHistory();
const middleWare = applyMiddleware(
  sagaMiddleware,
  routerMiddleware(history)
);

const store = createStore(
  connectRouter(history)(reducers),
  compose(middleWare, devTools)
);

sagaMiddleware.run(rootSaga)


window.dataLayer = window.dataLayer || []
history.listen((location) => {
  window.dataLayer.push({
    'event': 'virtualPageView',
    'pageUrl': window.location.href,
    'pageTitle': document.title
  })
});

render(
<Provider store={store}>
  <GoogleReCaptchaProvider reCaptchaKey="6LfMk2YbAAAAAA3evRsaxVYelQ_pixtkiD4jScRm">
      <AppContainer history={history} />
  </GoogleReCaptchaProvider>
</Provider>
, document.getElementById('app')
);
