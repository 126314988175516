import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { TweenMax, ScrollToPlugin } from "gsap/all"
import { fetch } from "../../actions/request"
import Fade from "react-reveal/Fade"
import { Helmet } from "react-helmet"

import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import VideoHero from "../layout/videoHero"
import GallerySlider from "../layout/gallerySlider"
import LineBreak from "../layout/lineBreak"
import ButtonList from "../layout/buttonList"
import StatsItem from "../layout/statsItem"
import StatsList from "../layout/statsList"
import QuoteHero from "../layout/quoteHero"
import RelatedList from "../layout/relatedList"
import ContactList from "../layout/contactList"
import ContactCard from "../layout/contactCard"
import WorkCard from "../layout/workCard"
import Grid from "../layout/grid"
import CompanyList from "../layout/companyList"
import CompanyCard from "../layout/companyCard"
import SitePagination from "../layout/sitePagination"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import { widont } from "../../utils/utils"
const _ = require("lodash")

class whereWeAreDetail extends Component {
	constructor(props) {
		super(props)

		this.state = {
			url: props.match.params.url,
			gallery: {
				pin: false,
				rtl: true,
				setHeight: "auto",
				buttonControl: true,
				pager: false,
				rotate: false,
				mouseControl: false,
				touchControl: true,
				keyControl: false,
				autoSlideControl: false,
				speed: 0.6,
				name: "gallery-inner"
			},
			requestData: null
		}
	}

	_widont() {
		widont('.intro p, .company-card p:not(.contact-extras), .t-h3')
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch(`offices/${this.state.url}`)
		window.addEventListener('resize', this._widont)
		window.setTimeout(this._widont, 1000)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {
			const { Fetch } = this.props

			Fetch(`offices/${this.props.match.params.url}`)
		}
		window.setTimeout(this._widont, 1000)

	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
		window.setTimeout(this._widont, 1000)

	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._widont)
	}

	render() {
		const { requestData, gallery } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const heroImage = requestData.heroImage,
				operatingHours = requestData.operatingHours,
				displayName = requestData.displayName,
				city = requestData.city,
				country = requestData.country,
				description = requestData.description,
				googleMapsLink = requestData.googleMapsLink,
				websiteLink = requestData.websiteLink,
				quote = requestData.quote,
				relatedProjects = requestData.relatedProjects,
				stats = requestData.stats,
				heroVideo = requestData.heroVideo,
				gallerySlides = requestData.gallerySlides,
				groupCompanies = requestData.groupCompanies,
				contacts = requestData.contacts,
				nextUrl = requestData.nextUrl,
				prevUrl = requestData.prevUrl,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<ImageHero
					marginDisabled
					images={heroImage}
					operatingHours={operatingHours}
					operatingCity={city}
					operatingCountry={country}
				/>
				<section className="site-content">
					{/* Office intro */}
					<Fade fraction={0.3} duration={600}>
						<Intro marginDisabled gridSize="sml">
							<RichText marginDisabled textAlign="left" dangerouslySet>
								{`<h1 class="h2">${city === country ? '' : city + ','} ${country}</h1>${description}`}
							</RichText>
							<div className="df fxdrc pt20 pb60" style={{gap: '20px'}}>
								{googleMapsLink && (
									<Fade bottom delay={200}>
										<a
											href={googleMapsLink}
											title="View on Google Maps"
											className="btn btn-link black has-icon fit-content"
											target="_blank"
										>
											<span className="ico ico-font ico-pin" />
											View on Google Maps
										</a>
									</Fade>
								)}
								{websiteLink && (
									<Fade bottom delay={400}>
										<a
											href={websiteLink}
											title="Visit website"
											className="btn btn-link black has-icon fit-content"
											target="_blank"
										>
											<span className="ico ico-font ico-globe" />
											Visit website
										</a>
									</Fade>
								)}
								{contacts && contacts.length > 1 && (
									<Fade bottom delay={600}>
										<button
											title="Contact Us"
											className="btn btn-link black has-icon fit-content"
											onClick={() =>
												TweenMax.to(window, 0.85, { scrollTo: { y: ".contact-list", offsetY: 166 } })
											}
										>
											<span className="ico ico-font ico-mail-contact" />
											Contact Us
										</button>
									</Fade>
								)}
							</div>
						</Intro>
					</Fade>

					{/* Quote about office */}
					{quote && quote.quote.length > 0 && <QuoteHero gridSize="med" quoteText={quote.quote} quoteAuthor={quote.author} />}

					{/* Related work/projects */}
					{relatedProjects && relatedProjects.length > 0 && (
						<RelatedList
							marginDisabled
							gridSize="lrg"
							title="Work by this office"
							linkUrl={`/work?office=${displayName}`}
							linkText="See all work by this office"
						>
							{relatedProjects.map((project, index) => (
								<Fade delay={index * 400 + 100} duration={500} key={`project-${index}`}>
									<div className="col col-6">
										<WorkCard
											data={project}
											lessDetail
											workBy={displayName}
											delay={index * 250 + 400}
										/>
									</div>
								</Fade>
							))}
						</RelatedList>
					)}

					{/* Office statistics */}
					{!_.isEmpty(stats) && (stats.numOfEmployees || stats.yearOpened || stats.languagesSpoken) && (
						<StatsList marginDisabled gridSize="med">
							{stats.numOfEmployees && (
								<StatsItem
									statDuration={3.75}
									statSuffix="+"
									statEndValue={stats.numOfEmployees}
									statLabel="Members of staff"
								/>
							)}
							{stats.yearOpened && (
								<StatsItem
									statDuration={3.75}
									statEndValue={stats.yearOpened}
									statStartValue={new Date().getFullYear()}
									statLabel="Year founded"
								/>
							)}
							{stats.languagesSpoken && (
								<StatsItem statDuration={3.75} statEndValue={stats.languagesSpoken} statLabel="Languages spoken" />
							)}
						</StatsList>
					)}

					{/* Office video */}
					{heroVideo && <VideoHero responsive data={heroVideo} />}

					{/* Office gallery */}
					{gallerySlides && gallerySlides.length > 0 && (
						<Fade fraction={0.4}>
							<GallerySlider options={gallery} slides={gallerySlides} />
						</Fade>
					)}

					{/* Office's associated companies - Group Companies */}
					{groupCompanies && groupCompanies.length > 0 && (
						<>
							<LineBreak gridSize="sml" />
							<CompanyList marginDisabled gridSize="med" title="Group Companies">
								{groupCompanies.map((company, index) => (
									<Fade bottom delay={index * 300 + 200} duration={800} fraction={0.2} key={index} onReveal={this._widont}>
										<CompanyCard image={company.image} imageAlt={company.imageAltText}>
											<RichText marginDisabled textAlign="left">
												<h4>{company.title}</h4>
												<p>{company.description}</p>
											</RichText>
											{company.contacts && <ContactCard
												args={{
													data: company.contacts,
													customTitle: "Contact",
													marginDisabled: true,
													classes: "padding-disabled",
													textAlign: "left"
												}}
											/>}
										</CompanyCard>
									</Fade>
								))}
							</CompanyList>
							<LineBreak gridSize="sml" />
						</>
					)}

					{/* Office contacts */}
					{contacts && contacts.length > 0 && (
						<ContactList gridSize="lrg" title="Contact Us">
							<Grid
								gridItems={contacts}
								rowClass={"flush start"}
								noRows
								maxCols={2}
								size={"lrg"}
								args={args => ({
									data: args,
									classes: "grey",
									marginDisabled: true,
									textAlign: "left"
								})}
								Child={ContactCard}
							/>
						</ContactList>
					)}
				</section>

				<SitePagination gridSize="lrg" title="offices" permalink="/where-we-are" nextUrl={nextUrl} prevUrl={prevUrl} />
			</>
			)
		}
	}
}

whereWeAreDetail.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(whereWeAreDetail)
connectedPage = withRouter(connectedPage)

export default connectedPage
