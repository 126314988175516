import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { Helmet } from "react-helmet"

import Subnav from "../layout/subnav"
import ImageHero from "../layout/imageHero"
import Intro from "../layout/intro"
import RichText from "../layout/richText"
import LineBreak from "../layout/lineBreak"
import JoinForm from "../layout/joinForm"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import Fade from "react-reveal/Fade"
import { widont } from "../../utils/utils"

class JoinOurTeam extends Component {
	constructor(props) {
		super(props)

		this.state = {
			requestData: null
		}
	}

	_widont() {
		widont('.t-bc, .richtext h2, .richtext p:not(:last-child)')
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/join-team")
		window.addEventListener('resize', this._widont)
		window.setTimeout(this._widont, 1000)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._widont)
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	render() {
		let { onBlur, onFocus, ...props } = this.props
		const { requestData } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined)
			return <PageLoadPlaceholder />

		const heroImage = requestData.joinTeamPage.heroImage,
					title = requestData.joinTeamPage.title,
					hideTitle = requestData.joinTeamPage.hideTitle,
					content = requestData.joinTeamPage.content,
					offices = requestData.offices,
					metaTitle = requestData.metaData.title,
					metaDesc = requestData.metaData.description,
					metaImage = requestData.metaData.image,
					metaUrl = location.href

		return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
				<link rel="canonical" href={metaUrl} />
				<meta name="description" content={metaDesc} />
				<meta property="og:url" content={metaUrl} />
				<meta property="og:title" content={metaTitle} />
				<meta property="og:description" content={metaDesc} />
				<meta property="og:image" content={metaImage} />
				<meta name="twitter:title" content={metaTitle} />
				<meta name="twitter:description" content={metaDesc} />
				<meta name="twitter:image" content={metaImage} />
				<meta name="twitter:image:alt" content={metaTitle} />
			</Helmet>
			<ImageHero
				marginDisabled
				images={heroImage}
				heroTitle={hideTitle ? "" : title}
			/>
			{/* <Subnav marginDisabled /> */}
			<iframe src="https://updsvi9bsj.us-east-1.awsapprunner.com/jobsearch/en" style={{width:`100dvw` , height: `100dvh`}}></iframe>
		</>
		)
	}
}

JoinOurTeam.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(JoinOurTeam)
connectedPage = withRouter(connectedPage)

export default connectedPage
