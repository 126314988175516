import React, { Component } from "react"
import Link from "./link"
import Fade from "react-reveal/Fade"

class OfficeCard extends Component {
	constructor(props) {
		super(props)

		this.state = {
			cardImg: this.props.data.card
		}
	}

	render() {
		const {
			marginDisabled,
			delay,
			fraction,
			data: { permaLink, card, city, cardAltText, country, displayName }
		} = this.props
		const { cardImg } = this.state
		const animationDuration = 300
		const animationFraction = 0.2

		return (
			<Link url={"/where-we-are/" + permaLink}>
				<div className={"card module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
					<div className="over-hidden">
						<Fade bottom delay={delay} duration={animationDuration} fraction={animationFraction}>
							<div className="image">
								<Fade delay={delay + animationDuration} duration={animationDuration} fraction={animationFraction}>
									<div className="image-container">
										{cardImg ? (
											<img
												src={card}
												onError={() => {
													this.setState({ cardImg: null })
												}}
												alt={cardAltText}
												className="img-responsive-max"
											/>
										) : null}
									</div>
								</Fade>
							</div>
						</Fade>
					</div>
					<div className="content">
						{country && (
							<div className="subtitle">
								<span className="line" />
								<div className="t-h5">{country}</div>
							</div>
						)}
						{city && (
							<div className="title">
								<div className="t-h3">{displayName}</div>
							</div>
						)}
					</div>
				</div>
			</Link>
		)
	}
}

export default OfficeCard
