import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import queryString from "query-string"
import { resetFilter } from "../../actions/filters"
import Fade from "react-reveal/Fade"
import { Helmet } from "react-helmet"
import { TimelineLite, Power2 } from 'gsap/all'

import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import VideoHero from "../layout/videoHero"
import LineBreak from "../layout/lineBreak"
import WorkCard from "../layout/workCard"
import AwardsList from "../layout/awardsList"
import CtaHero from "../layout/ctaHero"
import GallerySlider from "../layout/gallerySlider"
import QuoteSlider from "../layout/quoteSlider"
import SitePagination from "../layout/sitePagination"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import { widont } from "../../utils/utils"

class WorkDetail extends Component {
	constructor(props) {
		super(props)

		this.state = {
			url: props.match.params.url,
			gallery: {
				pin: false,
				rtl: true,
				setHeight: "auto",
				buttonControl: true,
				pager: false,
				rotate: false,
				mouseControl: false,
				touchControl: true,
				keyControl: false,
				autoSlideControl: false,
				speed: 0.6,
				name: "gallery-inner"
			},
			quotes: {
				setHeight: "450px",
				pager: true,
				rotate: true,
				mouseControl: false,
				touchControl: true,
				autoSlideControl: true,
				autoInterval: 7,
				speed: 1,
				name: "quote-carousel-inner"
			},
			requestData: null,
			playVideo: false
		}

		this.scrollToWork = this.scrollToWork.bind(this)
		this.setState = this.setState.bind(this)
	}

	_widont() {
		widont('.t-bc, .richtext h2, .richtext p')
	}

	componentWillMount() {
		const { filters, Fetch, resetFilter } = this.props

		Fetch(`work/${this.state.url}?${queryString.stringify(filters.active)}`)
		if (filters.page !== "work") {
			resetFilter()
		}
		window.addEventListener('resize', this._widont)
		window.setTimeout(this._widont, 1000)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {
			const { filters, Fetch } = this.props

			Fetch(`work/${this.props.match.params.url}?${queryString.stringify(filters.active)}`)
		}
		this._widont()
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
		this._widont()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._widont)
	}

	scrollToWork() {
		let tl = new TimelineLite()

		tl.to(window, 1, {
			scrollTo: { y: document.getElementsByClassName("video-hero")[0].offsetTop - 66, autoKill: false },
			ease: Power2.easeOut,
			onComplete: () => {
				this.setState({ playVideo: true })
			}
		})
	}

	render() {
		const { gallery, quotes, requestData, playVideo } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined)
			return <PageLoadPlaceholder />

		const metaTitle = requestData.metaData.title,
			metaDesc = requestData.metaData.description,
			metaImage = requestData.metaData.image,
			metaUrl = location.href,
			description = requestData.description,
			workBy = requestData.office ? requestData.office.displayName : "",
			workByUrl = requestData.office ? `/where-we-are/${requestData.office.permaLink}` : "",
			isWorkByNull = requestData.office.isNullOffice,
			heroImage = requestData.heroImage,
			title = requestData.title,
			client = requestData.client,
			heroVideo = requestData.heroVideo,
			gallerySlides = requestData.gallerySlides,
			quoteSlides = requestData.quotes,
			additionalOffices = requestData.additionalOffices,
			awards = requestData.awards,
			office = requestData.office,
			relatedProjects = requestData.relatedProjects,
			nextUrl = requestData.nextUrl,
			prevUrl = requestData.prevUrl
		return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
				<link rel="canonical" href={metaUrl} />
				<meta name="description" content={metaDesc} />
				<meta property="og:url" content={metaUrl} />
				<meta property="og:title" content={metaTitle} />
				<meta property="og:description" content={metaDesc} />
				<meta property="og:image" content={metaImage} />
				<meta name="twitter:title" content={metaTitle} />
				<meta name="twitter:description" content={metaDesc} />
				<meta name="twitter:image" content={metaImage} />
				<meta name="twitter:image:alt" content={metaTitle} />
			</Helmet>
			<ImageHero
				marginDisabled
				images={heroImage}
				subtitle={title}
				title={client && client.title}
				workBy={workBy}
				workByUrl={workByUrl}
				isWorkByNull={isWorkByNull}
				additionalOffices={additionalOffices}
				link={heroVideo ? true : false}
				scrollToWork={this.scrollToWork}
			/>
			<section className="site-content">
				{/* Project Intro */}
				<Fade fraction={0.2}>
					<Intro gridSize="sml">
						<RichText className="pb50" marginDisabled dropLastPadding textAlign="left" dangerouslySet>
							{description}
						</RichText>
					</Intro>
				</Fade>

				{/* Awards Section */}
				{awards && awards.length > 0 && (
					<>
						<LineBreak gridSize="sml" />
						<AwardsList gridSize="sml" title="Awards" data={awards} />
						{/* <LineBreak gridSize="sml" /> */}
					</>
				)}

				{/* Project Video */}
				{heroVideo && (
					<VideoHero
						responsive
						data={heroVideo}
						playVideo={playVideo}
					/>
				)}

				{/* Gallery Slider */}
				{gallerySlides && gallerySlides.length > 0 && (
					<Fade fraction={0.2}>
						<GallerySlider options={gallery} slides={gallerySlides} />
					</Fade>
				)}

				{/* Quote Slider */}
				{quoteSlides && quoteSlides.length > 0 &&
					<QuoteSlider
						options={quotes}
						quotes={quoteSlides}
					/>
				}

				{/* Project Office CTA */}
				{/* {office && (
					<CtaHero
						gridSize="med"
						image={office.ctaImage && office.ctaImage}
						subtitle="Created by"
						title={`${office.displayName}`}
						url={"/where-we-are/" + office.permaLink}
					/>
				)} */}

				{/* Related Work */}
				{relatedProjects && relatedProjects.length > 0 && (
					<div className="grid med">
						<div className="row">
							{relatedProjects.map((project, index) => {
								return (
									<Fade delay={index * 400 + 200} duration={700} key={`project-${index}`}>
										<div className="col col-6" key={`project-${index}`}>
											<WorkCard
												data={project}
												workBy={workBy}
												relatedWork
												delay={index * 250 + 400}
											/>
										</div>
									</Fade>
								)
							})}
						</div>
					</div>
				)}
			</section>

			<SitePagination gridSize="lrg" title="work" permalink="/work" nextUrl={nextUrl} prevUrl={prevUrl} />
		</>
		)
	}
}

WorkDetail.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	filters: state.filters,
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category)),
	resetFilter: () => dispatch(resetFilter())
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(WorkDetail)
connectedPage = withRouter(connectedPage)

export default connectedPage
