import React from "react"
import Fade from "react-reveal/Fade"

const PresskitDownload = ({ marginDisabled, delay, fraction, args: { url, image, title, subtitle, imageAltText } }) => {
	const animationDuration = 300

	return (
		<a href={url} download className={"presskit-download module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
			<div className="over-hidden">
				<Fade bottom delay={delay} duration={animationDuration} fraction={fraction}>
					<div className="image">
						<Fade delay={delay + animationDuration} duration={animationDuration} fraction={fraction}>
							<div className="image-container">
								<img src={image} alt={imageAltText} className="img-responsive-max" />
							</div>
						</Fade>
					</div>
				</Fade>
			</div>
			{title && (
				<div className="title">
					<h3 className="h8">{title}</h3>
				</div>
			)}
			{subtitle && (
				<div className="subtitle">
					<div className="t-h5">{subtitle}</div>
				</div>
			)}
		</a>
	)
}

export default PresskitDownload
