import React from "react"

const LeadershipList = ({ marginDisabled, gridSize, pattern, children }) => {	
	//const pattern = [4, 4, 4, 4, 3, 3, 3] this is the actual pattern the code below tries to follow
	//col/pattern numbers speak towards the amount of columns the row will be divided into, e.g. 4 = divided into 4 cols.
	let currentPatternColVal = 4
	let nextPatternColVal = 4
	let lastLoopValue = 0
	let initPattern = pattern
	let finalPattern = []

	console.log(initPattern)
	
	if (initPattern) {
		finalPattern = initPattern;
	} else {
		children.map((index) => {
			currentPatternColVal = nextPatternColVal;		
			let currentIndex = parseInt(index.key) + 1;		
	
			if ((children.length % currentIndex === 1) && (lastLoopValue + currentPatternColVal != children.length) && currentIndex == (children.length - 1)) {
				//detects if the count is at the 2nd last number and the next col will be an orphan.
				// then sets both columns to fill 2 spaces.
				lastLoopValue = 0;
				currentPatternColVal = 2;
				nextPatternColVal = 2;
			} else if (currentPatternColVal === 4) {	
				//checks if the pattern expected is still 4 		
				if (currentIndex == (lastLoopValue + 4)) {
					//exit condition and swops over to the 3 pattern
					lastLoopValue = currentIndex;	
					nextPatternColVal = 3;
				}
			} else if (currentPatternColVal === 3) {
				//checks if the pattern expected is still 3 
				if (currentIndex == (lastLoopValue + 3)) {
					//exit condition and swops over to the 4 pattern
					lastLoopValue = currentIndex;
					nextPatternColVal = 4;
				}
			}
	
			return (				
				finalPattern.push(currentPatternColVal)
			)
		});
	}	

	console.log(finalPattern)

	return (
		<section className={"leadership-list "}>
			<section className="content">
				<div className={"grid full " + (gridSize ? gridSize : "")}>
					<div className="row flush start top">
						{children.map((item, index) => {											
							return (
								<div className={"col size-" + finalPattern[index]} key={"col-" + index}>
									{item}
								</div>
							)
						})}
					</div>
				</div>
			</section>
		</section>
	)
}

export default LeadershipList
