import { combineReducers } from 'redux';

import {
  HANDLE_SUBSCRIBE,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  SUBSCRIBE_RESET
} from '../actions/subscribe'

const INITITAL_STATE = {
  busy: false,
  status: null
}

function subscribe(state = INITITAL_STATE, action) {
  switch (action.type) {
    case HANDLE_SUBSCRIBE:
      return {
        ...state,
        busy: true,
        status: null
      }

    case SUBSCRIBE_SUCCESS:
      return {
        ...state,
        busy: false,
        status: true
      }

    case SUBSCRIBE_FAILURE:
      return {
        ...state,
        busy: false,
        status: false
      }

    case SUBSCRIBE_RESET:
      return {
        ...state,
        busy: false,
        status: null
      }

    default:
      return state
  }
}

export default subscribe
