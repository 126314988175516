import React from "react"
import CustomScroll from "react-custom-scroll"

const NeverNav = ({ children, closeFilter }) => (
	<section className="never-nav">
		<div className="overlay" onClick={closeFilter} />
		<section className="content">
			<CustomScroll heightRelativeToParent="100%">
				<div className="grid lrg">
					<div className="row">
						{children.map((item, index) => (
							<div className="col" key={"col-" + index}>
								{item}
							</div>
						))}
					</div>
				</div>
			</CustomScroll>
		</section>
		<button type="button" className="btn btn-never-nav-toggle" onClick={closeFilter}>
			Close navigation
		</button>
	</section>
)

export default NeverNav
