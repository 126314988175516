import React, { Component } from "react"
import { TweenMax, EasePack, Power2 } from "gsap/all"

class SearchBar extends Component {
	constructor(props) {
		super(props)

		this.animateIn = this.animateIn.bind(this)
		this.handleAnimation = this.handleAnimation.bind(this)
		this.getFocus = this.getFocus.bind(this)
	}

	handleAnimation() {
		if (this.props.searchActive) {
			this.animateIn()
		}
	}

	getFocus() {
		this.searchInput.focus()
	}

	animateIn() {
		let searchBar = document.getElementById("searchbar")

		TweenMax.fromTo(
			searchBar,
			0.75,
			{ opacity: 0, transform: "translateX(-614px)", ease: Power2.easeInOut },
			{ opacity: 1, transform: "translateX(0)", ease: Power2.easeInOut, clearProps: "all" }
		).delay(0.3)
	}

	componentDidMount() {
		this.handleAnimation()
		this.getFocus()
	}

	componentDidUpdate() {
		this.handleAnimation()
		this.getFocus()
	}

	render() {
		const { searchActive } = this.props

		return (
			<div id="searchbar" className={"search-bar" + (searchActive ? " search-active" : "")}>
				<form action="/results">
					<div className="form-input-wrap">
						<input
							type="text"
							name="keywords"
							placeholder="Search"
							autoComplete="off"
							autoFocus
							ref={ele => (this.searchInput = ele)}
						/>
						<button type="submit" className="btn btn-search">
							<span className="ico ico-font ico-search" />
						</button>
					</div>
				</form>
			</div>
		)
	}
}

export default SearchBar
