export const SET_FILTER = 'SET_FILTER'
export function setFilter(page, category, filters) {
  return {
    type: SET_FILTER,
    page: page,
    category: category,
    filters: filters
  }
}

export const RESET_FILTER = 'RESET_FILTER'
export function resetFilter() {
  return {
    type: RESET_FILTER
  }
}
