import React from "react"
const _ = require("lodash")

const StatsList = ({ marginDisabled, gridSize, children }) => (
	<section className={"stats-list module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
		<div className={"grid " + (gridSize ? gridSize : "")}>
			{_.filter(children, child => {
				if (child !== false && child !== null && child !== 0 && child !== "" && child != undefined) {
					return true
				} else {
					return false
				}
			})
				.map((item, index) => (
					<div className="col" key={"col-" + index}>
						{item}
					</div>
				))
				.reduce((rows, element, index) => {
					index % 3 === 0 && rows.push([])
					rows[rows.length - 1].push(element)
					return rows
				}, [])
				.map((rowContent, index) => (
					<div className="df jcsb" key={"row-" + index}>
						{rowContent}
					</div>
				))}
		</div>
	</section>
)

export default StatsList
