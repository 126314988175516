import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { fetch } from "../../actions/request"
import { resetFilter, setFilter } from "../../actions/filters"
import Fade from "react-reveal/Fade"
import { Helmet } from "react-helmet"

import Subnav from "../layout/subnav"
import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import ButtonList from "../layout/buttonList"
import Grid from "../layout/grid"
import PressCard from "../layout/pressCard"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import FilterByList from "../layout/filterbyList"

import { pressCenter as filterOptions } from "../../lib/filters"

const loadMoreLimit = 9

class PressCenter extends Component {
	constructor(props) {
		super(props)

		const { category = "All" } = queryString.parse(props.history.location.search)
		this.state = {
			visibleArticles: loadMoreLimit,
			requestData: null,
			selectedFilter: category,
			clearedFilter: 0
		}

		this.setFilter = this.setFilter.bind(this)
		this.loadMore = this.loadMore.bind(this)
		this.filterItems = this.filterItems.bind(this)
	}

	loadMore() {
		this.setState(prev => {
			return { visibleArticles: prev.visibleArticles + (this.state.requestData.loadMoreLimit || loadMoreLimit) }
		})
	}

	componentWillMount() {
		const { filters, Fetch, resetFilter, history, setFilter } = this.props

		Fetch("pages/view/press-center")

		if (filters.page !== "pressCenter") {
			resetFilter()
		}

		if (history.location.search) {
			const queryFilters = queryString.parse(history.location.search)
			if (queryFilters.category) {
				if (!Array.isArray(queryFilters.category)) {
					queryFilters.category = [queryFilters.category]
				}
				setFilter("pressCenter", "category", queryFilters.category)
			}
		}
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextState.clearedFilter === 1) {
			this.setFilter("All")
		}
	}

	componentWillReceiveProps(nextProps) {
		const { category = "All" } = queryString.parse(this.props.history.location.search)
		this.setState({
			requestData: nextProps.Request.data,
			selectedFilter: category,
			clearedFilter: this.state.clearedFilter + 1
		})
	}

	filterItems(items) {
		const { filters } = this.props
		let filteredItems = items
		if (filters.active.category && filters.active.category.length > 0) {
			filteredItems = items.filter(e => filters.active.category.includes(e.category))
		}
		return filteredItems
	}

	setFilter(key) {
		const category = filterOptions[0].key
		const { history, setFilter } = this.props
		const currentFilter = [key]
		if (key === "All") {
			setFilter("pressCenter", category, [])
			history.push(`${history.location.pathname}`)
			return
		}
		setFilter("pressCenter", category, currentFilter)
		history.push(`${history.location.pathname}?${queryString.stringify({ [category]: currentFilter })}`)
	}

	render() {
		const { requestData, visibleArticles, selectedFilter } = this.state
		const { toggleSubscribe } = this.props

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const filteredArticles = this.filterItems(requestData.articles) ? this.filterItems(requestData.articles) : [],
				allArticlesVisible = filteredArticles.length <= visibleArticles,
				title = requestData.pressCenterPage.title,
				hideTitle = requestData.pressCenterPage.hideTitle,
				content = requestData.pressCenterPage.content,
				heroImage = requestData.pressCenterPage.heroImage,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = window.location.href,
				filtered = requestData.articles.length !== filteredArticles.length

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<ImageHero marginDisabled images={heroImage} heroTitle={hideTitle ? "" : title} />
				<Subnav marginDisabled />
				<section className="site-content">
					<Fade fraction={0.3} duration={600}>
						<Intro marginDisabled gridSize="sml">
							<RichText marginDisabled textAlign="center" dangerouslySet>
								{content}
							</RichText>
							{/* <Fade bottom delay={200}>
								<ButtonList textAlign="center">
									<button onClick={toggleSubscribe} className="btn black has-icon btn-link">
										<span className="ico ico-font ico-mail-contact" />
										SUBSCRIBE TO OUR NEWS ALERTS
									</button>
								</ButtonList>
							</Fade> */}
						</Intro>
					</Fade>

					{filteredArticles && filteredArticles.length > 0 && (
						<FilterByList
							gridSize="lrg"
							title="Filter Content"
							filters={[
								{
									title: "All",
									key: "All"
								},
								...filterOptions[0].options
							]}
							activeFilter={selectedFilter}
							handleFilter={this.setFilter}
							noItems={filteredArticles.length < 1}
							noItemsMsg="There is no news to show based on your filter preferences."
							loadMore={this.loadMore}
							showLoadMore={!allArticlesVisible}
							loadMoreLabel="Load more content"
						>
							<Grid
								gridItems={filteredArticles.slice(0, visibleArticles)}
								rowClass={"start"}
								colnum={3}
								size={"lrg"}
								Child={PressCard}
								filtered={filtered}
								noRows
								uniqueKey={"date"}
							/>
						</FilterByList>
					)}
				</section>
			</>
			)
		}
	}
}

const mapStateToProps = state => ({
	filters: state.filters,
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category)),
	resetFilter: () => dispatch(resetFilter()),
	setFilter: (page, category, key) => dispatch(setFilter(page, category, key))
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(PressCenter)
connectedPage = withRouter(connectedPage)

export default connectedPage
