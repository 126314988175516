import React, { Component } from "react"
import CountUp from "react-countup"
import Fade from "react-reveal/Fade"

class StatsItem extends Component {
	constructor(props) {
		super(props)
		this.state = {
			trigger: false
		}
	}

	render() {
		const { marginDisabled, statDuration, statPrefix, statSuffix, statEndValue, statStartValue, statLabel } = this.props
		const { trigger } = this.state

		const startTrigger = trigger ? (statStartValue ? statStartValue : 0) : null

		const easingFn = (t, b, c, d) => {
			return c * (t /= d) * t + b
		}

		return (
			<Fade
				duration={0}
				delay={0}
				onReveal={() => {
					this.setState({ trigger: true })
				}}
			>
				<section className={"stats-item module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
					<CountUp
						className="stats-value"
						start={startTrigger}
						end={statEndValue}
						duration={statDuration}
						useEasing={true}
						easingFn={easingFn}
						separator=""
						prefix={statPrefix}
						suffix={statSuffix}
					/>
					<h5 className="h8 stats-label text-uppercase">{statLabel}</h5>
				</section>
			</Fade>
		)
	}
}

export default StatsItem
