export const DETAILS_REQUEST = 'DETAILS_REQUEST';
export function fetchDetails(category, url) {
  return {
    type: DETAILS_REQUEST,
    category, url
  };
}

export const DETAILS_REQUEST_SUCCESS = 'DETAILS_REQUEST_SUCCESS';
export function fetchDetailsSuccess(details) {
  return {
    type: DETAILS_REQUEST_SUCCESS,
    payload: details
  };
}

export const DETAILS_REQUEST_FAILURE = 'DETAILS_REQUEST_FAILURE';
export function fetchDetailsFailure(err) {
  return {
    type: DETAILS_REQUEST_FAILURE,
    payload: err
  };
}

export const REQUEST = 'REQUEST';
export function fetch(category) {
  return {
    type: REQUEST,
    payload: category
  };
}

export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export function fetchSuccess(list) {
  return {
    type: REQUEST_SUCCESS,
    payload: list
  };
}

export const REQUEST_FAILURE = 'REQUEST_FAILURE';
export function fetchFailure(err) {
  return {
    type: REQUEST_FAILURE,
    payload: err
  };
}

export const POST_REQUEST = 'POST_REQUEST';
export function post(url, payload) {
  return {
    type: POST_REQUEST,
    payload,
    url
  };
}

export const POST_REQUEST_SUCCESS = 'POST_REQUEST_SUCCESS';
export function postSuccess(list) {
  return {
    type: POST_REQUEST_SUCCESS,
    payload: list
  };
}

export const POST_REQUEST_FAILURE = 'POST_REQUEST_FAILURE';
export function postFailure(err) {
  return {
    type: POST_REQUEST_FAILURE,
    payload: err
  };
}

export const NEXT_PAGE = 'NEXT_PAGE';
export function next(category) {
  return {
    type: NEXT_PAGE,
    payload: category
  };
}

export const NEXT_PAGE_SUCCESS = 'NEXT_PAGE_SUCCESS';
export function nextSuccess(list) {
  return {
    type: NEXT_PAGE_SUCCESS,
    payload: list
  };
}

export const NEXT_PAGE_FAILURE = 'NEXT_PAGE_FAILURE';
export function nextFailure(err) {
  return {
    type: NEXT_PAGE_FAILURE,
    payload: err
  };
}
