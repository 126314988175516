import React from "react"
import { HashLink as Link } from 'react-router-hash-link';

const AwardsCard = ({ data: { award } }) => {
	return (
		<div className="awards-card">
			<div className="image">
				{
					award && award.link ?
					<Link scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'end'})}  to={award.link}>
					<img src={award && award.logo} alt={award.logoAltText} className="img-responsive" />
					</Link> :
				 	<img src={award && award.logo} alt={award.logoAltText} className="img-responsive" /> 
				}
			</div>
		</div>
	)
}

export default AwardsCard
