import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { Helmet } from "react-helmet"

import Subnav from "../layout/subnav"
import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import Grid from "../layout/grid"
import FilterByList from "../layout/filterbyList"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import FeedCard from "../layout/feedCard"

class FcbFeed extends Component {
	constructor() {
		super()

		this.state = {
			platform: "All",
			requestData: null
		}

		this.setPlatform = this.setPlatform.bind(this)
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/feed")
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	setPlatform(platform) {
		this.setState({
			platform: platform
		})
	}

	render() {
		const { platform, requestData } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const heroImage = requestData.feedPage.heroImage,
				title = requestData.feedPage.title,
				hideTitle = requestData.feedPage.hideTitle,
				content = requestData.feedPage.content,
				feed = requestData.feed,
				platforms = requestData.platforms,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href,
				filteredFeed = feed ? feed.filter(item => item.feedType == platform || platform === "All") : []

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<ImageHero marginDisabled images={heroImage} heroTitle={hideTitle ? "" : title} />
				<Subnav marginDisabled />
				<section className="site-content">
					<Intro gridSize="med">
						<RichText marginDisabled dropLastPadding textAlign="center" dangerouslySet>
							{content}
						</RichText>
					</Intro>
					{filteredFeed && (
						<FilterByList
							marginDisabled
							gridSize="lrg"
							title="Filter by platform"
							filters={platforms}
							activeFilter={platform}
							handleFilter={this.setPlatform}
							noItems={filteredFeed.length < 1}
							noItemsMsg="We do not currently have any feed entries in this category"
						>
							<div className="feed-list module-margin">
								<Grid
									gridItems={[...filteredFeed].sort((a,b) => new Date(b.sortByDate) - new Date(a.sortByDate))}
									rowClass=""
									noRows
									colnum={3}
									size={"lrg"}
									Child={FeedCard}
									uniqueKey={"sortByDate"}
								/>
							</div>
						</FilterByList>
					)}
				</section>
			</>
			)
		}
	}
}

FcbFeed.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(FcbFeed)
connectedPage = withRouter(connectedPage)

export default connectedPage
