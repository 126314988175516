import React from "react"
import Link from "./link"
import Fade from "react-reveal/Fade"

const RelatedList = ({ marginDisabled, gridSize, title, linkUrl, linkText, children }) => (
	<section className={"related-list module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
		<section className="head">
			<div className={"grid " + (gridSize ? gridSize : "")}>
				<div className="row">
					<div className="col">
						{title && <h2>{title}</h2>}
						{(linkUrl || linkText) && (
							<Fade bottom delay={200}>
								<Link url={linkUrl} title={linkText} classProp="btn btn-link black">
									{linkText}
								</Link>
							</Fade>
						)}
					</div>
				</div>
			</div>
		</section>
		<section className="content">
			<div className="grid med">
				<div className="row">{children}</div>
			</div>
		</section>
	</section>
)

export default RelatedList
