import React from 'react'

const Intro = ({ marginDisabled, gridSize, children, className }) => (
  <section className={"intro module-margin " + (marginDisabled ? "module-margin-disabled " : "") + `${className}`}>
    <div className={"grid " + (gridSize ? gridSize : "")}>
      <div className="row">
        <div className="col">
          <section className="content">
            {children}
          </section>
        </div>
      </div>
    </div>
  </section>
)

export default Intro
