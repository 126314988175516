import React from "react"
import HcCarousel from "./hcCarousel"

const QuoteSlider = ({ options, quotes }) => (
<section className="quote-carousel module-margin">
	<div className="grid sml">
		<div className="row">
			<div className="col">
				<HcCarousel options={options}>
				{
					quotes.map(({ quote, author }, index) => (
					<article key={`quote-${ index }`}>
						<div className="content">
							<div className="quote-text">
								<div className="fz32 lh1.3 fz44@md">
								{ quote }
								</div>
							</div>
							{author &&
								<div className="quote-author">
									<h5 className="h8">
									{ author }
									</h5>
								</div>
							}
						</div>
					</article>
					))
				}
				</HcCarousel>
			</div>
		</div>
	</div>
</section>
)

export default QuoteSlider
