import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { fetch } from "../../actions/request"
import { resetFilter, setFilter as filterAction } from "../../actions/filters"
import { Helmet } from "react-helmet"

import Intro from "../layout/intro"
import RichText from "../layout/richText"
import ImageHero from "../layout/imageHero"
import Grid from "../layout/grid"
import WorkCard from "../layout/workCard"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import FilterByList from "../layout/filterbyList"

import { work as filterOptions } from "../../lib/filters"

const loadMoreLimit = 12

class Work extends Component {
	constructor(props) {
		super(props)

		const { region = "All" } = queryString.parse(props.history.location.search)
		this.state = {
			visibleProjects: loadMoreLimit,
			requestData: null,
			selectedFilter: region,
			clearedFilter: 0
		}

		this.setFilter = this.setFilter.bind(this)
		this.loadMore = this.loadMore.bind(this)
		this.filterItems = this.filterItems.bind(this)
	}

	componentWillMount() {
		const { filters, Fetch, resetFilter, history, setFilter } = this.props

		Fetch("pages/view/work")

		if (filters.page !== "work") {
			resetFilter()
		}

		if (history.location.search) {
			const queryFilters = queryString.parse(history.location.search)
			;["office", "region", "tag"].forEach(key => {
				if (queryFilters[key]) {
					if (!Array.isArray(queryFilters[key])) {
						queryFilters[key] = [queryFilters[key]]
					}
					setFilter("work", key, queryFilters[key])
				}
			})
		}
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextState.clearedFilter === 1) {
			this.setFilter("All")
		}
	}

	componentWillReceiveProps(nextProps) {
		const { region = "All" } = queryString.parse(this.props.history.location.search)

		this.setState({
			requestData: nextProps.Request.data,
			selectedFilter: region,
			clearedFilter: this.state.clearedFilter + 1
		})
	}

	loadMore() {
		this.setState(prev => {
			return { visibleProjects: prev.visibleProjects + (this.state.requestData.loadMoreLimit || loadMoreLimit) }
		})
	}

	filterItems(items) {
		const { filters } = this.props
		let filteredItems = items
		if (filters.active.office && filters.active.office.length > 0) {
			filteredItems = filteredItems.filter(e => filters.active.office.includes(e.office.displayName))
		}
		if (filters.active.region && filters.active.region.length > 0) {
			filteredItems = filteredItems.filter(e => filters.active.region.includes(e.office.region))
		}
		if (filters.active.tag && filters.active.tag.length > 0) {
			filteredItems = filteredItems.filter(e => e.tags.some(r => filters.active.tag.includes(r)))
		}
		return filteredItems
	}

	setFilter(key) {
		const category = filterOptions[0].key
		const { history, setFilter } = this.props
		const currentFilter = [key]
		if (key === "All") {
			setFilter("work", category, [])
			history.push(`${history.location.pathname}`)
			return
		}
		setFilter("work", category, currentFilter)
		history.push(`${history.location.pathname}?${queryString.stringify({ [category]: currentFilter })}`)
	}

	render() {
		const { requestData, visibleProjects, selectedFilter } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const filteredProjects = this.filterItems(requestData.projects),
				allProjectsVisible = filteredProjects.length <= visibleProjects,
				// filtered = requestData.projects.length !== filteredProjects.length,
				title = requestData.workPage.title,
				hideTitle = requestData.workPage.hideTitle,
				heroImage = requestData.workPage.heroImage,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaImageAltText = requestData.metaData.imageAltText,
				metaUrl = location.href

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaImageAltText} />
				</Helmet>

				<ImageHero marginDisabled images={heroImage} heroTitle={hideTitle ? "" : title} />
				
				<section className="site-content">
					<Intro gridSize="med">
						<RichText marginDisabled dropLastPadding textAlign="center" dangerouslySet>
							{`<h2>Our Latest Work</h2>`}
						</RichText>
					</Intro>

					{filteredProjects && (
						<FilterByList
							gridSize="lrg"
							title="Filter by Region"
							filters={["All", ...filterOptions[0].options]}
							activeFilter={selectedFilter}
							handleFilter={this.setFilter}
							noItems={filteredProjects.length < 1}
							noItemsMsg="There is no work to show based on your filter preferences."
							loadMore={this.loadMore}
							showLoadMore={!allProjectsVisible}
							history={history}
							loadMoreLabel="Load more work"
						>
							<Grid
								gridItems={filteredProjects.slice(0, visibleProjects)}
								rowClass={"flush start nowrap"}
								colnum={2}
								size={"lrg"}
								Child={WorkCard}
								uniqueKey={"title"}
							/>
						</FilterByList>
					)}
				</section>
			</>	
			)
		}
	}
}

Work.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	filters: state.filters,
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category)),
	resetFilter: () => dispatch(resetFilter()),
	setFilter: (page, category, key) => dispatch(filterAction(page, category, key))
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(Work)
connectedPage = withRouter(connectedPage)

export default connectedPage
