import React from "react"
import MediaItem from "../layout/mediaItem"

const PressMedia = ({ marginDisabled, media, alt, gridSize }) => (
	<section className={"press-image module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
		<div className={"grid " + (gridSize ? gridSize : "")}>
			<div className="row">
				<div className="col">
					<MediaItem image={media.image} alt={media.mediaAltText} videoUrlIframe={media.video} ratio="16-9" />
				</div>
			</div>
		</div>
	</section>
)

export default PressMedia
