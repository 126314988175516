import React from "react"
import ReactPlayer from "react-player"

const MediaItem = ({ className, extraStyles, image, alt, videoUrlIframe, videoUrlMp4, videoUrlWebm, ratio, includeCover }) => (
	<section
		className={`media-item ${className} ` + (ratio ? "ratio-" + ratio + " " : "")}
		style={{ backgroundImage: `url("${image}")` }}
		title={alt}
	>
		{includeCover && <div className="cover"></div>}
		{(videoUrlMp4 && !videoUrlIframe) || (videoUrlWebm && !videoUrlIframe) ? (
			<video autoPlay loop playsInline muted aria-hidden="true">
				{videoUrlMp4 && <source src={videoUrlMp4} type="video/mp4" />}
				{videoUrlWebm && <source src={videoUrlWebm} type="video/webm" />}
			</video>
		) : videoUrlIframe ? (
			<ReactPlayer
				className="video"
				url={videoUrlIframe}
				width="100%"
				height="100%"
				playing={false}
				loop={true}
				playsInline={true}
				volume={0.3}
				controls={true}
			/>
		) : null}
	</section>
)

export default MediaItem
