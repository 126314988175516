import React, { Component } from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import _ from "lodash"

class Grid extends Component {
	constructor(props) {
		super(props)
	}

	shouldComponentUpdate(nextProps) {
		return !_.isEqual(this.props.gridItems, nextProps.gridItems)
	}

	render() {
		const {
			gridItems,
			noRows,
			rowClass,
			colnum,
			maxCols,
			size,
			Child,
			args,
			filtered,
			uniqueKey,
			gridName // Requires a unique name for key generation if there's more than one Grid component being used on the same page
		} = this.props

		let rows = Math.ceil(gridItems.length / colnum)
		let cols = new Array(colnum)
		let numberOfColumns = colnum

		if (!colnum && maxCols && gridItems.length >= maxCols) {
			numberOfColumns = maxCols
		}

		const gridColnum = 12
		const colsize = gridColnum / numberOfColumns

		// Animation settings
		const animationDuration = 300
		const animationDelay = 100
		const animationFraction = 0.2

		// No Grid Items to render
		if (filtered && gridItems.length === 0) {
			return <h3>There are no items to show based on your filter preferences.</h3>
		}

		if (!noRows) {
			// Grid Items rendered in individual row elements determined by the number of columns (colnum) specified by the user
			return (
				<div className={`grid ${size}`}>
					{[...Array(rows)].map((item, index) => {
						let start = colnum * index ? colnum * index : 0
						let end = start + colnum < gridItems.length ? start + colnum : gridItems.length

						cols[index] = new Array()

						for (let iter = start; iter < end; iter++) {
							const delay = (iter - colnum * index) * animationDelay

							cols[index].push(
								<div key={gridName + "-col-" + iter} className={`col col-${colsize}`}>
									<Fade duration={animationDuration} delay={delay} fraction={animationFraction}>
										<Child
											key={
												uniqueKey
													? index + "-" + gridItems[iter][uniqueKey].replace(/\s+/g, "-").toLowerCase()
													: gridName + "-grid-item-" + iter
											}
											data={gridItems[iter]}
											delay={delay}
											fraction={animationFraction}
											args={args && args(gridItems[iter])}
										/>
									</Fade>
								</div>
							)
						}

						return (
							<div key={gridName + "-row-" + index} className={`row` + (rowClass ? " " + rowClass : "")}>
								{cols[index]}
							</div>
						)
					})}
				</div>
			)
		} else {
			// Grid items rendered within a sinle row element:
			// if colnum IS SET items will be rendered according to that sizing
			// if colnum ISN'T SET items will dynamically adjust their width to the available space or will wrap to a next line based on the maxCol prop, if it's been set
			return (
				<div className={`grid ${size}`}>
					<div className={`row` + (rowClass ? " " + rowClass : "")}>
						{gridItems.map((item, index) => {
							const delay = (index - numberOfColumns * Math.floor(index / numberOfColumns)) * 200

							return (
								<div key={gridName + "-col-" + index} className={`col ${numberOfColumns ? `col-${colsize}` : ""}`}>
									<Fade duration={animationDuration} delay={delay}>
										<Child
											key={
												uniqueKey
													? index + "-" + item[uniqueKey].replace(/\s+/g, "-").toLowerCase()
													: gridName + "-grid-item-" + index
											}
											data={item}
											delay={delay}
											args={args && args(item)}
										/>
									</Fade>
								</div>
							)
						})}
					</div>
				</div>
			)
		}
	}
}

Grid.defaultProps = {
	maxCols: 1,
	size: "lrg",
	filtered: false,
	gridName: "grid"
}

Grid.propTypes = {
	data: PropTypes.object,
	colnum: PropTypes.number,
	size: PropTypes.string,
	filtered: PropTypes.bool
}

export default Grid
