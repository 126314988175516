import React, { Component } from "react"
import ButtonList from "./buttonList"
import Fade from "react-reveal/Fade"

class FilterByList extends Component {
	constructor(props) {
		super(props)

		this.state = {
			busy: true,
			height: 0
		}

		this.loadMore = this.loadMore.bind(this)
	}

	loadMore() {
		const { loadMore } = this.props

		loadMore()

		this.setState({
			height: this.list.offsetHeight
		})
	}

	componentDidUpdate(nextProps, nextState) {
		if (this.state.height !== nextState.height) {
			this.setState({
				busy: false
			})
		}
	}

	render() {
		const {
			marginDisabled,
			gridSize,
			title,
			children,
			filters,
			handleFilter,
			activeFilter,
			noItems,
			noItemsMsg,
			showLoadMore,
			gridClass,
			loadMoreLabel
		} = this.props

		return (
			<section
				ref={event => {
					this.list = event
				}}
				className={
					"filterby-list module-margin " + (marginDisabled ? "module-margin-disabled " : "") + (gridClass ? gridClass : "")
				}
			>
				<div className={"grid " + (gridSize ? gridSize : "")}>
					<div className="df fxdrc fxdrr@md aic pb20 pt20 gap40 gap20@md">
					{title && <div className="t-link">{title}</div>}
					{filters.map(item => {
												const label = item.title ? item.title : item
												const key = item.key ? item.key : item
												return (
													<button
														name={key}
														key={key}
														className={`btn btn-filter-link${key == activeFilter ? " active" : ""}`}
														onClick={() => handleFilter(key)}
													>
														{label}
													</button>
												)
											})}
					</div>
					<div className="row">
						<div className="col">
							<section className="content">
								{noItems ? (
									<div className="module-margin">
										<Fade delay={200} duration={600}>
											<div className="t-h3">{noItemsMsg}</div>
										</Fade>
									</div>
								) : (
									children
								)}
							</section>

							{showLoadMore && (
								<Fade bottom delay={200}>
									<button className="btn btn-link black load-more" onClick={this.loadMore}>
										{loadMoreLabel}
									</button>
								</Fade>
							)}
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default FilterByList
