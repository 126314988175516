import { all } from 'redux-saga/effects'
import { requestSaga } from './request'
import { submissionSaga } from './submission'
import { subscribeSaga } from './subscribe'

export default function* rootSaga() {
  yield all([
    ...requestSaga,
    ...submissionSaga,
    ...subscribeSaga
  ])
}
