import React from "react"
import Media from "react-media"
import Link from "./link"
import RichText from "./richText"

const buildAdditionalOffices = (additionalOffices) =>  {
	const n = []
	if(additionalOffices)
	{
		additionalOffices.map((x,i) => {
			if(x.url && x.url.length > 0) {
				n.push(<span key={i}><a href={x.url}>{x.officeName}</a>{i < additionalOffices.length - 1 ? ',' : ''}</span>)
			} else {
				n.push(<span key={i}>{x.officeName}{i < additionalOffices.length - 1 ? ',' : ''}</span>)
			}
		})
	}
	return n
}

const SliderVertical = ({
	openVideoOverlay,
	data: { title, titleImage, subtitle, description, linkUrl, linkLabel, gradient, imageWeb, imageWebAltText, imageMobile, imageMobileAltText, mp4, webm, iframeUrl, office, additionalOffices, awardImage, awardImageAltText, awardCopy }
}) => (
	<article>
		{imageWeb && imageMobile ? (
			<Media query="(min-width: 768px)">
				{matches =>
					matches ? (
						<div className="media-background" title={imageWebAltText} style={{ backgroundImage: "url(" + imageWeb.replace(/ /g, "%20") + ")" }}>
							{(mp4 || webm) && (
								<video aria-hidden="true" autoPlay loop playsInline muted>
									{mp4 && <source src={mp4} type="video/mp4" />}
									{webm && <source src={webm} type="video/webm" />}
								</video>
							)}
						</div>
					) : (
						<div className="media-background" title={imageMobileAltText} style={{ backgroundImage: `url("${imageMobile.replace(/ /g, "%20")}")` }}>
							{(mp4 || webm) && (
								<video aria-hidden="true" autoPlay loop playsInline muted>
									{mp4 && <source src={mp4} type="video/mp4" />}
									{webm && <source src={webm} type="video/webm" />}
								</video>
							)}
						</div>
					)
				}
			</Media>
		) : imageWeb ? (
			<div className="media-background" title={imageWebAltText} style={{ backgroundImage: `url("${imageWeb.replace(/ /g, "%20")}")`}}>
				{(mp4 || webm) && (
					<video aria-hidden="true" autoPlay loop playsInline muted>
						{mp4 && <source src={mp4} type="video/mp4" />}
						{webm && <source src={webm} type="video/webm" />}
					</video>
				)}
			</div>
		) : null}
		<div className="content" style={{backgroundImage:"url('" + gradient + "')"}}>
			<div className="content-items">

				{subtitle && (
					<div className="subtitle">
						<div className="h5" style={{fontSize:"1.5rem"}}>{subtitle}</div>
					</div>
				)}
				{titleImage ? (
					<div className="title">
						<img src={titleImage} alt={title} className="img-responsive-max" style={{ "width": "356px" }} />
					</div>
				) : (
					<div className="title">
						<h1 className="h7">{title}</h1>
					</div>)
				}
				{description && (
					<div className="description">
						<RichText marginDisabled dangerouslySet>
							{description}
						</RichText>
					</div>
				)}
				<div className="link">
					{linkUrl && linkLabel && (
						<Link url={linkUrl} classProp="btn btn-carousel">
							{linkLabel}
						</Link>
					)}
					{iframeUrl && (
						<div className="play-video">
							<button title="play" className="btn btn-play" onClick={() => openVideoOverlay(iframeUrl)}>
								<span className="ico ico-font ico-play" />
							</button>
						</div>
					)}
				</div>
				<div className={`award ${awardImage ? `` : `dn`}`}>
					<img src={awardImage} width="80" height="24" alt={awardImageAltText} />
					<div className="ff-cp fz12 lh1">{awardCopy}</div>
				</div>
			</div>
		</div>
		<div className="actions">
			{iframeUrl && (
				<div className="play-video">
					<button title="play" className="btn btn-play" onClick={() => openVideoOverlay(iframeUrl)}>
						<span className="ico ico-font ico-play" />
					</button>
				</div>
			)}
		</div>
		<div className="">
			{office && office.displayName && (

				<div className="work-by-alt ff-cp">
					<span>Work by</span> 
					{!office.isNullOffice  && 
						<span><a href={`/where-we-are/${office.permaLink}`}>{`${office.displayName}`}</a>{`${additionalOffices && additionalOffices.length > 0 ? ',' : ''}`}</span>
					}
					{office.isNullOffice &&
						<span>{`${office.displayName}${`${additionalOffices && additionalOffices.length > 0 ? ',' : ''}`}`}</span>
					}
					{buildAdditionalOffices(additionalOffices)}
				</div>

			)}
		</div>

	</article>
)

export default SliderVertical
