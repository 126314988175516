import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { post } from "../../actions/request"

import ImageHero from "../layout/imageHero"
import Intro from "../layout/intro"
import RichText from "../layout/richText"
import Link from "../layout/link"
import ButtonList from "../layout/buttonList"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"

class Verify extends Component {
	constructor(props) {
		super(props)

		this.state = {
			requestData: null
		}
	}

	componentWillMount() {
		const { Post, match } = this.props

		Post("enquiries/verify", { token: match.params.token })
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	render() {
		const { requestData } = this.state
		const {
			Request: { busy, status }
		} = this.props

		if (requestData === null || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			return (
			<>
				<ImageHero marginDisabled gradientBg heroTitle="Email verifcation" />
				<section className="site-content">
					<Intro gridSize="med">
						<RichText marginDisabled textAlign="center">
							{!busy && status && <h2>Thank you for verifying your email address.</h2>}
							{!busy && !status && <h2>This verification token has already been used.</h2>}
						</RichText>
						<ButtonList marginDisabled textAlign="center">
							<Link url="/" classProp="btn btn-link black">
								Go home
							</Link>
						</ButtonList>
					</Intro>
				</section>
			</>
			)
		}
	}
}

Verify.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Post: (url, data) => dispatch(post(url, data))
})

let connectedPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Verify)
connectedPage = withRouter(connectedPage)

export default connectedPage
