import React from "react"

const CompanyCard = ({ marginDisabled, image, imageAlt, children }) => (
	<section className={"company-card module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
		<div className="grid">
			<div className="row flush start top">
				<div className="col col-3">
					<div className="image">
						<img src={image} alt={imageAlt} className="img-responsive" />
					</div>
				</div>
				<div className="col col-6">
					<div className="content">{children[0]}</div>
				</div>
				<div className="col col-3 company-contact">
					<div className="content">{children[1]}</div>
				</div>
			</div>
		</div>
	</section>
)

export default CompanyCard
