import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Field, reduxForm } from "redux-form"

import { TweenMax, ScrollToPlugin } from "gsap/all"
import { required, validEmail } from "../../lib/validation"
import { handleSubscribe, subscribeReset } from "../../actions/subscribe"

class SubscribeOverlay extends Component {
	constructor(props) {
		super(props)

		this.state = {
			animationRan: false
		}
		this.handleAnimation = this.handleAnimation.bind(this)
		this.animateIn = this.animateIn.bind(this)
		this.animateOut = this.animateOut.bind(this)
		this.handleOnClose = this.handleOnClose.bind(this)
		this.submit = this.submit.bind(this)
	}

	handleAnimation() {
		if (this.props.subscribeVisible) {
			this.animateIn()
			this.setState({
				animationRan: true
			})
		}
	}

	animateIn() {
		let body = document.querySelector("body"),
			subscribeOverlay = document.getElementsByClassName("subscribe-overlay"),
			subscribeOverlayBackground = document.querySelector(".subscribe-overlay .overlay")

		body.classList.add("hide-overflow")

		TweenMax.fromTo(
			subscribeOverlay,
			0.8,
			{ display: "none", transform: "translateY(101vh)" },
			{ display: "block", transform: "translateY(0)" }
		)

		TweenMax.fromTo(subscribeOverlayBackground, 0.3, { autoAlpha: 0 }, { autoAlpha: 0.5 }).delay(0.9)
	}

	animateOut(cb) {
		let body = document.querySelector("body"),
			subscribeOverlay = document.getElementsByClassName("subscribe-overlay"),
			subscribeOverlayBackground = document.querySelector(".subscribe-overlay .overlay")

		TweenMax.to(subscribeOverlayBackground, 0.3, { autoAlpha: 0 })

		TweenMax.to(subscribeOverlay, 0.8, { transform: "translateY(100vh)" }).delay(0.4)

		TweenMax.to(subscribeOverlay, 0.1, { display: "none", onComplete: cb }).delay(1.3)

		body.classList.remove("hide-overflow")
	}

	handleOnClose(cb) {
		this.animateOut(cb)
	}

	submit(values) {
		const { Post } = this.props
		Post("enquiries/subscribe", values)
	}

	componentDidMount() {
		const { ResetSubscribe, Subscribe } = this.props
		if (Subscribe.status === true) {
			ResetSubscribe()
		}
		this.handleAnimation()
	}

	componentDidUpdate() {
		if (this.props.subscribeVisible && this.state.animationRan !== true) {
			this.handleAnimation()
		}
	}

	render() {
		const {
			toggleSubscribe,
			Subscribe: { busy, status }
		} = this.props

		return (
			<section className="subscribe-overlay">
				<div className="overlay" onClick={() => this.handleOnClose(toggleSubscribe)} />
				<div className="subscribe-container">
					<div className="content">
						{!status && <div className="t-h3">Subscribe to our newsletter</div>}
						<div className={`subscribe-form ${!busy && status ? "thanks" : ""}`}>
							{!status && <SubscribeForm onSubmit={this.submit} />}
							{!busy && status && (
								<div className="t-h3">
									Thanks! We've sent you a verification email.
									<br />
									Please check your inbox to confirm.
								</div>
							)}
						</div>
					</div>
				</div>
				<button className="btn btn-subscribe-close" onClick={() => this.animateOut(toggleSubscribe)}>
					<span className="ico ico-font ico-close" />
				</button>
			</section>
		)
	}
}

let SubscribeForm = props => {
	const { handleSubmit } = props
	return (
		<form onSubmit={handleSubmit} className="forms" autoComplete="off">
			<Field name="email" label="Enter your email address" component={Input} type="email" validate={[required, validEmail]} />
			<div className="formfield">
				<button type="submit" className="btn btn-carousel black">
					Subscribe
				</button>
			</div>
		</form>
	)
}

const Input = ({ input, label, meta }) => (
	<div className={`formfield ${meta.touched && meta.error ? "validation-error" : ""}`}>
		<label htmlFor={input.name} className="label">
			{label}
		</label>
		<div className="input">
			<input {...input} />
		</div>
		<span className="validation-text">{meta.touched && meta.error && meta.error}</span>
	</div>
)

SubscribeForm = reduxForm({
	form: "SubscribeForm"
})(SubscribeForm)

const mapStateToProps = state => ({
	Subscribe: state.subscribe
})

const mapDispatchToProps = dispatch => ({
	Post: (url, payload) => dispatch(handleSubscribe(url, payload)),
	ResetSubscribe: () => dispatch(subscribeReset())
})

let connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(SubscribeOverlay)
connectedComponent = withRouter(connectedComponent)

export default connectedComponent
