import { put, takeLatest, call } from 'redux-saga/effects';

import {
  REQUEST,
  NEXT_PAGE,
  DETAILS_REQUEST,
  POST_REQUEST
} from '../actions/request.js';

import {
  get,
  post
} from '../utils/utils';

import {
  nextSuccess,
  nextFailure,
  fetchSuccess,
  fetchFailure,
  postSuccess,
  postFailure,
  fetchDetailsSuccess,
  fetchDetailsFailure
} from '../actions/request';

function* handleDetails(request) {

  try {
    const details = yield call(get, `/${request.category}/${request.url}`);

    yield put(fetchDetailsSuccess(details));
  } catch (err) {
    yield put(fetchDetailsFailure(err));
  }
}

function* handlePage(request) {

  try {
    const list = yield call(get, `/${request.payload}`);

    yield put(nextSuccess(list));
  } catch (err) {
    yield put(nextFailure(err));
  }
}

function* handleList(request) {

  try {
    const list = yield call(get, `/${request.payload}`);

    yield put(fetchSuccess(list));
  } catch (err) {
    yield put(fetchFailure(err));
  }
}

function* handlePost(request) {

  try {
    const list = yield call(post, `/${request.url}`, request.payload);

    yield put(postSuccess(list));
  } catch (err) {
    yield put(postFailure(err));
  }
}

export const requestSaga = [
  takeLatest(REQUEST, handleList),
  takeLatest(NEXT_PAGE, handlePage),
  takeLatest(DETAILS_REQUEST, handleDetails),
  takeLatest(POST_REQUEST, handlePost)
];
