import React, { Component } from "react"
import MediaItem from "../layout/mediaItem"
import Link from "./link"
import moment from "moment"
import { articleCategory } from "../../lib/filterConversion"
import Fade from "react-reveal/Fade"
const { detect } = require("detect-browser")
const browser = detect()

const fadeInDuration = 700
const slideUpDuration = 500

class PressCard extends Component {
	constructor(props) {
		super(props)
	}

	shouldComponentUpdate(nextProps) {
		if (this.props.data._id === nextProps.data._id) {
			return false
		} else {
			return true
		}
	}

	render() {
		const {
			delay,
			marginDisabled,
			data,
			data: { date, permaLink, card, cardAltText, category, title, office, createdAt, isExternal, externalLink }
		} = this.props

		let publishDate = moment(date).format("DD MMMM YYYY")

		const PressCardContent = () => {
			return (
				<div className="over-hidden">
					<Fade bottom delay={delay} duration={slideUpDuration}>
						<div className="image">
							<Fade delay={delay + slideUpDuration} duration={fadeInDuration}>
								{card ? (
									<MediaItem image={card} alt={cardAltText} ratio="1-1" includeCover />
								) : (
									<div
										className={
											"gradient" + (browser.name === "safari" || browser.name === "ios" ? " browser-safari " : " ")
										}
									/>
								)}
							</Fade>
						</div>
					</Fade>
					<Fade delay={delay + slideUpDuration + 100} duration={fadeInDuration}>
						<div className="press-card-container">
							<div className="top">
								{category && (
									<div className="" style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "start",
									}}>
										<div className="subtitle">
											<span className="line" />
											<div className="t-link">{articleCategory[category].toUpperCase()}</div>
										</div>
									</div>
								)}
							</div>
							<div className="btm">
								{title && (
									<div className="title">
										<div className="t-h5">{title}</div>
									</div>
								)}
								{(office.city || publishDate) && (
									<div className="location">
										<h5 className="h8">
											{office.city && `${office.city}, `} {publishDate}
										</h5>
									</div>
								)}
							</div>
						</div>
					</Fade>
				</div>
			)
		}

		return (
		<>
			{isExternal ? (
				<a
					href={externalLink}
					target="_blank"
					className={"press-card module-margin " + (marginDisabled ? "module-margin-disabled " : "")}
				>
					<PressCardContent />
				</a>
			) : (
				<Link
					url={"/press/press-center/" + permaLink}
					classProp={"press-card module-margin " + (marginDisabled ? "module-margin-disabled " : "")}
				>
					<PressCardContent />
				</Link>
			)}
		</>
		)
	}
}

export default PressCard
