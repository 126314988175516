import React from "react"

const ContactList = ({ marginDisabled, gridSize, title, children }) => (
	<section className={"contact-list module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
		{title && (
			<section className="head">
				<div className={"grid " + (gridSize ? gridSize : "")}>
					<div className="row">
						<div className="col">{title && <h3 className="h2">{title}</h3>}</div>
					</div>
				</div>
			</section>
		)}
		<section className="content">{children}</section>
	</section>
)

export default ContactList
