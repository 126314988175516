import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import request from './request'
import navigation from './pagetransition'
import filters from './filters'
import submission from './submission'
import subscribe from './subscribe'

export default combineReducers({
  form: formReducer,
  filters,
  navigation,
  request,
  submission,
  subscribe
})
