import React from "react"
import Fade from "react-reveal/Fade"
const { detect } = require("detect-browser")
const browser = detect()

const QuoteHero = ({ marginDisabled, gridSize, quoteText, quoteAuthor }) => (
	<section
		className={
			"quote-hero" +
			(browser.name === "safari" || browser.name === "ios" ? " browser-safari " : " ") +
			"module-margin" +
			(marginDisabled ? " module-margin-disabled " : " ")
		}
	>
		<div className={"grid " + (gridSize ? gridSize : "")}>
			<div className="row">
				<Fade fraction={0.3}>
					<div className="col">
						{quoteText && (
							<div className="quote-text">
								<div className="t-h3">{quoteText}</div>
							</div>
						)}
						{quoteAuthor && (
							<div className="quote-author">
								<h6 className="h8">{quoteAuthor}</h6>
							</div>
						)}
					</div>
				</Fade>
			</div>
		</div>
	</section>
)

export default QuoteHero
