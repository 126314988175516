import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { Helmet } from "react-helmet"

import Subnav from "../layout/subnav"
import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import LeadershipList from "../layout/leadershipList"
import LeadershipCard from "../layout/leadershipCard"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import { widont } from "../../utils/utils"

class Leadership extends Component {
	constructor() {
		super()

		this.state = {
			requestData: null
		}
	}


	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/leadership")

	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}



	render() {
		const { requestData } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const title = requestData.leadershipPage.title,
				hideTitle = requestData.leadershipPage.hideTitle,
				heroImage = requestData.leadershipPage.heroImage,
				content = requestData.leadershipPage.content,
				patternOverride = requestData.leadershipPage.patternOverride ? requestData.leadershipPage.patternOverride.split(',') : null,
				leaders = requestData.leaderships,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href				

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<ImageHero marginDisabled images={heroImage} heroTitle={hideTitle ? "" : title} />
				{/* <Subnav /> */}
				{/* <Intro gridSize="sml">
					<RichText marginDisabled dropLastPadding textAlign="center" dangerouslySet>
						{`<h2 class="h11">Our Global Team</h2>${content !== "<p><br></p>" ? content : ""}`}
					</RichText>
				</Intro> */}

				{leaders && leaders.length > 0 && (
					<LeadershipList marginDisabled gridSize="lrg" pattern={patternOverride}>
						{leaders.map((item, index) => {
							return <LeadershipCard key={index} marginDisabled data={item} />
						})}
					</LeadershipList>
				)}
			</>
			)
		}
	}
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Leadership)
connectedPage = withRouter(connectedPage)

export default connectedPage
