import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetchDetails } from "../../actions/request"
import { Helmet } from "react-helmet"

import ImageHero from "../layout/imageHero"
import BioCard from "../layout/bioCard"
import MediaItem from "../layout/mediaItem"
import SitePagination from "../layout/sitePagination"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import { widont } from "../../utils/utils"

class LeadershipDetail extends Component {
	constructor(props) {
		super(props)

		this.state = {
			url: props.match.params.url,
			requestData: null
		}
	}


	_widont() {
		widont('.mb-items-md > p')
	}

	componentWillMount() {
		const { FetchDetails } = this.props

		FetchDetails("leadership", this.state.url)
		window.addEventListener('resize', this._widont)
		window.setTimeout(this._widont, 1000)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {
			const { FetchDetails } = this.props

			FetchDetails("leadership", this.props.match.params.url)
		}
		window.setTimeout(this._widont, 1000)
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data[0]
		})
		window.setTimeout(this._widont, 1000)
	}


	componentWillUnmount() {
		window.removeEventListener('resize', this._widont)
	}

	render() {
		const { requestData } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const ctaImage = requestData.ctaImage,
				ctaImageAltText = requestData.ctaImageAltText,
				heroImage = requestData.heroImage,
				nextUrl = requestData.nextUrl,
				prevUrl = requestData.prevUrl,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href

			return (
				<>
					<Helmet>
						<title>{metaTitle}</title>
						<link rel="canonical" href={metaUrl} />
						<meta name="description" content={metaDesc} />
						<meta property="og:url" content={metaUrl} />
						<meta property="og:title" content={metaTitle} />
						<meta property="og:description" content={metaDesc} />
						<meta property="og:image" content={metaImage} />
						<meta name="twitter:title" content={metaTitle} />
						<meta name="twitter:description" content={metaDesc} />
						<meta name="twitter:image" content={metaImage} />
						<meta name="twitter:image:alt" content={metaTitle} />
					</Helmet>
					<ImageHero marginDisabled images={heroImage} />
					<section className="site-content">
						<BioCard gridSize="med" verticalAlign="top" linkText="Download Headshot &amp; Bio" data={requestData}>
							<img src={ctaImage} alt={ctaImageAltText} style={{
								verticalAlign: 'top',
								paddingRight: '20px',
								width: '50%',
								float: 'left'
							}} />
						</BioCard>
					</section>
					<SitePagination
						gridSize="lrg"
						title="Leadership"
						permalink="/who-we-are/leadership"
						nextUrl={nextUrl}
						prevUrl={prevUrl}
					/>
				</>
			)
		}
	}
}

LeadershipDetail.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	FetchDetails: (category, url) => dispatch(fetchDetails(category, url))
})

let connectedPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(LeadershipDetail)
connectedPage = withRouter(connectedPage)

export default connectedPage
