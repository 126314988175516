import { combineReducers } from 'redux';

import {
  HANDLE_SUBMIT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILURE,
  HANDLE_RESET
} from '../actions/submission'

const INITITAL_STATE = {
  busy: false,
  status: null
}

function submission(state = INITITAL_STATE, action) {
  switch (action.type) {
    case HANDLE_SUBMIT:
      return {
        ...state,
        busy: true,
        status: null
      }

    case SUBMIT_SUCCESS:
      return {
        ...state,
        busy: false,
        status: true
      }

    case SUBMIT_FAILURE:
      return {
        ...state,
        busy: false,
        status: false
      }

    case HANDLE_RESET:
      return {
        ...state,
        busy: false,
        status: null
      }

    default:
      return state
  }
}

export default submission
