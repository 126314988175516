import React from "react"

import Link from "../layout/link"
import MediaItem from "../layout/mediaItem"

const LeadershipCard = ({ marginDisabled, data: { permaLink, firstName, lastName, jobTitle, ctaImage, ctaImageAltText } }) => (
	<div className={"leadership-card module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
		{/* Card Link */}
		{permaLink && (
			<div className="link">
				<Link url={"/who-we-are/leadership/" + permaLink} />
			</div>
		)}

		{/* Card Image */}
		{ctaImage && (
			<div className="image">
				<MediaItem image={ctaImage} alt={ctaImageAltText} ratio="3-4" />
			</div>
		)}

		{/* Card Text  */}
		<div className="content">
			{(firstName || lastName) && (
				<div className="title">
					<div className="t-h2" style={{lineHeight: 1}}>
						{firstName}
						<br />
						{lastName}
					</div>
				</div>
			)}
			{jobTitle && (
				<div className="subtitle">
					<div className="t-link">{jobTitle}</div>
				</div>
			)}
			{permaLink && (
				<div className="button">
					<Link url={"/who-we-are/leadership/" + permaLink}>
						<button className="btn btn-link">View Bio</button>
					</Link>
				</div>
			)}
		</div>
	</div>
)

export default LeadershipCard
