import React, { Component } from "react"
import ReactPlayer from "react-player"
import { TweenMax, ScrollToPlugin } from "gsap/all"

class VideoOverlay extends Component {
	constructor(props) {
		super(props)

		this.animating = this.animating.bind(this)
		this.animateIn = this.animateIn.bind(this)
		this.animateOut = this.animateOut.bind(this)
	}

	animating() {
		if (this.props.videoOverlayVisible) {
			this.animateIn()
		} else {
			this.animateOut()
		}
	}

	animateIn() {
		let videoOverlay = document.querySelector(".video-overlay")

		TweenMax.fromTo(videoOverlay, 0.8, { transform: "translateY(101vh)" }, { display: "block", transform: "translateY(0)" })
	}

	animateOut() {
		let videoOverlay = document.querySelector(".video-overlay")

		TweenMax.to(videoOverlay, 0.8, { transform: "translateY(101vh)" })

		TweenMax.to(videoOverlay, 0.1, { display: "none" }).delay(0.8)
	}

	componentDidUpdate() {
		this.animating()
	}

	render() {
		const { videoOverlayVisible, videoOverlayUrl, closeVideoOverlay } = this.props

		return (
			<section className="video-overlay">
				<ReactPlayer
					className="video-container"
					url={videoOverlayVisible ? videoOverlayUrl : null}
					width="100%"
					height="100%"
					playing={videoOverlayVisible}
					loop={false}
					playsInline={false}
					volume={0.3}
					controls={false}
				/>
				<button className="btn btn-video-close" onClick={closeVideoOverlay}>
					<span className="ico ico-font ico-close" />
				</button>
			</section>
		)
	}
}

export default VideoOverlay
