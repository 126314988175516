import React, { useEffect } from "react"
import Fade from "react-reveal/Fade"
import { widont } from "../../utils/utils"
const CareersCard = ({ delay, fraction, marginDisabled, args: { hasVideo, onClick, image, imageAltText, title, desc } }) => {
	const animationDuration = 300
	useEffect(_ => {
		const _widont = _ => widont('.t-bc, .richtext h2, .richtext p')
		window.addEventListener('resize', _widont)
		window.setTimeout(_widont(), 500)
		return (_ => { window.removeEventListener('resize', _widont) })
	}, [])
	return (
		<div className={"careers-card " + (marginDisabled ? "module-margin-disabled " : "") + (hasVideo ? "video " : "")}>
			{/* Card image */}
			{hasVideo ? (
				<div className="over-hidden">
					<Fade bottom delay={delay} duration={animationDuration} fraction={fraction}>
						<div className="image" onClick={onClick}>
							<Fade delay={delay + animationDuration} duration={animationDuration} fraction={fraction}>
								<div className="image-container">
									<img src={image} alt={imageAltText} className="img-responsive-max" />
								</div>
							</Fade>
						</div>
					</Fade>
				</div>
			) : (
				<div className="over-hidden">
					<Fade bottom delay={delay} duration={animationDuration} fraction={fraction}>
						<div className="image">
							<Fade delay={delay + animationDuration} duration={animationDuration} fraction={fraction}>
								<div className="image-container">
									<img src={image} alt={imageAltText} className="img-responsive-max" />
								</div>
							</Fade>
						</div>
					</Fade>
				</div>
			)}

			{/* Card Description */}
			<Fade delay={delay} duration={animationDuration}>
				<div>
					{title && (
						<div className="title">
							<div className="t-h3">{title}</div>
						</div>
					)}
					{desc && (
						<div className="desc">
							<div className="t-bc" dangerouslySetInnerHTML={{ __html: desc }} />
						</div>
					)}
				</div>
			</Fade>
		</div>
	)
}

export default CareersCard
