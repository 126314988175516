import React from "react"
import ReactPlayer from "react-player"
import RichText from "../layout/richText"
import Fade from "react-reveal/Fade"

const FeedCard = ({ marginDisabled, data: { feedType, title, content, thumbnail, videoUrl, url } }) => (
	<Fade duration={600}>
		<div
			className={
				"feed-card module-margin " + (marginDisabled ? "module-margin-disabled " : "") + (feedType ? feedType.toLowerCase() : "")
			}
		>
			{title && (
				<div className="title">
					<div className="t-link">{title}</div>
					<div className="line" />
				</div>
			)}
			{videoUrl && (
				<div className="iframe">
					<ReactPlayer
						className="video"
						url={videoUrl}
						width="100%"
						height="100%"
						playing={false}
						loop={true}
						playsInline={true}
						volume={0.3}
						controls={true}
					/>
				</div>
			)}
			{!videoUrl && thumbnail && (feedType && feedType.toLowerCase() != 'instagram') && (
				<div className="image">
					<img src={thumbnail} alt={title} className="img-responsive-max" />
				</div>
			)}
			{content && (
				<div className={`content ${feedType ? feedType.toLowerCase() : ''}`}>
					<div className="t-bc fz16 lh1.80">{content}</div>
				</div>
			)}
			{url && <a href={url} className="t-link" target="_blank" title="View More" style={{
				    display: "block",
					minHeight: "50px",
					position: "absolute",
					bottom: "0",
					minWidth: "100%",
					zIndex: "10"
			}} />}
		</div>
	</Fade>
)

export default FeedCard
