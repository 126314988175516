import React, { Component } from "react"
import Link from "./link"
import Fade from "react-reveal/Fade"

class WorkCard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cardImg: this.props.data.card
		}
	}

	render() {
		const {
			delay,
			marginDisabled,
			relatedWork,
			lessDetail,
			workBy,
			data: { permaLink, card, cardAltTagData, office, additionalOffices, title, alt, client, tags, projectCategories },
		} = this.props
		const { cardImg } = this.state

//console.log(this.props);

		const animationDuration = 300
		const animationFraction = 0.2

		return (
			<Link
				classProp="work-card"
				url={"/work/" + permaLink}
			>
				<div className={"card module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
					<div className="over-hidden">
						<Fade bottom delay={delay} duration={animationDuration} fraction={animationFraction}>
							<div className="image">
								<Fade delay={delay + animationDuration} duration={animationDuration} fraction={animationFraction}>
									<div className="image-container">
										{cardImg ? (
											<img
												src={card}
												onError={() => {
													this.setState({ cardImg: null })
												}}
												alt={cardAltTagData}
												className="img-responsive-max"
											/>
										) : null}
									</div>
								</Fade>
							</div>
						</Fade>
					</div>
					<div className="content">
						{/* {(relatedWork || client) && (
							<div className="subtitle pb8 ff-cp fw700">
								<span className="line" />
								{relatedWork ? <div>Related work</div> : <div>{client.title}</div>}
							</div>
						)}
						{title && (
							<div className="title">
								<h3>{title}</h3>
							</div>
						)}
						{!lessDetail &&
							((office || tags) && (
								<div className="pt8 pb8 pb42@md">
									<div className="ff-nfd fz16 lh1.66">{office && office.city}{projectCategories && <span>&nbsp;|&nbsp;</span>}{projectCategories && projectCategories.map(x => x.title).join(', ')}
									</div>
								</div>
							))} */}
						<div className="pb42@md-mx">
							<div className="ff-cp fz20 lh1.66 fw700 pb8"
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'start'
								}}
							>
								<div className="subtitle"><span className="line" /></div>
								{relatedWork &&
									<div className="ff-cp fz18 lh1.66 fw700 ttu">MORE WORK FROM { office.displayName.toUpperCase() }</div>}
							</div>
							<div className="ff-nfd fz44 lh1.1 pb8">{title}</div>
							<div className="pb8">
								<div className="ff-nfd fz16 lh1.66">{office && office.city}{additionalOffices && `, ${additionalOffices.map(x => x.officeName).join(', ')}`}{projectCategories && <br/>}{projectCategories && <span className="ff-cp fz12" style={{color: '#727272'}}>{projectCategories.map(x => x.title).join(', ')}</span>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Link>
		)
	}
}
export default WorkCard
