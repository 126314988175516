import React, { Component } from "react"
import { helloSlider } from "../../lib/helloslider"

class HcCarousel extends Component {
	constructor() {
		super()

		this.state = {
			hasFired: false
		}

		this.initCarousel = this.initCarousel.bind(this)
	}

	initCarousel() {
		const { options } = this.props

		if (this.props.children !== undefined && !this.state.hasFired) {
			this.setState({
				hasFired: true
			})
			helloSlider(this.carousel, options)
		}
	}

	componentDidMount() {
		const { options, delay } = this.props

		if (options.fullscreen) {
			let html = document.querySelector("html"),
				body = document.querySelector("body")
			html.classList.add("home-fixed")
			body.classList.add("home-fixed")
		}

		if (delay) {
			this.timeoutID = setTimeout(this.initCarousel, delay)
		} else {
			this.initCarousel()
		}
	}

	componentDidUpdate() {
		this.initCarousel()
	}

	componentWillUnmount() {
		let html = document.querySelector("html"),
			body = document.querySelector("body");

		if (html.classList.contains("home-fixed"))
			html.classList.remove("home-fixed")

		if (body.classList.contains("home-fixed"))
			body.classList.remove("home-fixed")
	}

	render() {
		const {
			children,
			options: { name }
		} = this.props

		return (
			<section
				className={name}
				ref={event => {
					this.carousel = event
				}}
			>
				<div className="slides-container">
				{ children }
				</div>
			</section>
		)
	}
}

export default HcCarousel
