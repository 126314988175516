import React from 'react';

const RainbowText = ({ className, children }) => (
<span className={`rainbow-text dib ${ className || '' }`}>
  <span className="rainbow-background dib bgcp-t c-transparent">
    { children }
  </span>
</span>
)

export default RainbowText;
