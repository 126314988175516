import React, { Component } from "react"
import _ from "lodash"
import Fade from "react-reveal/Fade"

class NeverList extends Component {
	constructor(props) {
		super(props)

		this.state = {
			leftCol: 0,
			rightCol: 0
		}

		this.setColumnClass = this.setColumnClass.bind(this)
		this.handleButtonOnScroll = this.handleButtonOnScroll.bind(this)
		this.resetColumnClass = this.resetColumnClass.bind(this)
		this.refCallback = this.refCallback.bind(this)
	}

	resetColumnClass() {
		var cols = document.querySelectorAll(".never-list .col")

		this.setState({
			leftCol: 0,
			rightCol: 0
		})

		for (var i = 0; i < cols.length; i++) {
			//remove previous classes
			if (cols[i].classList.contains("left")) {
				cols[i].classList.remove("left")
			} else if (cols[i].classList.contains("right")) {
				cols[i].classList.remove("right")
			}

			//set new classes and state
			if (this.state.leftCol <= this.state.rightCol) {
				cols[i].classList.add("left")
				this.setState({
					leftCol: this.state.leftCol + cols[i].offsetHeight
				})
			} else {
				cols[i].classList.add("right")
				this.setState({
					rightCol: this.state.rightCol + cols[i].offsetHeight
				})
			}
		}
	}

	handleButtonOnScroll() {
		let docHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight) - document.getElementById("footer").offsetHeight,
			winHeight = window.innerHeight,
			maxScrollHeight = docHeight - winHeight,
			currentScrollHeight = window.scrollY || window.pageYOffset,
			button = document.getElementById("navYear")

		if (currentScrollHeight >= maxScrollHeight) {
			button.classList.add("set")
		} else if (button.classList.contains("set")) {
			button.classList.remove("set")
		}
	}

	componentDidMount() {
		window.addEventListener("resize", this.resetColumnClass)
		window.addEventListener("scroll", this.handleButtonOnScroll)
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resetColumnClass)
		window.removeEventListener("scroll", this.handleButtonOnScroll)
	}

	setColumnClass(element, height) {
		let cols = document.getElementById("cols"),
			leftCol = parseInt(cols.getAttribute("data-left")),
			rightCol = parseInt(cols.getAttribute("data-right")),
			heightInt = parseInt(height)

		//set new classes and state
		if (leftCol <= rightCol) {
			cols.setAttribute("data-left", leftCol + heightInt)
			element.classList.add("left")
		} else {
			cols.setAttribute("data-right", rightCol + heightInt)
			element.classList.add("right")
		}
	}

	refCallback(element) {
		if (element) {
			var height = element.offsetHeight
			this.setColumnClass(element, height)
		}
	}

	render() {
		const { marginDisabled, children, openFilter } = this.props

		return (
		<>
			<section className={"never-list module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
				<section className="content">
					<div className="grid lrg" data-left={0} data-right={0} id="cols">
						<div className="row">
							{children.map((item, index) => (
								<div className="col col-6" key={"col-" + index} ref={this.refCallback}>
									<Fade fraction={0.2} delay={index % 2 ? 200 : 0}>
										{item}
									</Fade>
								</div>
							))}
						</div>
					</div>
				</section>
			</section>
			<button type="button" className="btn btn-never-nav-toggle" id="navYear" onClick={openFilter}>
				Navigate to year
			</button>
		</>
		)
	}
}

export default NeverList
