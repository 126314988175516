import React, { Component } from 'react';
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Helmet } from 'react-helmet';
import { fetch } from "../../actions/request"
import RainbowText from '../blocks/rainbowText';

let specialtyPractices = [
  ['FCB/SIX','https://fcbsix.com'],
  ['FCB/RED','https://fcbnorthamerica.com/capabilities/fcb-red'],
  ['FCBX','https://fcbnorthamerica.com/capabilities/fcbx'],
  ['456 Studios','https://456studios.net'],
  ['The Design Group','https://fcbnorthamerica.com/capabilities/the-design-group'],
];

let capabilities = [
  'Advertising',
  'Business Consulting',
  'Digital',
  'Direct Marketing and CRM',
  'Data Analytics',
  'Healthcare Marketing',
  'Integrated Production',
  'Mobile',
  'Promotions',
  'Social Media',
  'Retail and Shopper Marketing',
  'Experiential/Sponsorships',
  'Strategic Planning',
];

let capabilityMapper = (name, i, arr) => (
<div
  key={i}
  className={`capabilities-text ff-cp c-white fw700 fz18 mb24`}
>
  { name }
</div>
)

let pillMapperWithLinks = (x,i) => (
  <span
    key={i}
    className="capabilities-pill-hover ff-cp c-white fw700 curp fz18 bd2-s-white bdrs44 p13 pl20 pr20 mr20 mb20"
    onClick={_ => window.open(x[1], "_blank")}
  >
    { x[0] }
  </span>
  )

class Capabilities extends Component {
  constructor() {
    super();
  }

  componentWillMount() {
    const { Fetch } = this.props
    // Fetch("pages/view/capabilities")
  }

  componentDidMount() {
    document.querySelector('.header').classList.add('fixed-override')
  }

  render() {
    return (
    <div className="capabilities-page pt-header">
      <div className="capabilities-page__hero bgc-white pt80 pb80">
        <div className="grid lrg">
          <p className="ff-nfd fz44@md lh1.66@md fz26 lh1.80">
            <span>Every opportunity is unique, and so is the core team we build around it—tapping a deep bench of specialists to deliver the </span>
            <RainbowText className="mr10">
              <span className="fsi">Never Finished</span>
            </RainbowText>
            <span>expertise you need—<br />and nothing you don’t.</span>
          </p>
        </div>
      </div>
      <div className="capabilities-page__capabilities-list bgc-true-black c-white pt110 pb110">
        <div className="grid lrg">
          <RainbowText>
            <h3 className="fsi fz24 fz44@md mb24 mb50@md">
              Specialty Practices
            </h3>
          </RainbowText>
          <div className="df fxww mb60">
            { specialtyPractices.map(pillMapperWithLinks) }
          </div>
          <RainbowText>
            <h3 className="fsi fz24 fz44@md mb24 mb50@md">
              Capabilities
            </h3>
          </RainbowText>
          <div className="colmc1 colmc2@sm colmc3@md mb60">
            { capabilities.map(capabilityMapper) }
          </div>
        </div>
      </div>
    </div>
    )
  }
}

Capabilities.defaultProps = {
  Request: new Object()
}

const mapStateToProps = state => ({
  Request: state.request
})

const mapDispatchToProps = dispatch => ({
  Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(Capabilities)
connectedPage = withRouter(connectedPage)

export default connectedPage;
