import React from 'react'

const LineBreak = ({ marginDisabled, gridSize, animate }) => (
  <section className={"linebreak module-margin " + (marginDisabled ? "module-margin-disabled " : "") + (animate ? "animate " : "")}>
    <div className={"grid " + (gridSize ? gridSize : "")}>
      <div style={{
        height: '1px',
        backgroundImage: "url('https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/RainbowGradientHoriztonal.jpeg')",
        backgroundPosition: "center center",
        backgroundSize: "cover"
      }}>

      </div>
    </div>
  </section>
)

export default LineBreak;
