import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { Helmet } from "react-helmet"

import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import OfficeCard from "../layout/officeCard"
import Grid from "../layout/grid"
import FilterByList from "../layout/filterbyList"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import Fade from "react-reveal/Fade"

class WhereWeAre extends Component {
	constructor(props) {
		super(props)

		this.state = {
			requestData: null,
			selectedRegion: "North America"
		}

		this.setRegion = this.setRegion.bind(this)
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/where-we-are")
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	setRegion(region) {
		this.setState({
			selectedRegion: region
		})
	}

	render() {
		const { requestData, selectedRegion } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const images = requestData.whereWeArePage.heroImage,
				title = requestData.whereWeArePage.title,
				hideTitle = requestData.whereWeArePage.hideTitle,
				content = requestData.whereWeArePage.content,
				offices = requestData.offices.filter(f => !f.isNullOffice || f.isNullOffice == false ),
				regions = requestData.regions,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href,
				filteredOffices = offices ? offices.filter(item => item.region == selectedRegion) : []
			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<ImageHero marginDisabled images={images} heroTitle={hideTitle ? "" : title} />
				<section className="site-content">
					<Fade>
						<Intro gridSize="sml">
							<div className="t-h4">
								{content.replaceAll(/(<([^>]+)>)/ig, "")}
							</div>
						</Intro>
					</Fade>
					<FilterByList
						gridSize="lrg"
						title="Choose a location"
						filters={regions}
						activeFilter={selectedRegion}
						handleFilter={this.setRegion}
						noItems={filteredOffices.length < 1}
						noItemsMsg="We do not currently have any offices in this region"
					>
						<Grid
							gridItems={[...filteredOffices]}
							rowClass={"flush start top"}
							colnum={2}
							size={"lrg"}
							Child={OfficeCard}
							uniqueKey={"displayName"}
						/>
					</FilterByList>
				</section>
			</>
			)
		}
	}
}

WhereWeAre.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(WhereWeAre)
connectedPage = withRouter(connectedPage)

export default connectedPage
