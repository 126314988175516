import { put, takeLatest, call } from 'redux-saga/effects';

import {
  HANDLE_SUBMIT
} from '../actions/submission';

import {
  post
} from '../utils/utils';

import {
  submitSuccess,
  submitFailure
} from '../actions/submission';

function* handleSubmit(request) {
  try {
    const res = yield call(post, `${request.url}`, request.payload, request.external);
    yield put(submitSuccess(res));
  } catch (err) {
    yield put(submitFailure(err));
  }
}

export const submissionSaga = [
  takeLatest(HANDLE_SUBMIT, handleSubmit)
];
