import React, { Component } from "react"
import { TweenMax, EasePack } from "gsap/all"
import { connect } from "react-redux"

import Menu from "./menu"
import SearchBar from "./searchBar"
import Filter from "./filter"
import Link from "./link"
import CustomScroll from "react-custom-scroll"

import { Navigation } from "../../constants/navigation"
const { detect } = require("detect-browser")
const browser = detect()

class Nav extends Component {
	constructor(props) {
		super(props)

		this.state = {
			menu: Navigation,
			navIsOpen: false,
			filterStorage: null //This is only used to store a snapshot of the active filters the user selected on close, so the filter can toggle between a close and return button
		}

		this.back = this.back.bind(this)
		this.forward = this.forward.bind(this)
		this.animateIn = this.animateIn.bind(this)
		this.animateOut = this.animateOut.bind(this)
		this.handleAnimation = this.handleAnimation.bind(this)
		this.handleOnClose = this.handleOnClose.bind(this)
		this.handleCallback = this.handleCallback.bind(this)
		this.stopScrollPropagation = this.stopScrollPropagation.bind(this)
	}

	handleAnimation() {
		if (this.props.active && !this.state.navIsOpen) {
			this.animateIn()
			this.setState({
				navIsOpen: true
			})
		}

		if (this.props.active && this.props.busy) {
			this.handleOnClose(this.props.closeNav)
		}

		if (!this.props.active) {
			this.handleCallback()
		}
	}

	handleOnClose(cb) {
		const { filters } = this.props

		this.animateOut(() => {
			cb()
			this.setState({
				filterStorage: JSON.stringify(filters.active)
			})
		})
	}

	animateIn() {
		let body = document.querySelector("body"),
			html = document.querySelector("html"),
			navElm = document.querySelector(".navigation"),
			navContent = document.querySelector(".navigation .navigation-container"),
			navElmHeaderChild1 = document.querySelector(".navigation header .left"),
			navElmHeaderChild2 = document.querySelector(".navigation header .right"),
			navElmFooter = document.querySelector(".navigation footer"),
			navElmOverlay = document.querySelector(".navigation .overlay")

		body.classList.add("site-fixed")
		html.classList.add("site-fixed")

		TweenMax.fromTo(navElm, 0.1, { display: "none" }, { display: "block" })

		TweenMax.fromTo(navContent, 0.5, { transform: "translateX(100%)" }, { transform: "translateX(0)" }).delay(0.1)

		TweenMax.fromTo(navElmOverlay, 0.4, { autoAlpha: 0 }, { autoAlpha: 0.6 }).delay(0.6)

		TweenMax.fromTo(
			[navElmHeaderChild1, navElmHeaderChild2],
			0.4,
			{ transform: "translateY(-125px)" },
			{ transform: "translateY(0)" }
		).delay(1)

		TweenMax.fromTo(navElmFooter, 0.4, { transform: "translateY(220px)" }, { transform: "translateY(0)" }).delay(1.2)
	}

	animateOut(cb) {
		let body = document.querySelector("body"),
			html = document.querySelector("html"),
			navElm = document.querySelector(".navigation"),
			navContent = document.querySelector(".navigation .navigation-container"),
			navElmOverlay = document.querySelector(".navigation .overlay")

		TweenMax.to(navElmOverlay, 0.4, { autoAlpha: 0, onComplete: cb })

		TweenMax.to(navContent, 0.5, { transform: "translateX(100%)" }).delay(0.5)

		TweenMax.to(navElm, 0.1, { display: "none" }).delay(1.1)

		body.classList.remove("site-fixed")
		html.classList.remove("site-fixed")
	}

	handleCallback() {
		if (this.state.navIsOpen) {
			this.setState({
				navIsOpen: false
			})
		}
	}

	stopScrollPropagation() {
		document.querySelector(".navigation .main").addEventListener("touchmove", e => e.stopPropagation())
	}

	back() {
		this.setState({
			menu : Navigation
		})
	}

	forward(data) {
		const { navigate } = this.props
		const loc = location.pathname

		if (data.path !== loc) {
			return navigate(data.path)
		}
	}

	componentDidMount() {
		const { history, navigate, filters } = this.props

		history.block((location, action) => {
			if (action === "POP") {
				navigate(`${location.pathname}${location.search}`, true)
			}
		})

		this.stopScrollPropagation()
	}

	componentDidUpdate() {
		this.handleAnimation()

		if (this.state.filterStorage === null) {
			this.setState({
				filterStorage: JSON.stringify(this.props.filters.active)
			})
		}
	}

	isEmpty(obj) {
		return Object.values(obj).every(x => x.length < 1 || x === null || x === "")
	}

	render() {
		const {
			active,
			closeNav,
			menuActive,
			searchActive,
			filterActive,
			filterPage,
			filterOptions,
			filterReset,
			toggleMenu,
			toggleSearch,
			history,
			filters
		} = this.props
		const { menu, filterStorage } = this.state
		const noFilters = this.isEmpty(filters.active)

		return (
			<section
				className={
					"navigation" +
					(active ? " active" : "") +
					(menuActive ? " nav-active" : "") +
					(searchActive ? " search-active" : "") +
					(filterActive ? " filter-active" : "")
				}
			>
				<div className="overlay" onClick={() => this.handleOnClose(closeNav)} />
				<div className={"navigation-container" + (browser.name === "safari" || browser.name === "ios" ? " browser-safari " : " ")}>
					<div className=" db oys oxh h100vh">
						<div className="posr df fxdrc jcsb mih100%">
							<header>
								<div className="left">
									{(menuActive || searchActive) && (
										<a href="/" title="FCB Global" className="logo">
											{/* <span className="ico ico-font" /> */}
											<img src="https://d1f5kcwhveewqf.cloudfront.net/static/public/img/img/fcb1150logo.png" alt="fcb logo 150 years" width={130} height={45} />
										</a>
									)}
									{filterActive && <div className="t-h3">Filter by</div>}
								</div>
								<div className="right">
									{(menuActive || searchActive) && (
										<>
											<button className="btn btn-search" onClick={toggleSearch}>
												<span className="ico ico-font ico-search" />
											</button>
											<button className="btn btn-menu" onClick={toggleMenu}>
												<span className="icon" />
											</button>
										</>
									)}
									{filterActive && (
										<button
											className={`btn btn-reset-filters ${noFilters ? "disabled" : ""}`}
											onClick={() => (!noFilters ? [filterReset(), this.handleOnClose(closeNav)] : [])}
										>
											<span className="ico ico-font ico-reset" />
										</button>
									)}

									{filterActive && filterStorage !== null && filterStorage !== JSON.stringify(filters.active) ? (
										<button className="btn btn-menu-return" onClick={() => this.handleOnClose(closeNav)}>
											<span className="ico ico-font ico-return" />
										</button>
									) : (
										<button className="btn btn-menu-close" onClick={() => this.handleOnClose(closeNav)}>
											<span className="ico ico-font ico-close" />
										</button>
									)}
								</div>
							</header>
							<section className="main">
								<CustomScroll heightRelativeToParent="100%">
									{menuActive &&
										<Menu
											data={menu}
											forward={this.forward}
											back={this.back}
										/>
									}
									{searchActive && <SearchBar searchActive={searchActive} />}
									{filterActive && (
										<Filter
											history={history}
											filterActive={filterActive}
											filterPage={filterPage}
											filterOptions={filterOptions}
										/>
									)}
								</CustomScroll>
							</section>
							<footer className="mt-auto">
								{(menuActive || searchActive) && (
									<>
										<section className="cta" >
											<Link url="/contact-us" classProp="btn btn-link flni" >
												Become A Client
											</Link>
											<Link url="/careers/join-our-team" classProp="btn btn-link">
												Join Our Team
											</Link>
										</section>
										<section className="social">
											<a
												href="https://www.facebook.com/FCB.Global"
												title="Facebook"
												className="btn btn-social"
												target="_blank"
											>
												<span className="ico ico-font ico-facebook" />
											</a>
											<a
												href="https://www.instagram.com/fcbglobal"
												title="Instagram"
												className="btn btn-social"
												target="_blank"
											>
												<span className="ico ico-font ico-instagram" />
											</a>
											<a href="https://twitter.com/fcbglobal" title="Twitter" className="btn btn-social" target="_blank">
												<span className="ico ico-font ico-twitter" />
											</a>
											<a
												href="https://www.linkedin.com/company/fcbglobal/"
												title="Linkedin"
												className="btn btn-social"
												target="_blank"
											>
												<span className="ico ico-font ico-linkedin" />
											</a>
											<a
												href="https://www.youtube.com/c/FCBglobal"
												title="youtube"
												className="btn btn-social"
												target="_blank"
											>
												<span className="ico ico-font ico-youtube" />
											</a>
										</section>
									</>
								)}
							</footer>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

const mapStateToProps = state => ({
	filters: state.filters
})

let connectedComponent = connect(mapStateToProps)(Nav)

export default connectedComponent
