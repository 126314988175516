export const HANDLE_SUBMIT = 'HANDLE_SUBMIT'
export function handleSubmit(url, payload, external) {
  return {
    type: HANDLE_SUBMIT,
    url, payload, external
  }
}

export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS'
export function submitSuccess(res) {
  return {
    type: SUBMIT_SUCCESS,
    payload: res
  }
}

export const SUBMIT_FAILURE = 'SUBMIT_FAILURE'
export function submitFailure(err) {
  return {
    type: SUBMIT_FAILURE,
    payload: err
  }
}

export const HANDLE_RESET = 'HANDLE_RESET'
export function handleReset() {
  return {
    type: HANDLE_RESET
  }
}
