import React from "react"
import { withRouter } from "react-router-dom"
import Link from "../layout/link"

const NotFound = () => (
	<section className="notfound">
		<div className="notfound-content">
			<h1 className="h9">
				Oh no! Something went <span className="ico" data-text="wrong" />
			</h1>
			<Link url="/" classProp="btn btn-link black">
				Go home
			</Link>
		</div>
	</section>
)

export default withRouter(NotFound)
