import React from "react"
import ReactPlayer from "react-player"

import RichText from "../layout/richText"

const NeverCard = ({ marginDisabled, data: { year, description, image, imageAltText, video } }) => (
	<div id={year} className={"never-card module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
		{year && (
			<div className="year">
				<div className="t-h2">{year}</div>
				<div className="line" />
			</div>
		)}
		{description && (
			<div className="desc">
				<RichText marginDisabled dropLastPadding textAlign="left" dangerouslySet>
					{description}
				</RichText>
			</div>
		)}
		{image && (
			<div className="image">
				<img src={image} alt={imageAltText} className="img-responsive-max" />
			</div>
		)}
		{video && (
			<div className="iframe">
				<ReactPlayer
					className="video"
					url={video}
					width="100%"
					height="100%"
					playing={false}
					loop={true}
					playsInline={true}
					volume={0.3}
					controls={true}
				/>
			</div>
		)}
	</div>
)

export default NeverCard
