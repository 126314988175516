import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { Helmet } from "react-helmet"

import ImageHero from "../layout/imageHero"
import Intro from "../layout/intro"
import RichText from "../layout/richText"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"

class PrivacyPolicy extends Component {
	constructor(props) {
		super(props)

		this.state = {
			requestData: null
		}
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/privacy")
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
		setTimeout(_ => {
			if(window.location.hash) {
				const ele = document.querySelector(window.location.hash)
				const yOffset = -100
				const y = ele.getBoundingClientRect().top + window.pageYOffset + yOffset;
	
				window.scrollTo({top: y, behavior: 'smooth'});
			}
		}, 500)
	}

	componentDidUpdate() {
		document.querySelectorAll("a[href^='#']").forEach(node => {
			node.addEventListener('click', e => {
				e.preventDefault();
				const ele = document.querySelector(`#${e.target.href.split('#')[1]}`)
				// console.log(ele);
				// ele.scrollIntoView()
				const yOffset = -100
				const y = ele.getBoundingClientRect().top + window.pageYOffset + yOffset;

				window.scrollTo({top: y, behavior: 'smooth'});
			});
		})
	}

	render() {
		const { requestData } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const title = requestData.privacyPage.title,
				hideTitle = requestData.privacyPage.hideTitle,
				content = requestData.privacyPage.content,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href

			return (
				<>
					<Helmet>
						<title>{metaTitle}</title>
						<link rel="canonical" href={metaUrl} />
						<meta name="description" content={metaDesc} />
						<meta property="og:url" content={metaUrl} />
						<meta property="og:title" content={metaTitle} />
						<meta property="og:description" content={metaDesc} />
						<meta property="og:image" content={metaImage} />
						<meta name="twitter:title" content={metaTitle} />
						<meta name="twitter:description" content={metaDesc} />
						<meta name="twitter:image" content={metaImage} />
						<meta name="twitter:image:alt" content={metaTitle} />
					</Helmet>
					<ImageHero marginDisabled gradientBg heroTitle={hideTitle ? "" : title} />
					<section className="site-content">
						<Intro gridSize="med">
							<RichText marginDisabled dropLastPadding textAlign="left">
								<div className="">
									<p><strong>Effective Date: October 6, 2021</strong></p>
									<p>FCB Worldwide Inc (&ldquo;FCB&rdquo;) respects your concerns about privacy. This Website Privacy Notice describes
										the types of information we collect about individuals on <a href="https://www.fcb.com">https://www.fcb.com</a>
										&nbsp;(the &ldquo;Site&rdquo;), how we use the information, with whom we share it, and the choices available regarding
										our use of the information. The Website Privacy Notice also describes the measures we take to safeguard personal
										information, how long we retain it, and how individuals can contact us about our privacy practices and to
										exercise their rights.&nbsp;</p>
									<p>Click on one of the links below to jump to the listed section:</p>
									<ul>
										<li><a href="#InformationWeObtain">Information We Obtain</a></li>
										<ul>
											<li className="ml2rem"><a href="#InformationYouProvide">Information You Provide</a></li>
											<li className="ml2rem"><a href="#InformationRecieveOtherSources">Information We Receive About You from Other Sources</a></li>
											<li className="ml2rem"><a href="#AutomatedCollectionInformation">Automated Collection of Information</a></li>
										</ul>
										<li><a href="#ThirdPartyWebAnalyticsServices">Third-Party Web Analytics Services</a></li>
										<li><a href="#HowWeUsePersonalInformation">How We Use Personal Information</a></li>
										<li><a href="#PersonalInformationSharing">Personal Information Sharing</a></li>
										<li><a href="#CrossBorderTransfer">Cross-Border Transfer</a></li>
										<li><a href="#YourRightsandChoices">Your Rights and Choices</a></li>
										<li><a href="#OtherOnlineServices">Other Online Services and Third-Party Features</a></li>
										<li><a href="#RetentionofPersonalInformation">Retention of Personal Information</a></li>
										<li><a href="#HowWeProtectPersonalInformation">How We Protect Personal Information</a></li>
										<li><a href="#ChildrensPersonalInformation">Children&rsquo;s Personal Information</a></li>
										<li><a href="#UpdatestoOurWebsitePrivacyNotice">Updates to Our Website Privacy Notice</a></li>
										<li><a href="#HowToContactUs">How to Contact Us</a></li>
									</ul>
									<p id="InformationWeObtain"><strong>Information We Obtain</strong></p>
									<p id="InformationYouProvide"><strong><em>Information You Provide</em></strong></p>
									<p>We collect and store personal information (such as name and other contact details) that you choose to provide to
										us through the Site or when you contact us. &ldquo;Personal Information&rdquo; is information that identifies
										you as an individual or relates to an identifiable individual. The types of personal information we collect
										include:</p>
									<ul>
										<li>Contact information, such as your name, e-mail address, location, and telephone number; and</li>
										<li>Other personal information you may submit to us, such as when you contact us.</li>
									</ul>
									<p id="InformationRecieveOtherSources"><strong><em>Information We Receive About You from Other Sources</em></strong></p>
									<p>We receive your Personal Information from other sources, such as publicly available databases and providers of
										demographic information.</p>
									<p id="AutomatedCollectionInformation"><strong><em>Automated Collection of Information </em></strong></p>
									<p>When you use the Site, we obtain certain information by automated means, such as browser cookies, beacons, device
										identifiers, server logs, and other technologies.</p>
									<p>The information we obtain in this manner may include your:</p>
									<ul>
										<li>Device IP address,</li>
										<li>Domain name,</li>
										<li>Identifiers associated with your devices,</li>
										<li>Device and operating system type and characteristics,</li>
										<li>Web browser characteristics,</li>
										<li>Language preferences,</li>
										<li>Clickstream data,</li>
										<li>Your interactions with the Site (such as the web pages you visit, links you click, and features you use),
										</li>
										<li>The pages that led or referred you to the Site,</li>
										<li>Dates and times of access to the Site, and</li>
										<li>Other information about your use of the Site.</li>
									</ul>
									<p>You can view more information on how we obtain certain information by automated means in our <a href="#CookieNotice">Cookie Notice</a>.</p>
									<p id="ThirdPartyWebAnalyticsServices"><strong><em>Third-Party Web Analytics Services</em></strong></p>
									<p>We use third-party online analytics services, such as Google Analytics. The service providers that administer
										these analytics services use cookies and similar technologies to collect and analyze information about use of
										the Site and report on activities and trends and diagnose technical issues. To learn more about Google&rsquo;s
										practices, please visit <a
											href="http://www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a>, and
										opt out of them by downloading the Google Analytics opt-out browser add-on, available at <a
											href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.&nbsp;</p>
									<p id="HowWeUsePersonalInformation"><strong>How We Use Personal Information</strong></p>
									<p>We use the Personal Information we obtain on the Site for legitimate business purposes, including to:</p>
									<ul>
										<li>Provide our services to you; process, evaluate, and respond to your requests and inquiries; and send
											administrative information to you, such as changes to our terms, conditions, and policies. We will engage in
											these activities to manage our contractual relationship with you and/or to comply with a legal obligation.
										</li>
									</ul>
									<ul>
										<li>Market our services to you. We will engage in this activity with your consent or where we have a legitimate
											interest.</li>
									</ul>
									<ul>
										<li>Operate, evaluate, and improve our business (including developing new services; enhancing and improving our
											services; analyzing our services; managing our communications; performing data analytics and market
											research; and performing accounting, auditing, and other internal functions). We will engage in these
											activities to comply with a legal obligation and/or because we have a legitimate interest.</li>
									</ul>
									<ul>
										<li>Protect against, identify, and prevent fraud, security breaches, and other criminal activity and risks. We
											will engage in these activities to comply with a legal obligation and/or because we have a legitimate
											interest.</li>
									</ul>
									<ul>
										<li>Comply with and enforce applicable legal requirements, relevant industry standards, and our policies,
											including this Website Privacy Notice and/or FCB&rsquo;s Terms and Conditions or Terms of Use. We will
											engage in these activities to comply with a legal obligation and/or because we have a legitimate interest.
										</li>
									</ul>
									<ul>
										<li>Aggregate and/or anonymize Personal Information so that it will no longer be considered personal
											information. We do so to generate other data for our use, which we may use and disclose for any purpose.
										</li>
									</ul>
									<p id="PersonalInformationSharing"><strong>Personal Information Sharing</strong></p>
									<p>We share your Personal Information with:</p>
									<ul>
										<li>The Interpublic Group of Companies, Inc. (&ldquo;IPG&rdquo;) agencies or companies, for the purposes
											described in this Website Privacy Nostice. You can consult the list and location of such entities <a href="https://www.interpublic.com/our-agencies">here</a>.</li>
										<li>Our service providers who perform services on our behalf, such as hosting this Site or data analytics. We do
											not authorize our service providers to use or disclose the information except as necessary to perform
											services on our behalf or comply with legal requirements.</li>
									</ul>
									<p>We also disclose information about you (1) if we are required to do so by law or legal process (such as a court
										order or subpoena), including laws outside your country of residence; (2) in response to requests by, or
										cooperate with, government agencies, such as law enforcement authorities, including outside of your country of
										residence; (3) to establish, exercise, or defend our legal rights; (4) when we believe disclosure is necessary
										or appropriate to protect our rights, privacy, safety, or property and/or that of our affiliates, you, or
										others; (5) in connection with an investigation of suspected or actual illegal activity; (6) in connection with
										the sale or transfer of all or a portion of our business, assets, or stock (including in the event of a
										reorganization, dissolution, or liquidation); or (7) otherwise with your consent.</p>
									<p id="CrossBorderTransfer"><strong>Cross-Border Transfer</strong></p>
									<p>Your personal information may be processed and stored in any country where we have facilities or in which we
										engage service providers, and, by using the Site, you understand your information may be transferred to
										countries outside of your country of residence, including the United States, which may have data protection
										rules that are different from those of your country. In certain circumstances, courts, law enforcement agencies,
										regulatory agencies, or security authorities in those other countries may be entitled to access your personal
										information.</p>
									<p>ADDITIONAL INFORMATION REGARDING THE EUROPEAN ECONOMIC AREA (&ldquo;EEA&rdquo;): Some non-EEA countries are
										recognized by the European Commission as providing an adequate level of data protection according to EEA
										standards (the full list of these countries is available <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en">here</a>. For transfers from the EEA to countries not
										considered adequate by the European Commission, we have put in place adequate measures, such as standard
										contractual clauses adopted by the European Commission to protect your personal information. You may obtain a
										copy of the EU&rsquo;s Standard Contractual Clauses <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">here</a>.</p>
									<p id="YourRightsandChoices"><strong>Your Rights and Choices</strong></p>
									<p>We offer you certain choices in connection with the personal information we obtain about you. If you would like
										to request to review, correct, update, suppress, restrict, or delete personal information, to object to the
										processing of personal information, or to receive an electronic copy of your personal information for purposes
										of transmitting it to another company (to the extent these rights are provided to you by applicable law), please
										contact us as specified in the <a href="#HowToContactUs">How to Contact Us</a> section of this Website Privacy Notice. We will respond
										to your request consistent with applicable law.</p>
									<p>To help protect your privacy and maintain security, we take steps to verify your identity before granting you
										access to your information. To the extent permitted by applicable law, a charge may apply before we provide you
										with a copy of any of your personal information that we maintain.</p>
									<p>You can unsubscribe from our marketing mailing lists by following the &ldquo;Unsubscribe&rdquo; link in our
										marketing emails. If you would like to unsubscribe from any other type of marketing you receive from us, please
										contact us as specified in the <a href="#HowToContactUs">How to Contact Us</a> section of this Website Privacy Notice.</p>
									<p id="OtherOnlineServices"><strong>Other Online Services and Third-Party Features</strong></p>
									<p>The Site may provide links to other online services and websites for your convenience and information, and may
										include third-party features such as apps, tools, widgets, and plug-ins (<u>e.g.</u>, LinkedIn, Instagram,
										Facebook, and Twitter buttons). These services and websites may operate independently from us. The privacy
										practices of these third parties, including details on the information they collect about you, are subject to
										their own privacy statements. To the extent any linked online services or third-party features are not owned or
										controlled by us, FCB is not responsible for these third parties&rsquo; information or other practices.</p>
									<p id="RetentionofPersonalInformation"><strong>Retention of Personal Information</strong></p>
									<p>We retain personal information for as long as needed or permitted in light of the purposes for which we obtained
										it and consistent with applicable law. The criteria used to determine our retention periods include:</p>
									<ul>
										<li>The length of time we have an ongoing relationship with you and provide the Site or our services to you;
										</li>
										<li>Whether there is a legal obligation to which we are subject; and</li>
										<li>Whether retention is advisable in light of our legal position (such as in regard to applicable statutes of
											limitation, litigation, or regulatory investigations).</li>
									</ul>
									<p id="HowWeProtectPersonalInformation"><strong>How We Protect Personal Information</strong></p>
									<p>We seek to use reasonable administrative, technical and physical safeguards designed to protect personal
										information within our organization. We hold our employees accountable for complying with policies, procedures,
										and regulations regarding the protection and confidentiality of personal information. If you have reason to
										believe that your interaction with us is no longer secure, please immediately notify us in accordance with the
										 <a href="#HowToContactUs">How to Contact Us</a> section of this Website Privacy Notice.</p>
									<p id="ChildrensPersonalInformation"><strong>Children&rsquo;s Personal Information</strong></p>
									<p>The Site is designed for a general audience and is not directed to children under the age of 16. FCB does not
										knowingly collect or solicit personal information from children under the age of 16 through the Site. If you
										believe that a child under the age of 16 may have provided us with personal information through the Site, please
										contact us as specified in the <a href="#HowToContactUs">How to Contact Us</a> section of this Website Privacy Notice.</p>
									<p id="UpdatestoOurWebsitePrivacyNotice"><strong>Updates to Our Website Privacy Notice</strong></p>
									<p>This Website Privacy Notice will be updated periodically and without prior notice to you to reflect changes in
										our personal information practices. We will indicate at the top of the notice when it was most recently updated.
										Any changes will become effective when we post the revised Website Privacy Notice on the Site.</p>
									<p id="HowToContactUs"><strong>How to Contact Us</strong></p>
									<p>FCB Worldwide Inc, located at 100 West 33rd Street, New York, N.Y. 10001-2900, is the company responsible for
										collection, use, and disclosure of your personal information under this Website Privacy Notice.</p>
									<p>If you wish to exercise any of your privacy rights, or if you wish to contact us in relation to this Privacy
										Notice or any privacy matter, including our use of your personal information, please use the following contact
										details:</p>
									<p><strong>ATTN: Information Security Compliance Manager</strong></p>
									<p><a href="mailto:mb.datasecurity@fcb.com">mb.datasecurity@fcb.com</a></p>
									<p>Or for any query or issue, our Data Protection Officers for the UK and the EU can be contacted via post or email
										using the following details:</p>
									<p>UK DPO or EU DPO</p>
									<p>Interpublic Group Limited</p>
									<p>For the UK: <a href="mailto:UK.DPO@interpublic.com">UK.DPO@interpublic.com</a></p>
									<p>For the EU: <a href="mailto:GDPR.DPO@interpublic.com">GDPR.DPO@interpublic.com</a></p>
									<p>You have the right to lodge a complaint with a data protection authority for your country or region or where an
										alleged infringement of applicable data protection law occurs. A list of data protection authorities is
										available <a href="https://protect-us.mimecast.com/s/GVKtCmZM8ki2Bq0BH312ig">here</a>.</p>
									<h2 id="CookieNotice"><strong>FCB Cookie Notice</strong></h2>
									<p><strong>Effective Date:  October 6, 2021</strong></p>
									<p>This Cookie Notice describes the different types of cookies and similar technologies used on the FCB website from
										which you are accessing this policy (the &ldquo;Site&rdquo;). This policy provides you with information about
										how we use cookies and how you can control them.</p>
									<p>We can change this policy at any time. The &ldquo;Effective Date&rdquo; legend at the top of this page indicates
										when this policy was last revised. Any changes will become effective when we make the revised policy available
										on the Site.</p>
									<p>
										<strong>1. What are cookies and similar technologies?</strong>
									</p>
									<p>Cookies are a standard feature of websites that allow us to store small amounts of data on your computer about
										your visit to the Site. We use cookies to perform many different functions. For example, cookies:</p>
									<ul>
										<li>Help us learn which areas of the Site are useful and which areas need improvement;</li>
										<li>Allow us to remember you and your preferences when you return to the Site and otherwise improve your
											experience;</li>
										<li>Help us to serve online advertising to you that is relevant to your interests; and</li>
										<li>Measure the effectiveness of our online advertising and marketing communications.</li>
									</ul>
									<p>We also use technologies similar to cookies, such as Flash Local Shared Objects (also known as Flash cookies) and
										pixel tags (also known as web beacons and clear GIFs), for similar purposes.</p>
									<p>
										<strong>2. What types of cookies do we use?</strong>
									</p>
									<p>Below we list the different types of cookies and similar technologies used on the Site.</p>
									<p>&middot; <strong><em>Strictly Necessary Cookies. </em></strong>These cookies are required for the Site to
										function. They are used to ensure the safety, security, and integrity of the Site, as well as to enable users to
										move around the Site and use its features. Without these cookies, the Site will not perform as smoothly for you
										as we would like it to, and we may not be able to provide the Site or certain features of it. These cookies are
										often session-specific, which means they expire after your visit to the Site has ended. These cookies cannot be
										disabled.</p>
									<p>&middot; <strong><em>Functionality Cookies. </em></strong>These cookies allow the Site to recognize you when you
										return, as well as to remember choices you made (such as your name, language, or region), so it can provide more
										personal features. These cookies will typically remain on your device until you choose to clear them. They
										cannot be disabled.</p>
									<p>&middot; <strong><em>Social Media Cookies. </em></strong>These cookies are set by social media services and
										track you across the Site and other websites, in order to build a profile of your interests. This affects the
										content and messages you see on other websites.</p>
									<p>
										<strong>3. How can you manage your cookie preferences?</strong>
									</p>
									<div className="df">
										<p className="pr1rem">You can manage your cookie preferences at any time via our</p><a class="optanon-show-settings">Cookie Preference Center</a>
									</div>
									<p>Additionally, you can stop certain types of cookies from being downloaded on your computer by selecting the
										appropriate settings on your web browser. Most web browsers will tell you how to stop accepting new browser
										cookies, how to be notified when you receive a new browser cookie, and how to disable existing cookies. You can
										find out how to do this for your particular browser by clicking &ldquo;help&rdquo; on your browser&rsquo;s menu
										or by visiting <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>. If you choose to
										delete or disable cookies and similar technologies, your experience on the Site may be diminished, and some
										features may not work as intended.</p>
									<p>We use Flash Local Shared Objects (&ldquo;Flash LSOs&rdquo;) and other technologies to, among other things,
										collect and store information about your use of the Site. If you do not want Flash LSOs stored on your computer,
										you can adjust the settings of your Flash player to block Flash LSO storage using the tools contained in the <a
											href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">Website
											Storage Settings Panel</a>. You can also go to the <a
												href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html">Global
											Storage Settings Panel</a> and follow the instructions. Please note that setting the Flash player to
										restrict or limit acceptance of Flash LSOs may reduce or impede the functionality of some Flash applications.
									</p>
									<p>If you have any questions, please <a href="#HowToContactUs">contact us here.</a></p>
								</div>
							</RichText>
						</Intro>
					</section>
				</>
			)
		}
	}
}

PrivacyPolicy.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(PrivacyPolicy)
connectedPage = withRouter(connectedPage)

export default connectedPage
