import React from "react"
import Link from "../layout/link"
import Fade from "react-reveal/Fade"

const SitePagination = ({ gridSize, title, permalink, nextUrl, prevUrl }) => (
	<section className="site-pagination">
		<div className={"grid " + (gridSize ? gridSize : "")}>
			<div className="row middle">
				<div className="col col-4 text-left">
					<Fade bottom>
						<Link
							url={prevUrl ? `${permalink}/${prevUrl}` : ""}
							title="Previous"
							classProp={`btn btn-pagination left t-link ${!prevUrl ? "disabled" : ""}`}
						>
							<span className="ico ico-font ico-arrow-left" />
							&nbsp;Prev
						</Link>
					</Fade>
				</div>
				<div className="col col-4 text-center">
					<Fade bottom delay={100}>
						<Link url={permalink} title={title} classProp="btn btn-pagination all t-link" title={"All " + title}>
							<span className="ico ico-font ico-all" />
							&nbsp;All<span className="hide-mobile">&nbsp;{title}</span>
						</Link>
					</Fade>
				</div>
				<div className="col col-4 text-right">
					<Fade bottom>
						<Link
							url={nextUrl ? `${permalink}/${nextUrl}` : ""}
							title="Next"
							classProp={`btn btn-pagination right t-link ${!nextUrl ? "disabled" : ""}`}
						>
							Next&nbsp;
							<span className="ico ico-font ico-arrow-right" />
						</Link>
					</Fade>
				</div>
			</div>
		</div>
	</section>
)

export default SitePagination
