import { article } from '../../../lib/categories';
import regions from '../../../lib/regions';
import { searchCategory } from '../lib/filterConversion';

export const pressCenter = [
  {
    title: 'Category',
    key: 'category',
    options: article,
  }
];

export const work = [
  {
    title: 'Region',
    key: 'region',
    options: regions,
  }
];

export const searchResults = [
  {
    title: 'Category',
    key: 'category',
    options: Object.values(searchCategory),
  }
];
