import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { Helmet } from "react-helmet"

import Subnav from "../layout/subnav"
import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import ButtonList from "../layout/buttonList"
import CareersList from "../layout/careersList"
import CareersCard from "../layout/careersCard"
import LineBreak from "../layout/lineBreak"
import GallerySlider from "../layout/gallerySlider"
import Grid from "../layout/grid"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import Fade from "react-reveal/Fade"

class WorkingAtFcb extends Component {
	constructor(props) {
		super(props)

		this.state = {
			gallery: {
				pin: false,
				rtl: true,
				setHeight: "auto",
				buttonControl: true,
				pager: false,
				rotate: false,
				mouseControl: false,
				touchControl: true,
				keyControl: false,
				autoSlideControl: false,
				speed: 0.6,
				name: "gallery-inner"
			},
			requestData: null
		}
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/working-at-fcb")
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	render() {
		const { gallery, requestData } = this.state
		const { openVideoOverlay } = this.props

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const heroImage = requestData.workingAtFcbPage.heroImage,
				title = requestData.workingAtFcbPage.title,
				hideTitle = requestData.workingAtFcbPage.hideTitle,
				content = requestData.workingAtFcbPage.content,
				gallerySlides = requestData.workingAtFcbPage.gallerySlides,
				companyValues = requestData.workingAtFcbPage.companyValues,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<ImageHero marginDisabled images={heroImage} heroTitle={hideTitle ? "" : title} />
				<Subnav marginDisabled />
				<section className="site-content">
					{/* Careers Intro */}
					<Fade fraction={0.2}>
						<Intro gridSize="sml">
							<RichText marginDisabled textAlign="center" dangerouslySet>
								{ content }
							</RichText>
							{/* <Fade bottom>
								<ButtonList textAlign="center">
									<a
										href="https://fcb.referrals.selectminds.com/"
										target="_blank"
										title="Find job opportunities"
										className="btn btn-rainbow-link"
									>
										Find job opportunities
									</a>
								</ButtonList>
							</Fade> */}
						</Intro>
					</Fade>

					{/* Gallery */}
					{gallerySlides && gallerySlides.length > 0 && (
						<>
							<Fade fraction={0.2}>
								<GallerySlider
									options={gallery}
									slides={gallerySlides}
								/>
							</Fade>
							<LineBreak gridSize="sml" />
						</>
					)}

					{/* Company Values */}
					{companyValues && (
						<CareersList marginDisabled gridSize="med" textAlign="left">
							<Grid
								gridItems={companyValues}
								rowClass={"flush start nowrap"}
								colnum={2}
								size={"lrg"}
								args={args => ({
									// hasVideo: args.iframeUrl,
									// onClick: args.iframeUrl ? () => openVideoOverlay(args.iframeUrl) : null,
									image: args.image,
									imageAltText: args.imageAltText,
									title: args.title,
									desc: args.description
								})}
								Child={CareersCard}
							/>
						</CareersList>
					)}
				</section>
			</>
			)
		}
	}
}

WorkingAtFcb.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(WorkingAtFcb)
connectedPage = withRouter(connectedPage)

export default connectedPage
