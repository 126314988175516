import React from "react"
import Grid from "./grid"
import AwardsCard from "./awardsCard"

const AwardsList = ({ marginDisabled, gridSize, title, data }) => (
	<section className={"awards-list module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
		<section className="head">
			<div className={"grid " + (gridSize ? gridSize : "")}>
				<div className="row">
					<div className="col">{title && <h3 className="h2">{title}</h3>}</div>
				</div>
			</div>
		</section>
		<section className="content">
			<Grid gridItems={data} rowClass={"flush start nowrap"} colnum={4} size={gridSize} Child={AwardsCard} />
		</section>
	</section>
)

export default AwardsList
