export const HANDLE_SUBSCRIBE = 'HANDLE_SUBSCRIBE'
export function handleSubscribe(url, payload) {
  return {
    type: HANDLE_SUBSCRIBE,
    url, payload
  }
}

export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS'
export function subscribeSuccess(res) {
  return {
    type: SUBSCRIBE_SUCCESS,
    payload: res
  }
}

export const SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE'
export function subscribeFailure(err) {
  return {
    type: SUBSCRIBE_FAILURE,
    payload: err
  }
}

export const SUBSCRIBE_RESET = 'SUBSCRIBE_RESET'
export function subscribeReset() {
  return {
    type: SUBSCRIBE_RESET
  }
}
