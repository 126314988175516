import React from "react"
import Fade from "react-reveal/Fade"
import RichText from "./richText"

const BioCard = ({
	marginDisabled,
	gridSize,
	verticalAlign,
	linkText,
	children,
	data: { firstName, lastName, jobTitle, biography, download, socialProfiles }
}) => (
	<section className={"bio-card module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
		<div className={"grid " + (gridSize ? gridSize : "")}>
			<Fade cascade delay={700} duration={500} fraction={0.2}>
				<div className="t-bc">
					{children}
					<div className="t-h1 pb10">{firstName} {lastName}</div>
					<div className="t-link pb20">{jobTitle}</div>
					<div className="pb20 mb-items-md" dangerouslySetInnerHTML={{ __html: biography }}></div>
					{/* Biography Information Download Link */}
					{download && (
						<div className="pb20">
							<a href={download} className="btn btn-link black" download>
								{linkText}
							</a>
						</div>
					)}
					{/* Social Links */}
					{socialProfiles ? (
						<div className="social df" style={{gap: '40px'}}>
							{socialProfiles.facebook && (
								<a href={socialProfiles.facebook} title="Facebook" className="btn btn-social black" target="_blank">
									<span className="ico ico-font ico-facebook" />
								</a>
							)}
							{socialProfiles.instagram && (
								<a
									href={socialProfiles.instagram}
									title="Instagram"
									className="btn btn-social black"
									target="_blank"
								>
									<span className="ico ico-font ico-instagram" />
								</a>
							)}
							{socialProfiles.twitter && (
								<a href={socialProfiles.twitter} title="Twitter" className="btn btn-social black" target="_blank">
									<span className="ico ico-font ico-twitter" />
								</a>
							)}
							{socialProfiles.linkedin && (
								<a href={socialProfiles.linkedin} title="Linkedin" className="btn btn-social black" target="_blank">
									<span className="ico ico-font ico-linkedin" />
								</a>
							)}
						</div>
					) : null}
				</div>
			</Fade>
		</div>
	</section>
)

export default BioCard
