import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import LineBreak from "../layout/lineBreak"
import { post } from "../../actions/request"
import { searchCategory, searchCategoryUrl } from "../../lib/filterConversion"
import { resetFilter } from "../../actions/filters"
import { searchResults as filterOptions } from "../../lib/filters"
import Link from "../layout/link"
const { detect } = require("detect-browser")
const browser = detect()
import Fade from "react-reveal/Fade"

class SearchResults extends Component {
	constructor(props) {
		super(props)
		const { keywords } = queryString.parse(props.location.search)
		this.state = {
			page: 1,
			resultsUpdated: false,
			searchResults: [],
			searchInput: keywords,
			facets: {},
			showFilter: false
		}

		this.loadMore = this.loadMore.bind(this)
		this.facets = [
			"Press",
			"Office",
			"Work",
			"Page",
			"Leadership"
		]
	}

	componentDidMount() {
		this.getSearchResults()
		if (this.props.filters && this.props.filters.page !== "searchResults") {
			resetFilter()
		}
	}

	componentDidUpdate(prevProps, newProps) {
		// console.log('props', prevProps, newProps)
		const prevSearchResults = prevProps.searchResults
		const prevLocation = prevProps.location

		const currSearchResults = this.props.searchResults
		const currLocation = this.props.location

		const prevLast = prevSearchResults.length - 1
		const currLast = currSearchResults.length - 1

		const prevResult = prevSearchResults[prevLast] || { itemId: 0 }
		if (currLocation.search !== prevLocation.search) {
			this.setState({
				page: 1,
				resultsUpdated: false
			})
			this.getSearchResults()
		} else if (this.props.resultCount > 0 && this.state.page === 1 && !this.state.resultsUpdated) {
			this.setState({
				searchResults: currSearchResults,
				resultsUpdated: true
			})
		} else if (this.props.resultCount > 0 && this.state.page > 1 && prevResult.itemId !== currSearchResults[currLast].itemId) {
			this.setState({
				searchResults: this.state.searchResults.concat(currSearchResults),
				resultsUpdated: true
			})
		}
	}

	getSearchResults(page = 1) {
		const { location, Post } = this.props
		const { keywords, category = "" } = queryString.parse(location.search)
		if (keywords) {
			const categories = category.split(',')
			//Not supporting multiple cats at the moment
			Post({ keywords, category: (categories.length > 0 && categories[0]) ? [categories[0]] : null, page })
			if ((categories.length > 0 && categories[0])) {
				this.setState({ showFilter: false, facets: { [categories[0]]: categories[0] } })
			} else {
				this.setState({ showFilter: false, facets: {} })
			}
		}
	}

	applyFilter(category) {
		const { location, Post } = this.props
		const { keywords } = queryString.parse(location.search)
		const { history } = this.props
		if (keywords) {
			history.push(`${history.location.pathname}?${queryString.stringify({ keywords, category })}`)
			this.setState({searchResults: []})
			this.getSearchResults()
		}
	}

	loadMore() {
		const page = this.state.page + 1
		this.setState({
			page,
			resultsUpdated: false
		})
		this.getSearchResults(page)
	}

	resultItem(category, title) {
		return (
			<Fade bottom fraction={0.4}>
				<div className="search-result-item row flush middle">
					<div className="col col-2">
						<h4 className="h8">{category}</h4>
					</div>
					<div className="col col-10">
						<h3>{title}</h3>
					</div>
				</div>
			</Fade>
		)
	}

	render() {
		const { resultCount, openNav, toggleFilter, facets } = this.props
		const { searchResults, searchInput } = this.state
		return (
			<>
				<section className={"search-header" + (browser.name === "safari" || browser.name === "ios" ? " browser-safari " : " ")}>
					<form action="" className="search-header-bar">
						<input
							type="text"
							name="keywords"
							placeholder="Search"
							autoComplete="off"
							value={searchInput || ""}
							onChange={e => {
								this.setState({
									searchInput: e.target.value
								})
							}}
						/>
						<button className="btn btn-search">
							<span className="ico ico-font ico-search" />
						</button>
					</form>
				</section>
				<section className="search-actions">
					<div className="grid sml">
						<div className="row">
							<div className="col col-6 position-relative">
								<button
									className="btn btn-link black"
									onClick={() => { this.setState({ showFilter: !this.state.showFilter }) }}
								>
									{Object.keys(this.state.facets).length == 0 ? 'Filter Search Results' : `Filtered by: ${Object.keys(this.state.facets)[0]}`}
								</button>
								 <div id="facetsFilter" className={`position-absolute filter-card ${this.state.showFilter ? '' : 'hidden'}`}>
									<div className="filter-card-body">
										{this.facets.filter(f => f.toLocaleLowerCase() != "page").map((x, i) => {
											return <div key={i} className="row">
												<div className="col">
													<button
														className={`btn btn-link black mb1rem`}
														onClick={() => this.applyFilter(x)}
													>
														{x} {this.state.facets[x] ? `✓` : ``}
													</button>
												</div>
											</div>
										})}
										<div className="row">
											<div className="col">
												<button
													className={`btn btn-link black mb1rem`}
													onClick={() => this.applyFilter('')}
												>
													Clear
												</button>
											</div>
										</div>
									</div>
								</div> 
							</div>
							<div className="col col-6">
								<h5 className="h8 flr text-uppercase weight400">
									<span className="current-results">
										{searchResults && searchResults.length ? searchResults.length : "0"}
									</span>{" "}
									of <span className="total-results">{resultCount}</span> Results
								</h5>
							</div>
						</div>
					</div>
				</section>
				<section>
					<div className="grid sml">

					</div>
				</section>
				<LineBreak marginDisabled gridSize="sml" />
				<section className="search-results">
					<div className="grid sml">
						{searchResults &&
							searchResults.length > 0 &&
							searchResults.map(({ category, title, itemId, url, isExternal }) => (
								<div key={itemId}>
									{isExternal && (
										<a href={url} target="_blank">
											{this.resultItem(category, title)}
										</a>
									)}
									{!isExternal && <Link url={url}>{this.resultItem(category, title)}</Link>}
								</div>
							))}
						{searchResults && searchResults.length === 0 && <h3>There are no items to show based on your search criteria.</h3>}
					</div>
				</section>
				{resultCount !== searchResults.length && (
					<section className="search-pagination">
						<Fade bottom delay={300} duration={600}>
							<button className="btn btn-link black load-more" onClick={this.loadMore}>
								Load More Results
							</button>
						</Fade>
					</section>
				)}
			</>
		)
	}
}

SearchResults.defaultProps = {
	searchResults: [],
	resultCount: 0
}

const mapStateToProps = state => ({
	searchResults: state.request.data.results,
	resultCount: state.request.data.count || 0,
	filters: state.filters,
	facets: state.request.data.facets
})

const mapDispatchToProps = dispatch => ({
	Post: data => dispatch(post("search/filter", data)),
	resetFilter: () => dispatch(resetFilter())
})

let connectedPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchResults)
connectedPage = withRouter(connectedPage)

export default connectedPage
