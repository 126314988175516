module.exports = {
  article: [
    {
      title: 'Article',
      key: 'article'
    },
    {
      title: 'Press Release',
      key: 'pressrelease'
    },
    {
      title: 'Thought Leadership',
      key: 'thoughtleadership'
    }
  ]
};
