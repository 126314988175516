import {
  SET_FILTER,
  RESET_FILTER
} from '../actions/filters'

const INITIAL_STATE = {
  page: '',
  active: {}
}

function filters(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FILTER:
      return {
        page: action.page,
        active: {
          ...state.active,
          [action.category]: action.filters
        }
      }

    case RESET_FILTER:
      return {
        ...state,
        page: '',
        active: {}
      }

    default:
      return state
  }
}

export default filters
