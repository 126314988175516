import React from 'react';
import Link from './layout/link';

const linkStyle = className =>
  `primary-link ff-cp fz700 btn btn-link black ${ className || '' }`

const PrimaryLink = ({ className, children, path, url, linkTitle, linkTarget }) => (
  path ? (
  <Link
    classProp={linkStyle(className)}
    url={path}
  >
  { children }
  </Link>
  )
: url  ? (
  <a
    className={linkStyle(className)}
    href={url}
    title={ linkTitle || '' }
    target={linkTarget}
  >
  { children }
  </a>
  ) 
: (
  <div className={linkStyle(className)}>
  { children }
  </div>
  ) 
)

export default PrimaryLink;
