import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import Fade from "react-reveal/Fade"
import { Helmet } from "react-helmet"

import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import VideoHero from "../layout/videoHero"
import LineBreak from "../layout/lineBreak"
import FeatureCta from "../layout/featureCta"
import NeverFinishedCube from "../layout/neverFinishedCube"
import MediaItem from "../layout/mediaItem"
import CtaHero from "../layout/ctaHero"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import CapabilitiesList from "../layout/capabiltiesList"
import CapabilitiesCard from "../layout/capabilitiesCard"
import Grid from "../layout/grid"
import Link from "../layout/link"
import GridContainer from '../blocks/GridContainer';
import FadeComponent from '../blocks/FadeComponent';
import PrimaryLink from '../PrimaryLink';

class WhoIsFCB extends Component {
	constructor(props) {
		super(props)

		this.state = {
			// gallery: {
			// 	pin: false,
			// 	rtl: true,
			// 	setHeight: "auto",
			// 	buttonControl: true,
			// 	pager: false,
			// 	rotate: false,
			// 	mouseControl: false,
			// 	touchControl: true,
			// 	keyControl: false,
			// 	autoSlideControl: false,
			// 	speed: 0.6,
			// 	name: "gallery-inner"
			// },
			requestData: null,
			toggleModal: false,
		}
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/who-is-fcb")
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data,
		})
	}

	render() {
		const { requestData } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const heroImage = requestData.whoIsFcbPage.heroImage,
				title = requestData.whoIsFcbPage.title,
				hideTitle = requestData.whoIsFcbPage.hideTitle,
				content = requestData.whoIsFcbPage.content,
				heroVideo = requestData.whoIsFcbPage.heroVideo,
				neverFinished = requestData.whoIsFcbPage.neverFinished,
				leadershipCta = requestData.whoIsFcbPage.leadershipCta,
				neverFinishedCta = requestData.whoIsFcbPage.timelineCta,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				capabilities = requestData.whoIsFcbPage.capabilities.map((item) => ({ name: item })),
				metaUrl = location.href

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>

				<ImageHero
					marginDisabled
					images={heroImage}
					heroTitle={hideTitle ? "" : title}
				/>

				<section className={`site-content ${!content ? "no-top-padding" : ""}`}>
					{content && (
						<Fade fraction={0.2}>
							<Intro gridSize="sml" marginDisabled>
								<div className="pb100 pt60@md-mx fz24 lh1.66 fz16@md lh1.66@md">
									<RichText
										className="ff-nfd pb40 pb0@md"
										marginDisabled
										dropLastPadding
										textAlign="center"
										dangerouslySet
									>
										{ content }
									</RichText>
								</div>
							</Intro>
						</Fade>
					)}

					<div className="mb100">
					{/* {heroVideo &&
						<VideoHero
							responsive
							data={heroVideo}
						/>
					} */}
						<img className="responsive" src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/who-is-fcb/video.png" alt="Video Placeholder" />
					</div>

					{/* Never Finished Section */}
					{/* {neverFinished && (
						<div id="image456">
							<Intro gridSize="sml" marginDisabled>
								<RichText marginDisabled textAlign="center">
									<h2>Our Never Finished Approach</h2>
								</RichText>
							</Intro>

							<NeverFinishedCube content={neverFinished} />

							<LineBreak gridSize="sml" />
						</div>
					)} */}

					{/* Our Capabilities */}
					{false && capabilities && capabilities.length > 0 && (
						<>
							<CapabilitiesList gridSize="sml" title="Our Capabilities">
								<Grid
									gridItems={capabilities}
									rowClass={"flush start nowrap"}
									colnum={3}
									size={"sml"}
									Child={CapabilitiesCard}
									uniqueKey={"name"}
								/>
							</CapabilitiesList>
							<LineBreak gridSize="sml" />
						</>
					)}

					{/* Leadership */}
					{leadershipCta &&
						<GridContainer
							className="db aic mb100 df@md"
							size="medium"
						>
							<div className="w100% pr15 w50%@md">
								<FadeComponent
									className="oh"
									fadeSettings={{
										right     : true,
										duration : 600,
										fraction : 0.3
									}}
									innerWrapperClasses="image bgc-true-black"
									innerFadeSettings={{
										delay   : 700,
										duration: 500,
									}}
								>
									<MediaItem
										className="leadership-zoom"
										image={leadershipCta.image}
										alt={leadershipCta.imageAltText}
										ratio="3-4"
									/>
								</FadeComponent>
							</div>
							<div className="w100% pl30 pr15 pt25 pt0@md w50%@md">
								<h2 className="cta-heading ff-cp fz36 fz52@md fw700 mb20">
									{ leadershipCta.title }
								</h2>
								<div 
									className="cta-description fz16 fz18@md ff-nfd mb70"
									dangerouslySetInnerHTML={{__html: leadershipCta.description}}
								></div>
								<PrimaryLink
									className="btn btn-link black"
									path={leadershipCta.linkUrl}
								>
									<span className="ff-cp">{ leadershipCta.linkLabel }</span>
								</PrimaryLink>
							</div>
						</GridContainer>
					}

					{/* Approach */}
					<GridContainer
						className="db aic mb200 df@md fxdrrr"
						size="medium"
					>
						<div className="w100% pl15 w50%@md">
							<FadeComponent
								className="oh"
								fadeSettings={{
									left     : true,
									duration : 600,
									fraction : 0.3
								}}
								innerWrapperClasses="image bgc-true-black"
								innerFadeSettings={{
									delay   : 700,
									duration: 500,
								}}
							>
								<div className="orange-border">
									<MediaItem
										image={'https://d1f5kcwhveewqf.cloudfront.net/static/public/img/img/HubPage_Approach_optimized.gif'}
										ratio="3-4"
									/>
								</div>
							</FadeComponent>
						</div>
						<div className="w100% pl15 pr15 pt25 pt0@md w50%@md">
							<h2 className="cta-heading ff-cp fz36 fz52@md fw700 mb20">
								Approach
							</h2>
							<div className="cta-description fz16 fz18@md ff-nfd mb70">
								Explore our tools for embracing new opportunities.
							</div>
							<PrimaryLink
								className="btn btn-link fw700 black"
								path={'/who-we-are/approach'}
							>
								<span className="ff-cp">View our approach</span>
							</PrimaryLink>
						</div>
					</GridContainer>

					{/* Commitment */}
					<GridContainer
						className="db aic mb200 df@md"
						size="medium"
					>
						<div className="w100% pr15 w50%@md">
							<FadeComponent
								className="oh"
								fadeSettings={{
									right     : true,
									duration : 600,
									fraction : 0.3
								}}
								innerWrapperClasses="image"
								innerFadeSettings={{
									delay   : 700,
									duration: 500,
								}}
							>
								<img
									className="w100%"
									src="https://d1f5kcwhveewqf.cloudfront.net/static/public/img/img/DEI-Logo.jpg"
								/>
							</FadeComponent>
						</div>
						<div className="w100% pl30 pr15 pt25 pt0@md w50%@md">
							<h2 className="cta-heading ff-cp fz36 fz52@md fw700 mb20">
							Inclusion Commitment 
							</h2>
							<div 
								className="cta-description fz16 fz18@md ff-nfd mb70"
							>
								See how we're creating a more inclusive agency.
							</div>
							<PrimaryLink
								className="btn btn-link black"
								path={'/who-we-are/deib'}
							>
								<span className="ff-cp">View our inclusion commitment</span>
							</PrimaryLink>
						</div>
					</GridContainer>

					{/* Never Finished Timeline CTA */}
					{
						neverFinishedCta && (
						<CtaHero
							url={neverFinishedCta.linkUrl}
							gridSize="med"
							image={neverFinishedCta.image}
							imageAltText={neverFinishedCta.imageAltText}
							subtitle={neverFinishedCta.subtitle}
							title={neverFinishedCta.title}
						/>
					)
					}
				</section>
			</>
			)
		}
	}
}

WhoIsFCB.defaultProps = {
	Request: new Object(),
}

const mapStateToProps = (state) => ({
	Request: state.request,
})

const mapDispatchToProps = (dispatch) => ({
	Fetch: (category) => dispatch(fetch(category)),
})
let connectedPage = connect(mapStateToProps, mapDispatchToProps)(WhoIsFCB)
connectedPage = withRouter(connectedPage)

export default connectedPage
