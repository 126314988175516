import filter from '../../../data/filter.json';

const filterConversion = Object.keys(filter).reduce((acc, curr) => {
  const filterObj = filter[curr].reduce((keyValAcc, { key, label }) =>
    Object.assign(keyValAcc, { [key]: label })
  , {});

  const filterObjUrl = filter[curr].reduce((keyValAcc, { key, url }) =>
    Object.assign(keyValAcc, { [key]: url })
  , {});

  return Object.assign(acc, { [curr]: filterObj, [`${curr}Url`]: filterObjUrl });
}, {});

export const articleCategory   = filterConversion.articleCategory;
export const searchCategory    = filterConversion.searchCategory;
export const searchCategoryUrl = filterConversion.searchCategoryUrl;
