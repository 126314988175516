import { combineReducers } from 'redux';

import {
  NEXT_PAGE,
  NEXT_PAGE_SUCCESS,
  REQUEST,
  REQUEST_SUCCESS,
  REQUEST_FAILURE,
  POST_REQUEST,
  POST_REQUEST_SUCCESS,
  POST_REQUEST_FAILURE,
  DETAILS_REQUEST,
  DETAILS_REQUEST_SUCCESS,
  DETAILS_REQUEST_FAILURE
} from '../actions/request'

const INITITAL_STATE = {
  busy: false,
  data: new Array
}

function request(state = INITITAL_STATE, action) {
  switch (action.type) {
    case DETAILS_REQUEST:
      return {
        ...state,
        busy: true
      }

    case DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        busy: false,
        msg: action.payload.statusText,
        status: true,
        data: [action.payload.data]
      }

    case DETAILS_REQUEST_FAILURE:
      return {
        ...state,
        busy: false,
        msg: 'Your request has failed please refresh and try again',
        status: false,
      }

    case REQUEST:
      return {
        ...state,
        busy: true,
        data: new Array
      }

    case REQUEST_SUCCESS:
      return {
        ...state,
        busy: false,
        msg: action.payload.statusText,
        status: true,
        data: action.payload.data
      }

    case REQUEST_FAILURE:
      return {
        ...state,
        busy: false,
        msg: 'Your request has failed please refresh and try again',
        status: false,
      }

    case POST_REQUEST:
      return {
        ...state,
        busy: true,
        data: new Array
      }

    case POST_REQUEST_SUCCESS:
      return {
        ...state,
        busy: false,
        msg: action.payload.statusText,
        status: true,
        data: action.payload.data
      }

    case POST_REQUEST_FAILURE:
      return {
        ...state,
        busy: false,
        msg: 'Your request has failed please refresh and try again',
        status: false,
      }

    case NEXT_PAGE:
      return {
        ...state,
        busy: true
      }

    case NEXT_PAGE_SUCCESS:
      return {
        ...state,
        busy: false,
        msg: action.payload.statusText,
        status: true,
        data: [...state.data, ...action.payload.data]
      }

    default:
      return state
  }
}

export default request
