import React from 'react'
import sanitizeHtml from 'sanitize-html'

const RichText = ({ className, marginDisabled, dropLastPadding, textAlign, children, dangerouslySet }) => {
  if (dangerouslySet) {
    const sanitizedChildren = sanitizeHtml(children, {
      allowedTags: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'a', 'ul', 'ol', 'li',
        'b', 'i', 'strong', 'em', 'u', 'strike', 'hr', 'br', 'div',
        'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'img', 'iframe', 'nobr' ],
      allowedClasses: false,
      allowedAttributes: {
        '*': [ 'href', 'target', 'class', 'className', 'src', 'title', 'style', 'alt', 'align', 'rel' ]
      },
      selfClosing: [ 'img', 'br', 'hr', 'link' ],
      allowedSchemes: [ 'http', 'https', 'mailto', 'tel' ],
      allowedSchemesByTag: {},
      allowedSchemesAppliedToAttributes: [ 'href', 'src', 'cite' ],
      allowProtocolRelative: true
    })
    return (
      <section className={"richtext module-margin " + (marginDisabled ? "module-margin-disabled " : "") + (dropLastPadding ? "drop-last-padding " : "") + (textAlign ? ("text-" + textAlign + " ") : "") + (className || '')} dangerouslySetInnerHTML={{ __html: sanitizedChildren }}></section>
    )
  } else {
    return (
      <section className={"richtext module-margin " + (marginDisabled ? "module-margin-disabled " : "") + (dropLastPadding ? "drop-last-padding " : "") + (textAlign ? ("text-" + textAlign + " ") : "") + (className || '')}>
        {children}
      </section>
    )
  }
}

export default RichText
