import React from "react"

const ButtonList = ({ marginDisabled, textAlign, children }) => (
	<section
		className={
			"button-list module-margin " + (marginDisabled ? "module-margin-disabled " : "") + (textAlign ? "text-" + textAlign + " " : "")
		}
	>
		{children}
	</section>
)

export default ButtonList
