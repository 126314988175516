import React, { Component } from "react"
import { Navigation } from "../../constants/navigation"
import Link from "./link"
import Slide from "react-reveal/Slide"

class Subnav extends Component {
	constructor(props) {
		super(props)

		this.state = {
			url: location.pathname,
			navList: Navigation,
			subList: null,
			currentSection: location.pathname.split("/")[1]
		}

		this.handleBuild = this.handleBuild.bind(this)
	}

	handleBuild() {
		const { navList, currentSection } = this.state,
			subnav = navList.find(item => item.path.indexOf(`/${currentSection}`) > -1)

		this.setState({
			subList: subnav
		})
	}

	componentDidMount() {
		this.handleBuild()
	}

	render() {
		const { marginDisabled } = this.props
		const { url, subList } = this.state

		return (
			<section className={"subnav module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
				{subList != null &&
					subList.children.map((item, index) => {
						return !item.blank ? (
							<Link
								key={`${item.name}-${index}`}
								url={item.path}
								classProp={"btn subnav-item" + (item.path === url ? " active" : "")}
								title={item.name}
							>
								<Slide bottom duration={600}>
									{item.name}
								</Slide>
							</Link>
						) : (
							<a href={item.path} className="btn subnav-item" title={item.name} target="_blank" key={`${item.name}-${index}`}>
								{item.name}
							</a>
						)
					})}
			</section>
		)
	}
}

export default Subnav
