import React from "react"

const CareersList = ({ marginDisabled, gridSize, title, textAlign, children }) => (
	<section
		className={
			"careers-list module-margin " +
			(marginDisabled ? "module-margin-disabled " : "") +
			(textAlign ? "text-" + textAlign + " " : "")
		}>
		<div className={"grid " + (gridSize ? gridSize : "")}>{children}</div>
	</section>
)

export default CareersList
