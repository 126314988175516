import React, { Component, useState, FC, useCallback } from 'react';
import { withRouter } from "react-router-dom"
import { Field, reduxForm } from "redux-form"
import { connect } from "react-redux"
import { handleSubmit, handleReset } from "../../actions/submission"
import { required, validEmail, validURL } from "../../lib/validation"

import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

class JoinForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			recaptchaToken: null,
		}
	}

	componentDidMount() {
		const { Reset, Submission } = this.props
		if (Submission.status === true)
			Reset()
	}

	inputCheck = e => {
		let fieldContainer = e.currentTarget.parentElement.parentElement

		if (e.currentTarget.value === "") {
			if (fieldContainer.classList.contains("not-empty"))
				fieldContainer.classList.remove("not-empty")

			if (fieldContainer.classList.contains("focused"))
				fieldContainer.classList.remove("focused")

		}
		else
			fieldContainer.classList.add("not-empty")
	}

	inputFocus = e => {
		let fieldContainer = e.currentTarget.parentElement.parentElement

		if (!fieldContainer.classList.contains("focused"))
			fieldContainer.classList.add("focused")
	}

	setRecaptchaToken = recaptchaToken => this.setState({ recaptchaToken })

	submit = values => {
		values.recaptchaToken = this.state.recaptchaToken;
		const { Submit } = this.props
		Submit("/enquiries/submitportfolio", values)
	}

	render() {
		const {
			offices,
			Submission: { busy, status }
		} = this.props

		return (
			<>
				{!status &&
					<Form
						onSubmit={this.submit}
						offices={offices}
						setRecaptchaToken={this.setRecaptchaToken}
						busy={busy}
						status={status}
					/>
				}
				{!busy && status && (
					<div className="grid sml module-margin">
						<div className="row">
							<div className="col text-center">
								<div className="t-h3">
									Thank you for submitting your portfolio to FCB.
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		)
	}
}

let Form = ({ handleSubmit, onSubmit, offices, setRecaptchaToken, busy, status }) => {
	console.log(busy, status)
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [token, setToken] = useState('');
	const [dynamicAction, setDynamicAction] = useState('homepage');
	const [actionToChange, setActionToChange] = useState('');
	const [noOfVerifications, setNoOfVerifications] = useState(0);

	const submitClickHandler = useCallback(async () => {
		if (!executeRecaptcha) return;

		const result = await executeRecaptcha('dynamicAction');
		setToken(result);
		setRecaptchaToken(result);

		setNoOfVerifications(noOfVerifications => noOfVerifications + 1);

		handleSubmit()

	}, [dynamicAction, executeRecaptcha]);

	const handleReCaptchaVerify = useCallback(
		token => {
			setToken(token);
			setRecaptchaToken(token);
			setNoOfVerifications(noOfVerifications => noOfVerifications + 1);
			console.log('verify?', token, noOfVerifications)
		},
		[setNoOfVerifications, setToken]
	);

	const handleCommitAction = useCallback(() => {
		setDynamicAction(actionToChange);
	}, [actionToChange]);

	return (
		<div className="forms">
			<div className="grid sml text-left">
				<div className="row flush top">
					<div className="col col-12">
						<div className="t-h2 text-center">Submit Your Creative Portfolio</div>
					</div>
				</div>
				<div className="row flush top">
					<div className="col col-6">
						<Field name="name" label="First name" component={Input} type="text" validate={[required]} />
					</div>
					<div className="col col-6">
						<Field name="email" label="Email address" component={Input} type="email" validate={[required, validEmail]} />
					</div>
				</div>
				<div className="row flush top">
					<div className="col col-6">
						<Field name="phone" label="Phone number" component={Input} type="tel" validate={[required]} />
					</div>
					<div className="col col-6">
						<Field name="location" label="Your preferred location" component={Select} validate={[required]}>
							<option value="" />
							{offices.map((el, index) => (
								<option key={index} value={el.displayName}>
									{el.displayName}
								</option>
							))}
						</Field>
					</div>
				</div>
				<div className="row flush top">
					<div className="col">
						<Field name="portfolioLink" label="Portfolio url" component={Input} type="url" validate={[required, validURL]} />
					</div>
				</div>
				<div className="row flush top">
					<div className="col">
						<GoogleReCaptcha
							action={dynamicAction}
							onVerify={handleReCaptchaVerify}
						/>
					</div>
				</div>
				<div className="row flush top">
					<div className="col">
						<div className="formfield button">
						{(status !== false) &&
							<button
								onClick={submitClickHandler}
								className="btn btn-link black"
								disabled={busy}
							>
								{busy ? `Processing` : `Submit your portfolio`}
							</button>
						}
						</div>
					</div>
				</div>
				{(status === false) &&
					<div className="row flush top">
						<div className="col">
							<p
							style={{
								'color':'red'
							}}
							>Could not process request. Please email news@fcb.com directly.</p>
						</div>
					</div>
				}
			</div>
		</div>
	)
}

const Block = ({ input, wrapClass, label, meta, children }) => (
	<div
		className={`formfield ${wrapClass} ${input.value ? "not-empty" : ""} ${meta.active ? "focused" : ""} ${meta.touched && meta.error ? "validation-error" : ""
			}`}
	>
		<div className="field-input">{children}</div>
		<label htmlFor={input.name} className="field-label">
			{label}
		</label>
		<span className="validation-text">{meta.touched && meta.error && meta.error}</span>
	</div>
)

const Input = ({ input, label, meta }) => (
	<Block input={input} label={label} meta={meta} wrapClass="textfield">
		<input {...input} />
	</Block>
)

const Select = ({ input, label, meta, children }) => (
	<Block input={input} label={label} meta={meta} wrapClass="dropdownlist">
		<select {...input}>
			{children}
		</select>
	</Block>
)

Form = reduxForm({
	form: "Form"
})(Form)

JoinForm.defaultProps = {
	offices: []
}

const mapStateToProps = state => ({
	Submission: state.submission
})

const mapDispatchToProps = dispatch => ({
	Submit: (url, payload, external) => dispatch(handleSubmit(url, payload, external)),
	Reset: () => dispatch(handleReset())
})

let connectedComponent = connect(mapStateToProps, mapDispatchToProps)(JoinForm)
connectedComponent = withRouter(connectedComponent)

export default connectedComponent
