import axios from 'axios'

export function get(url) {
  return axios.get('/api' + url)
  .then(res => {
    return res
  })
  .catch(err => {
    throw err
  })
}

export function post(url, payload, external) {
  return axios.post((external === true ? '' : '/api') + url, payload)
  .then(res => {
    return res
  })
  .catch(err => {
    throw err
  })
}

export function widont(selectors) {
		document.querySelectorAll(selectors).forEach(x => {
      // console.log('widont')
		  var wordArray = x.innerText.split(" ").map(x => x.trim());
		  var lastWords = wordArray.pop()
		  lastWords = lastWords.split(/\s/g)
		  lastWords.forEach(x => wordArray.push(x))// s/b full reset
		  if (wordArray.length > 1 && window.innerWidth > 414) {
			wordArray[wordArray.length - 2] += "&nbsp;" + wordArray[wordArray.length - 1];
			wordArray.pop();
			if (wordArray[wordArray.length - 1].length < 20) {
			  const test = wordArray[wordArray.length - 2] + "&nbsp;" + wordArray[wordArray.length - 1]
			  if (test.length < 30) {
				wordArray[wordArray.length - 2] += "&nbsp;" + wordArray[wordArray.length - 1];
				wordArray.pop();
			  }
			}
		  }
		  x.innerHTML = wordArray.join(" ");
		})
}
