import React, { Component } from "react"
import PropTypes from "prop-types"
import { TweenMax, Power2 } from "gsap/all"
import { connect } from "react-redux"
import queryString from "query-string"
import { setFilter } from "../../actions/filters"

class Filter extends Component {
	constructor(props) {
		super(props)

		this.animateIn = this.animateIn.bind(this)
		this.handleAnimation = this.handleAnimation.bind(this)
		this.toggleView = this.toggleView.bind(this)
		this.setFilter = this.setFilter.bind(this)
	}

	handleAnimation() {
		if (this.props.filterActive) {
			this.animateIn()
		}
	}

	animateIn() {
		let filter = document.getElementById("filter"),
			dropGroups = document.getElementsByClassName("group")

		TweenMax.fromTo(
			filter,
			0.75,
			{ opacity: 0, transform: "translateX(-614px)", ease: Power2.easeInOut },
			{ opacity: 1, transform: "translateX(0)", ease: Power2.easeInOut, clearProps: "all" }
		).delay(0.3)

		if (dropGroups.length > 0) {
			TweenMax.set(dropGroups, { maxHeight: "0", marginBottom: "0", className: "-=active" })
			TweenMax.set(dropGroups[0], { maxHeight: "1000", marginBottom: "50px", className: "+=active" })

			dropGroups[0].previousSibling.classList.add("active")
		}
	}

	toggleView(e) {
		var dropButton = e.currentTarget,
			dropGroup = dropButton.nextSibling

		if (dropGroup.classList.contains("active")) {
			dropButton.classList.remove("active")
			TweenMax.to(dropGroup, 0.45, { maxHeight: "0", marginBottom: "0", ease: Power3.easeInOut, className: "-=active" })
		} else {
			dropButton.classList.add("active")
			TweenMax.to(dropGroup, 0.45, { maxHeight: "1000", marginBottom: "50px", ease: Power3.easeInOut, className: "+=active" })
		}
	}

	componentDidMount() {
		this.handleAnimation()
	}

	componentDidUpdate() {
		// this.handleAnimation()
	}

	setFilter(page, category, key) {
		const { filters, history, setFilter } = this.props
		const currentFilters = filters.active
		if (currentFilters[category] && currentFilters[category].includes(key)) {
			currentFilters[category] = currentFilters[category].filter(e => e !== key)
		} else {
			if (!currentFilters[category]) {
				currentFilters[category] = []
			}
			currentFilters[category].push(key)
		}
		const currentQueryString = queryString.parse(history.location.search)
		setFilter(page, category, currentFilters[category])
		history.push(`${history.location.pathname}?${queryString.stringify(Object.assign(currentQueryString, currentFilters))}`)
	}

	filterActive(page, category, key) {
		const { filters } = this.props
		return filters.active[category] && filters.active[category].includes(key)
	}

	render() {
		const { filterActive, filterPage, filterOptions } = this.props

		return (
			<div id="filter" className={"filter" + (filterActive ? " filter-active" : "")}>
				{filterOptions.map((category, index) => (
					<div key={index}>
						<div className="t-h" onClick={this.toggleView}>{category.title}</div>
						<div className="group">
							{category.options &&
								category.options.map((option, index) => (
									<button
										className={`btn btn-filter-item ${
											this.filterActive(filterPage, category.key, option.key || option) ? "active" : ""
										}`}
										key={index}
										onClick={() => this.setFilter(filterPage, category.key, option.key ? option.key : option)}
									>
										{option.title ? option.title : option}
									</button>
								))}
						</div>
					</div>
				))}
			</div>
		)
	}
}

Filter.defaultProps = {
	filterOptions: []
}

Filter.propTypes = {
	filterActive: PropTypes.bool,
	filterPage: PropTypes.string,
	filterOptions: PropTypes.array
}

const mapStateToProps = state => ({
	filters: state.filters
})

const mapDispatchToProps = dispatch => ({
	setFilter: (page, category, key) => dispatch(setFilter(page, category, key))
})

let connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(Filter)

export default connectedComponent
