import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { navigate } from "../../actions/pagetransition"

class Link extends Component {
	constructor(props) {
		super(props)

		this.handleNav = this.handleNav.bind(this)
	}

	handleNav(destination) {
		if (!destination) { return; }
		const { Navigate } = this.props
		let currentLocation = this.props.location.pathname || window.location.pathname

		if (currentLocation != destination) {
			Navigate(destination)
		}
	}

	render() {
		const { title, classProp, url, children } = this.props

		return (
			<div title={title} className={classProp ? classProp : "link-nav"} onClick={() => this.handleNav(url)}>
				{children}
			</div>
		)
	}
}

Link.propTypes = {
	url: PropTypes.string
}

const mapDispatchToProps = dispatch => ({
	Navigate: url => dispatch(navigate(url))
})

Link = connect(
	null,
	mapDispatchToProps
)(Link)
Link = withRouter(Link)

export default Link
