import React, { Component } from "react"
import { TweenMax, EasePack, Power2 } from "gsap/all"

class Menu extends Component {
	constructor(props) {
		super(props)

		this.animateIn = this.animateIn.bind(this)
		this.animateOut = this.animateOut.bind(this)
	}

	animateIn() {
		let links = document.getElementsByClassName("menu-item")

		TweenMax.staggerFromTo(
			links,
			0.7,
			{ transform: "translateX(-414px)" },
			{ transform: "translateX(0)", ease: Power2.easeOut, clearProps: "all" },
			0.15
		)
	}

	animateOut(cb) {
		const links = document.getElementById("menu") ? document.getElementById("menu").childNodes : []

		TweenMax.to(links, 0.4, { transform: "translateX(-414px)", ease: Power2.easeOut, clearProps: "all", onComplete: cb })
	}

	componentDidMount() {
		this.animateIn()
	}

	componentDidUpdate() {
		this.animateIn()
	}

	render() {
		const { data, forward } = this.props
		const loc = location.pathname
		return (
			<nav id="menu" className="menu">
				{data.map((item, index) => {
					return item.blank ? (
						<a target="_blank" className={"btn menu-item " + (item.class ? item.class : "")} key={index} href={item.path}>
							{item.name}
						</a>
					) : (
						<span 
							className="menu-section"
							key={index}
						> 
							<button
								className={"btn menu-item " + (item.class ? item.class : "") + (item.path === loc ? " active" : "")}
								key={index}
								onClick={() => forward(item)}
							>
								{item.name}
							</button>
							{ 
								Array.isArray(item.children) ? item.children.map((childItem, childIndex) => {
									return (<button
										className={"btn menu-item " + (childItem.class ? childItem.class : "") + (childItem.path === loc ? " active" : "")}
										key={childIndex}
										onClick={() => forward(childItem)}
									>
										{childItem.name}
									</button>)
								}) : ("")
							}
						</span>
					)
				})}
			</nav>
		)
	}
}

export default Menu
