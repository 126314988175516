import React from "react"

const BrowseHappy = () => {
	const ieCondition =
		'<!--[if lte IE 9]><section class="browsehappy"><h2><img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/fcb-logo.png" class="img-responsive" alt="FCB Global - Foote, Cone & Belding" /><br />You are using an <strong>outdated</strong> browser. Please <a href="http://browsehappy.com/" title="browser happy" target="_blank"><strong>upgrade your browser</strong></a> to improve your experience.</h2></section><![endif]-->'

	return <section dangerouslySetInnerHTML={{ __html: ieCondition }} />
}

export default BrowseHappy
