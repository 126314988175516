import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { Helmet } from "react-helmet"

import HcCarousel from "../layout/hcCarousel"
import SliderHorizontal from "../layout/sliderHorizontal"
import SliderVertical from "../layout/sliderVertical"
import Media from "react-media"
import RainbowText from "../blocks/rainbowText"

class Home extends Component {
	constructor() {
		super();
		this.state = {
			vertical: {
				keyControl: true,
				pager: false,
				rotate: true,
				pin: true,
				pinMsgStart: "Scroll to begin",
				pinMsgMiddle: "Keep scrolling",
				pinMsgEnd: "Never Finished",
				speed: 0.65,
				name: "page-carousel-vert",
				fullscreen: true
			},
			horizontal: {
				pin: false,
				rtl: true,
				pager: true,
				rotate: true,
				mouseControl: false,
				touchControl: true,
				keyControl: true,
				autoSlideControl: true,
				autoInterval: 9,
				speed: 0.8,
				name: "page-carousel-horz",
				fullscreen: true
			},
			requestData: null,
		}
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/home")
	}

	componentWillReceiveProps(nextProps) {
		//console.log("NEXT PROPS ", nextProps.Request.data)
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	render() {
		const { vertical, horizontal, requestData } = this.state
		const { openVideoOverlay } = this.props

		if (requestData === null || requestData instanceof Array || requestData === undefined)
			return null;
		
		const sliderHorz = requestData.homePage.sliderHorizontal,
					sliderVert = requestData.homePage.sliderVertical,
					metaTitle  = requestData.metaData.title,
					metaDesc   = requestData.metaData.description,
					metaImage  = requestData.metaData.image,
					metaUrl    = location.href;

		return (
		<>
			<Helmet>
				<title>{metaTitle}</title>
				<link rel="canonical" href={metaUrl} />
				<meta name="description" content={metaDesc} />
				<meta property="og:url" content={metaUrl} />
				<meta property="og:title" content={metaTitle} />
				<meta property="og:description" content={metaDesc} />
				<meta property="og:image" content={metaImage} />
				<meta name="twitter:title" content={metaTitle} />
				<meta name="twitter:description" content={metaDesc} />
				<meta name="twitter:image" content={metaImage} />
				<meta name="twitter:image:alt" content={metaTitle} />
			</Helmet>
			<HcCarousel options={vertical}>
				<article>
					<HcCarousel
						options={horizontal}
						delay={2000}
					>
						{sliderHorz.map((item, index) => (
							<SliderHorizontal
								key={"sliderHorz" + index}
								openVideoOverlay={openVideoOverlay}
								data={item}
							/>
							))
						}
					</HcCarousel>
				</article>
				<article>
					<div id="CopySlide" style={{
						background:'#000',
						color: '#FFF', 
						height:'100vh', 
						width:'100vw', 
						display: 'grid',
						placeItems: 'center',
						}}>
						<div
						className=""
						style={{
							padding: '2rem',
							margin: 'auto',
							display: 'flex',
							flexDirection: 'column',
							gap: '1rem'
						}}
						>
							<p className="home-copy">At FCB, we understand that activating business in the short term is critical. But so is building brands for the long term. We don’t think clients should have to choose between the two, and that’s why we’ve developed our offering to deliver on both simultaneously, through creativity. In fact, it’s core to our mission: building brands that are both <RainbowText className="ff-nfd fsi"><span>Timeless</span></RainbowText> and <RainbowText className="ff-nfd fsi"><span>Timely</span></RainbowText>, with creativity fueled by diversity, data and technology to drive big business success.</p>
							<p className="home-copy">We have built FCB around the belief that creativity is truly an economic multiplier and that brands who can unlock it across everything they do – from advertising to design, performance to retention – will build a powerful strategic advantage over&nbsp;their&nbsp;competitors.</p>
						</div>
						</div>
				</article>
				{sliderVert.map((item, index) => (
					<SliderVertical
						key={"sliderVert" + index}
						openVideoOverlay={openVideoOverlay}
						data={item}
					/>
					))
				}
			</HcCarousel>
		</>
		)
	}
}

Home.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(Home)
connectedPage = withRouter(connectedPage)

export default connectedPage
