import React, { Component } from "react"
import { ConnectedRouter } from "connected-react-router"
import { Route, Switch, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import queryString from "query-string"
import { TweenMax, EasePack } from "gsap/all"
import { Helmet } from "react-helmet"

import DefaultLayout from "../layout/defaultLayout"
import NotFound from "../components/pages/notFound"
import SearchResults from "../components/pages/searchResults"
import Home from "../components/pages/home"
import Work from "../components/pages/work"
import WorkDetail from "../components/pages/workDetail"
import WhoIsFcb from "../components/pages/whoIsFcb"
import Leadership from "../components/pages/leadership"
import LeadershipDetail from "../components/pages/leadershipDetail"
import NeverFinishedStory from "../components/pages/neverFinishedStory"
import DeAndI from "../components/pages/deAndI"
import Approach from "../components/pages/approach"
import WhereWeAre from "../components/pages/whereWeAre"
import WhereWeAreDetail from "../components/pages/whereWeAreDetail"
import PressCenter from "../components/pages/pressCenter"
import PressCenterDetail from "../components/pages/pressCenterDetail"
import PressKit from "../components/pages/pressKit"
import FcbFeed from "../components/pages/fcbFeed"
import WorkingAtFcb from "../components/pages/workingAtFcb"
import JoinOurTeam from "../components/pages/joinOurTeam"
import Contact from "../components/pages/contact"
import TermsAndConditions from "../components/pages/termsAndConditions"
import CcpaPrivacyNotice from "../components/pages/ccpaPrivacyNotice"
import PrivacyPolicy from "../components/pages/privacyPolicy"
import Verify from "../components/pages/verify"

import Capabilities from '../components/pages/capabilities';

import PageTransition from "../components/layout/pageTransition"
import { reset, navigate, updateAdaCompliance } from "../actions/pagetransition"
import { resetFilter } from "../actions/filters"
import sitemapData from "../../../data/sitemap.json"
const sitemap = { ...sitemapData.frontend }
class AppContainer extends Component {
	constructor() {
		super()

		this.state = {
			active: false,
			menuActive: false,
			searchActive: false,
			filterActive: false,
			filterPage: "",
			filterOptions: "",
			transition: false,
			destination: null,
			shareVisible: false,
			subscribeVisible: false,
			videoOverlayVisible: false,
			videoOverlayUrl: ""
		}

		this.handleNavOpen = this.handleNavOpen.bind(this)
		this.handleNavClose = this.handleNavClose.bind(this)
		this.toggleShareOverlay = this.toggleShareOverlay.bind(this)
		this.toggleSubscribeOverlay = this.toggleSubscribeOverlay.bind(this)
		this.openVideoOverlay = this.openVideoOverlay.bind(this)
		this.closeVideoOverlay = this.closeVideoOverlay.bind(this)
		this.toggleSearch = this.toggleSearch.bind(this)
		this.toggleFilter = this.toggleFilter.bind(this)
		this.toggleMenu = this.toggleMenu.bind(this)
		this.filterReset = this.filterReset.bind(this)
		this.toggleStopAnimation = this.toggleStopAnimation.bind(this)
	}

	handleNavOpen() {
		this.setState({
			active: true
		})
	}

	handleNavClose() {
		this.setState({
			active: false,
			menuActive: false,
			searchActive: false,
			filterActive: false
		})
	}

	toggleMenu() {
		this.setState({
			searchActive: false,
			filterActive: false,
			menuActive: !this.state.menuActive
		})
	}

	toggleSearch() {
		this.setState({
			menuActive: false,
			filterActive: false,
			searchActive: !this.state.searchActive
		})
	}

	toggleFilter(page, options) {
		this.setState({
			menuActive: false,
			searchActive: false,
			filterActive: !this.state.filterActive,
			filterPage: page,
			filterOptions: options
		})
	}

	toggleShareOverlay() {
		this.setState({
			shareVisible: !this.state.shareVisible
		})
	}

	toggleStopAnimation() {
		const { updateAdaCompliance } = this.props
		updateAdaCompliance()
	}

	toggleSubscribeOverlay() {
		this.setState({
			subscribeVisible: !this.state.subscribeVisible
		})
	}

	openVideoOverlay(videoUrl) {
		this.setState({
			videoOverlayVisible: true,
			videoOverlayUrl: videoUrl
		})
		window.__vimeoRefresh() 
	}

	closeVideoOverlay() {
		this.setState({
			videoOverlayVisible: false
		})
	}

	filterReset() {
		const { history, resetFilter } = this.props
		const {
			location: { pathname, search }
		} = history
		const { keywords } = queryString.parse(search)
		resetFilter()
		if (pathname === "/results" && keywords) {
			history.push(`${pathname}?${queryString.stringify({ keywords })}`)
		} else {
			history.push(pathname)
		}
	}

	componentDidMount() {
		console.log(
			"%cFCB " + new Date().getFullYear() + "  ",
			'font-weight: bold; font-size: 50px; line-height:85px; font-family:"code-pro", Helvetica, Arial, sans-serif; color: #1f1646; text-shadow: 3px 3px 0 #0033a1 , 6px 6px 0 #00b3e3 , 9px 9px 0 #e1261c , 12px 12px 0 #ee7624 , 15px 15px 0 #f4c400 , 18px 18px 0 #e6437b , 21px 21px 0 #9f2943 , 24px 24px 0 #673278'
		)
	}

	render() {
		const {
			active,
			shareVisible,
			subscribeVisible,
			videoOverlayVisible,
			videoOverlayUrl,
			menuActive,
			searchActive,
			filterActive,
			filterPage,
			filterOptions
		} = this.state
		const { Navigate, Reset, destination, transition, history, busy, navBack } = this.props

		return (
			<ConnectedRouter history={history}>
				<DefaultLayout
					history={history}
					toggleShareOverlay={this.toggleShareOverlay}
					toggleStopAnimation={this.toggleStopAnimation}
					toggleSubscribeOverlay={this.toggleSubscribeOverlay}
					reset={Reset}
					navigate={Navigate}
					openNav={this.handleNavOpen}
					closeNav={this.handleNavClose}
					shareVisible={shareVisible}
					subscribeVisible={subscribeVisible}
					active={active}
					busy={transition}
					videoOverlayVisible={videoOverlayVisible}
					videoOverlayUrl={videoOverlayUrl}
					closeVideoOverlay={this.closeVideoOverlay}
					menuActive={menuActive}
					searchActive={searchActive}
					filterActive={filterActive}
					filterPage={filterPage}
					filterOptions={filterOptions}
					filterReset={this.filterReset}
					toggleMenu={this.toggleMenu}
					toggleSearch={this.toggleSearch}
					toggleFilter={this.toggleFilter}
				>
					{/* <Helmet>
						<title data-react-helmet="true">FCB</title>
						<link rel="canonical" href={location.origin} />
						<meta
							name="description"
							content="FCB (Foote, Cone &amp; Belding) is filled with a diverse and passionate group of thinkers, creators, technologists and storytellers devoted to creating buzzworthy ideas that change consumer behavior."
							data-react-helmet="true"/>
						<meta property="og:locale" content="en_US" data-react-helmet="true"/>
						<meta property="og:type" content="website" data-react-helmet="true"/>
						<meta property="og:ttl" content="345600" data-react-helmet="true"/>
						<meta property="og:url" content={location.origin} data-react-helmet="true"/>
						<meta property="og:title" content="FCB" data-react-helmet="true"/>
						<meta
							property="og:description"
							content="FCB (Foote, Cone &amp; Belding) is filled with a diverse and passionate group of thinkers, creators, technologists and storytellers devoted to creating buzzworthy ideas that change consumer behavior."
							data-react-helmet="true"/>
						<meta property="og:image" content="https://d1f5kcwhveewqf.cloudfront.net/uploads/meta-image.jpg" data-react-helmet="true"/>
						<meta property="og:image:width" content="600" data-react-helmet="true"/>
						<meta property="og:image:height" content="315" data-react-helmet="true"/>
						<meta name="twitter:title" content="FCB" data-react-helmet="true"/>
						<meta
							name="twitter:description"
							content="FCB (Foote, Cone &amp; Belding) is filled with a diverse and passionate group of thinkers, creators, technologists and storytellers devoted to creating buzzworthy ideas that change consumer behavior."
							data-react-helmet="true"/>
						<meta name="twitter:image" content="https://d1f5kcwhveewqf.cloudfront.net/uploads/meta-image.jpg" data-react-helmet="true"/>
						<meta name="twitter:image:alt" content="FCB" data-react-helmet="true"/>
						<meta name="twitter:site" content="@FCBglobal" data-react-helmet="true"/>
						<meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
					</Helmet> */}
					<Switch>
						<Route exact path={sitemap["home"]} render={() => <Home openVideoOverlay={this.openVideoOverlay} />} />
						<Route
							exact
							path="/results"
							render={() => <SearchResults openNav={this.handleNavOpen} toggleFilter={this.toggleFilter} busy={transition} />}
						/>
						<Route
							exact
							path={sitemap["work"]}
							render={() => (
								<Work openNav={this.handleNavOpen} toggleFilter={this.toggleFilter} busy={transition} history={history} />
							)}
						/>
						<Route exact path={sitemap["work-detail"]} render={() => <WorkDetail history={history} />} />
						<Redirect exact from={sitemap["who-we-are"]} to={sitemap["who-is-fcb"]} />
						<Route
							exact
							path={sitemap["capabilities"]}
							component={Capabilities}
						/>
						<Route exact path={sitemap["who-is-fcb"]} component={WhoIsFcb} />
						<Route exact path={sitemap["leadership"]} component={Leadership} />
						<Route exact path={sitemap["leadership-detail"]} component={LeadershipDetail} />
						<Route exact path={sitemap["approach"]} component={Approach} />
						<Route exact path={sitemap["never-finished-story"]} component={NeverFinishedStory} />
						<Route exact path={sitemap["de-and-i"]} component={DeAndI} />
						<Route exact path={sitemap["where-we-are"]} component={WhereWeAre} />
						<Route exact path={sitemap["where-we-are-detail"]} component={WhereWeAreDetail} />
						<Redirect exact from={sitemap["press"]} to={sitemap["press-center"]} />
						<Route
							exact
							path={sitemap["press-center"]}
							render={() => (
								<PressCenter
									history={history}
									toggleSubscribe={this.toggleSubscribeOverlay}
									openNav={this.handleNavOpen}
									toggleFilter={this.toggleFilter}
									busy={transition}
								/>
							)}
						/>
						<Route exact path={sitemap["press-center-detail"]} component={PressCenterDetail} />
						<Route exact path={sitemap["press-kit"]} component={PressKit} />
						<Route exact path={sitemap["fcb-feed"]} component={FcbFeed} />
						<Redirect exact from={sitemap["careers"]} to={sitemap["working-at-fcb"]} />
						<Route
							exact
							path={sitemap["working-at-fcb"]}
							render={() => <WorkingAtFcb openVideoOverlay={this.openVideoOverlay} />}
						/>
						<Route exact path={sitemap["join-our-team"]} component={JoinOurTeam} />
						<Route exact path={sitemap["contact-us"]} component={Contact} />
						<Route exact path={sitemap["terms-and-conditions"]} component={TermsAndConditions} />
						<Route exact path={sitemap["ccpa-privacy-notice"]} component={CcpaPrivacyNotice} />
						<Route exact path={sitemap["privacy-policy"]} component={PrivacyPolicy} />
						<Route exact path="/verify/:token" component={Verify} />
						<Route component={NotFound} />
					</Switch>
					<PageTransition busy={busy} transition={transition} history={history} dest={destination} reset={Reset} back={navBack} />
				</DefaultLayout>
			</ConnectedRouter>
		)
	}
}

const mapStateToProps = state => ({
	busy: state.request.busy,
	transition: state.navigation.busy,
	destination: state.navigation.destination,
	navBack: state.navigation.back,
	adaCompliance: state.navigation.adaCompliance,
})

const mapDispatchToProps = dispatch => ({
	Reset: () => dispatch(reset()),
	Navigate: (url, back) => dispatch(navigate(url, back)),
	resetFilter: () => dispatch(resetFilter()),
	updateAdaCompliance: () => dispatch(updateAdaCompliance())
})

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
