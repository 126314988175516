const regions = [
  'North America',
  'Latin America',
  'Europe',
  'Africa',
  'Middle East',
  'Asia Pacific'
];

module.exports = regions;
