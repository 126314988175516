import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import Fade from "react-reveal/Fade"
import { Helmet } from "react-helmet"

import Subnav from "../layout/subnav"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import VideoHero from "../layout/videoHero"
import LineBreak from "../layout/lineBreak"
import CareersList from "../layout/careersList"
import Grid from "../layout/grid"
import DeAndIPageCard from "../layout/deAndIPageCard"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import PdfDownloadList from "../layout/pdfDownloadList"
import PdfDownload from "../layout/pdfDownload"
import RainbowText from '../blocks/rainbowText';
import GridContainer from '../blocks/GridContainer';
import RoundedCTA from '../blocks/RoundedCTA';
import ImageHero from "../layout/imageHero"
import { widont } from "../../utils/utils"


class DeAndI extends Component {
	constructor(props) {
		super(props)
		this.state = {
			requestData: null,
		}
	}

	_widont() {
		widont(`.t-bc, .richtext h2`)
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/de-and-i")
		window.addEventListener('resize', this._widont)
		window.setTimeout(this._widont, 1000)
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data,
		})
		this._widont()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._widont)
	}

	render() {
		const { requestData } = this.state
		const { openVideoOverlay } = this.props
		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const heroImage = requestData.deAndIPage.heroImage,
				title = requestData.deAndIPage.title,
				hideTitle = requestData.deAndIPage.hideTitle,
				content = requestData.deAndIPage.content,
				heroVideo = requestData.deAndIPage.heroVideo,
				goals = requestData.deAndIPage.goals,
				downloads = requestData.deAndIPage.downloads,
				emailContact = requestData.deAndIPage.emailContact,
				otherDetails = requestData.deAndIPage.otherDetails,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href

			return (
				<>
					<Helmet>
						<title>{metaTitle}</title>
						<link rel="canonical" href={metaUrl} />
						<meta name="description" content={metaDesc} />
						<meta property="og:url" content={metaUrl} />
						<meta property="og:title" content={metaTitle} />
						<meta property="og:description" content={metaDesc} />
						<meta property="og:image" content={metaImage} />
						<meta name="twitter:title" content={metaTitle} />
						<meta name="twitter:description" content={metaDesc} />
						<meta name="twitter:image" content={metaImage} />
						<meta name="twitter:image:alt" content={metaTitle} />
					</Helmet>


					<ImageHero
						marginDisabled
						overlayDisabled
						images={heroImage}
						heroTitle={hideTitle ? "" : 'Diversity, Equity, Inclusion & Belonging'} // Changed the 'title' variable
					/>

					{/* content */}
					<section className={`site-content ${!content ? "no-top-padding" : ""}`}>
						<Fade fraction={0.2}>
							<Intro gridSize="sml">
								<section className="richtext module-margin module-margin-disabled drop-last-padding text-left">
									<p>Cultivating a work environment that represents, values, and encourages diverse backgrounds
									and perspectives is necessary to fuel creativity. FCB is deeply committed to our work
									on <em>diversity, equity, inclusion, and belonging</em> (DEIB) and are proud to have a culture where
									every employee is recognized and celebrated for who they are and enjoys a sense of shared
									purpose and belonging.</p>

									<p>In close collaboration with leadership, the FCB DEIB team works to create understanding and
togetherness, in addition to promoting empathy through education, discussion, awareness, and
advocacy. We are invested in providing our people with opportunities for personal and career
growth, learning and development, and community-building. This collaboration happens across
the offices in our global network and vertically through our disciplines to deliver on our
commitment to inclusion for every one of our employees, our clients, and the communities in
which we operate.</p>
								</section>
							</Intro>
						</Fade>

						<Fade fraction={0.2}>
							<Intro gridSize="sml">
								<div style={{
									height: '4px',
									backgroundImage: "url('https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/RainbowGradientHoriztonal.jpeg')",
									backgroundPosition: "center center",
									backgroundSize: "cover",
																	marginTop: '2rem'
								}}>

								</div>
							</Intro>
						</Fade>

						<Fade fraction={0.2}>
							<GridContainer
								className="df aic"
								size="small"
							>
								<section className=" text-left ">
									<span>
										<h4 class="mb2">THE POWER OF DIVERSITY</h4>
										<p>We’ve implemented detailed action plans across three main areas: <strong>our <em>workforce</em>, our
										 <em> workplace</em> and our <em>creative work</em>.</strong></p>

										<p>We are committed to fostering a <strong><em>workforce</em></strong> – across all levels – that is representative of the
										general population and ensures that leadership adopts innovative ways to foster and retain
										talent. We serve our clients best when we maintain a culture where we treat one another with
										respect, kindness, and a generosity of spirit.</p>

										<p>When our people bring their unique backgrounds, perspectives, and experiences to the
										<strong><em> workplace</em></strong>, they deepen our collective sense of purpose and inspire us to be smarter, stronger,
										and braver human beings.</p>

										<p>This investment in human capital unleashes our ability to create <strong><em> work</em></strong> that helps our clients
										engage with the marketplace and their audience in an enlightened and authentic way. We
										believe that brands can play a powerful role for good as shapers of culture, breaking down
										barriers, creating opportunities, and building a more equitable world.</p>
										
										<p>By bringing diverse viewpoints and experiences into our offices and into our work, we become
										stronger, more relevant and more effective.</p>
									</span>

								</section>
							</GridContainer>
						</Fade>

						<Fade fraction={0.2}>
							<Intro gridSize="sml">
								<div style={{
									height: '4px',
									backgroundImage: "url('https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/RainbowGradientHoriztonal.jpeg')",
									backgroundPosition: "center center",
									backgroundSize: "cover",
									marginTop: '2rem'
								}}>

								</div>
							</Intro>
						</Fade>

						<Fade fraction={0.2}>
							<GridContainer
								className="df aic"
								size="small"
							>
								<section className=" text-left ">
									<span>
										<h4 class="mb2">TAKING ACTION ON DEIB</h4>
										<p>FCB’s commitment to transparency reflects our dedication to understanding our current state and defining paths to our goals. Publishing our demographic data builds trust, enables benchmarking against industry standards, and sets measurable DEIB goals to track progress and inspire continuous improvement.</p>
									</span>

								</section>
							</GridContainer>
						</Fade>

						<Fade fraction={0.2}>
							<GridContainer
								className="df aic"
								size="small"
							>
								<section className="mb4 text-left ">
									<p>
										<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/charts-gender-v2.png" className="pl4rem@lg pr4rem@lg pt3rem w100%"></img>
										<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/charts-ethnics-v3.png" className="pl4rem@lg pr4rem@lg pb1rem w100%"></img>
										<span className="ff-nfd">Data as of December 2024</span>
									</p>

								</section>
							</GridContainer>
						</Fade>

						<Fade fraction={0.2}>
							<GridContainer
								className="df aic"
								size="small"
							>
								<section className=" text-left ">
									<span>
										<p>While growth is evident, we recognize more work is needed. We’ve made strong gains in increasing women representation and are putting in structures to increase and maintain BIPOC representation. At FCB, we remain committed to embedding DEIB in everything we do, understanding that this work is <strong><em>Never Finished</em></strong>.</p>
									</span>

								</section>
							</GridContainer>
						</Fade>

						<Fade fraction={0.2}>
							<Intro gridSize="sml">
								<div style={{
									height: '4px',
									backgroundImage: "url('https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/RainbowGradientHoriztonal.jpeg')",
									backgroundPosition: "center center",
									backgroundSize: "cover",
									marginTop: '2rem'
								}}>

								</div>
							</Intro>
						</Fade>

						<Fade fraction={0.2}>
							<GridContainer
								className="df aic"
								size="small"
							>
								<section className=" text-left ">
									<span>
										<h4 class="mb2">DRIVING IMPACT AND INCLUSION</h4>
										<p>Our commitments are demonstrated through a constant and always-evolving flow of new and meaningful DEIB initiatives, including Implicit Bias Workshops, Culture and Inclusion Communities in each local office, as well as strategic partnerships and sponsorships with industry groups and advocates such as: </p>
									</span>
									<p>
									<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/fcb-logos-website-transparent.png" className="pt1rem pb1rem w100%"></img>
									</p>

								</section>
							</GridContainer>
						</Fade>

						
						<Fade fraction={0.2}>
							<Intro gridSize="sml">
								<div style={{
									height: '4px',
									backgroundImage: "url('https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/RainbowGradientHoriztonal.jpeg')",
									backgroundPosition: "center center",
									backgroundSize: "cover",
									marginTop: '2rem'
								}}>

								</div>
							</Intro>
						</Fade>

						<Fade fraction={0.2}>
							<GridContainer
								className="df aic"
								size="small"
							>
								<section className=" text-left ">
									<span>
										<h4 class="mb2">WE ARE BETTER TOGETHER</h4>
										<p>FCB’s Employee Resource Groups (ERGs) are dedicated communities within our organization that champion DEIB. These groups foster a culture of belonging by providing support and amplifying the voices of underrepresented communities. Through collaborative initiatives and advocacy, our ERGs empower employees to connect, learn, and drive meaningful change both within our company and in the communities we serve. Our ERGs, which span our AAPI, Black, Hispanic, Women, Veteran and LGBTQ+ communities, to name a few, support and celebrate the many contributions our employees make, and we’re always open to creating more.</p>
									</span>

								</section>
							</GridContainer>
						</Fade>
					</section>
				</>
			)
		}
	}
}

DeAndI.defaultProps = {
	Request: new Object(),
}

const mapStateToProps = (state) => ({
	Request: state.request,
})

const mapDispatchToProps = (dispatch) => ({
	Fetch: (category) => dispatch(fetch(category)),
})
let connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeAndI)
connectedPage = withRouter(connectedPage)

export default connectedPage
