import { PAGE_TRANSITION_START, PAGE_TRANSITION_END, ADA_COMPLIANCE } from '../actions/pagetransition';

const INITITAL_STATE = {
  busy: false,
  destination: null,
  back: false,
  adaCompliance: true
};

function navigation(state = INITITAL_STATE, action) {
  switch (action.type) {
    case PAGE_TRANSITION_START:
      return {
        ...state,
        busy: true,
        destination: action.destination,
        back: action.back
      };

    case PAGE_TRANSITION_END:
      return {
        ...state,
        busy: false,
        back: false
      };

    case ADA_COMPLIANCE:
      return {
        ...state,
        adaCompliance: !state.adaCompliance,
      };

    default:
      return state;
  }
}

export default navigation;
