import React from 'react';
import PropTypes from 'prop-types';

const paddingClasses = 'pl20 pr20 pl30@smx pr30@smx pl100@lg pr100@lg';

const gridSize = size => (
    size === 'large'  ? 'grid--large'
  : size === 'medium' ? 'grid--medium'
  : 'grid--small'
)

const classNames = (className, off, size) =>
  `grid-container ml-auto mr-auto ${ gridSize(size) } ${ off ? 'grid--off' : '' } ${ paddingClasses } ${ className || '' }`

const GridContainer = ({ className, children, elementType, off, size }) => (
(!elementType)
? <div className={classNames(className, off, size)}>
  { children }
  </div>
: <section className={classNames(className, off, size)}>
  { children }
  </section>
)

GridContainer.propTypes = {
  className : PropTypes.string,
  children  : PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  elementType : PropTypes.string,
  off         : PropTypes.bool,
  size        : PropTypes.string, // small (default), medium, large
};

export default GridContainer;
