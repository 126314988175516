import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import Fade from "react-reveal/Fade"
import { Helmet } from "react-helmet"

import Subnav from "../layout/subnav"
import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import LineBreak from "../layout/lineBreak"
import PresskitDownload from "../layout/presskitDownload"
import PresskitDownloadList from "../layout/presskitDownloadList"
import PresskitCard from "../layout/presskitCard"
import PresskitCardList from "../layout/presskitCardList"
import ContactList from "../layout/contactList"
import ContactCard from "../layout/contactCard"
import Grid from "../layout/grid"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"

const _ = require("lodash")

class PressKit extends Component {
	constructor(props) {
		super(props)

		this.state = {
			requestData: null
		}
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/press-kit")
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	render() {
		const { requestData } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const heroImage = requestData.pressKitPage.heroImage,
				title = requestData.pressKitPage.title,
				hideTitle = requestData.pressKitPage.hideTitle,
				content = requestData.pressKitPage.content,
				mediaIntro = requestData.pressKitPage.mediaIntro,
				mediaAssets = requestData.mediaAssets,
				leadershipIntro = requestData.pressKitPage.leadershipIntro,
				leadership = _.sortBy(requestData.leaderships, ["lastName"]),
				contacts = requestData.pressKitPage.mediaContacts,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<ImageHero marginDisabled images={heroImage} heroTitle={hideTitle ? "" : title} />
				<Subnav marginDisabled />
				<section className="site-content">
					{content && (
						<Intro marginDisabled gridSize="sml">
							<RichText marginDisabled textAlign="center" dangerouslySet>
								{content}
							</RichText>
						</Intro>
					)}

					{/* Media Assets Downloads */}
					{mediaAssets && mediaAssets.length > 0 && (
						<>
							<PresskitDownloadList gridSize="lrg">
								<Grid
									gridItems={mediaAssets}
									rowClass={"flush start nowrap"}
									colnum={3}
									size={"lrg"}
									args={args => ({
										url: args.downloadLink,
										image: args.image,
										title: args.description,
										subtitle: args.type,
										imageAltText: args.imageAltText,
									})}
									Child={PresskitDownload}
								/>
							</PresskitDownloadList>
							<LineBreak gridSize="lrg" />
						</>
					)}

					{/* Leadership Section */}
					{leadershipIntro && (
						<Intro marginDisabled gridSize="sml">
							<RichText marginDisabled textAlign="center" dangerouslySet>
								{leadershipIntro}
							</RichText>
						</Intro>
					)}
					{leadership && leadership.length > 0 && (
						<>
							<PresskitCardList marginDisabled gridSize="lrg">
								<Grid
									gridItems={leadership}
									rowClass={"flush start nowrap"}
									colnum={2}
									size={"lrg"}
									args={args => ({
										url: args.download,
										image: args.card,
										imageAltText: args.cardAltText,
										fullname: `${args.firstName} ${args.lastName}`,
										jobtitle: args.jobTitle
									})}
									Child={PresskitCard}
								/>
							</PresskitCardList>
							<LineBreak gridSize="lrg" />
						</>
					)}

					{/* Page contact section */}
					{mediaIntro && (
						<Intro marginDisabled gridSize="sml">
							<RichText marginDisabled dropLastPadding textAlign="center" dangerouslySet>
								{mediaIntro}
							</RichText>
						</Intro>
					)}
					{contacts && contacts.length > 0 && (
						<ContactList gridSize="lrg">
							<Grid
								gridItems={contacts}
								rowClass={"start"}
								noRows
								maxCols={4}
								size={"lrg"}
								args={args => ({
									data: args,
									classes: "grey",
									marginDisabled: true,
									textAlign: "center",
									pageContact: true
								})}
								Child={ContactCard}
							/>
						</ContactList>
					)}
				</section>
			</>
			)
		}
	}
}

PressKit.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})
let connectedPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(PressKit)
connectedPage = withRouter(connectedPage)

export default connectedPage
