import React from "react"
import RichText from "./richText"

// ContactCard's properties need to be passed as an object named args for example:
// <ContactCard
// 	args={{
// 		data: contact,
// 		marginDisabled: true,
// 		classes: "grey",
// 		textAlign: "center",
// 		pageContact: true
// 	}}
// />

const ContactCard = ({
	args: {
		pageContact,
		marginDisabled,
		textAlign,
		customTitle,
		classes,
		data: { title, role, description, website, phone, mobile, email }
	}
}) => {
	const formatNumber = num => {
		let number = num.replace(/[\s.\-_]/g, "") //remove whitespace and other characters

		return (number.charAt(0) === "+")
			? number.replace(/\(.*?\)/g, "")
			: number
	}

	return (
		<section
			className={"contact-card module-margin " + (marginDisabled ? "module-margin-disabled " : "") + (classes ? classes + " " : "")}
		>
			<RichText
				marginDisabled
				dropLastPadding
				textAlign={textAlign}
				dangerouslySet
			>
				{`
					<h4 ${pageContact && ` class="title"`}>${customTitle ? customTitle : title}</h4>
				${role && pageContact ? `<p class="text-uppercase weight700">${role}</p><br />` : ""}
				${description ? `${description}` : ""}
				<p class="contact-extras">
					${phone ? `<a href='tel:${formatNumber(phone)}' title='${phone}'>T:&nbsp;${phone}</a><br />` : ""}
					
					${mobile ? `<a href='tel:${formatNumber(mobile)}' title='${mobile}'>M:&nbsp;${mobile}</a><br />` : ""}

					${website ? `<a href='${website}' title='${website}'>W:&nbsp;${website}</a><br />` : ""}
					
					${email ? `<a class="wsnw" href='mailto:${email}' title='${email}'>E:&nbsp;${email}</a>` : ""}
				</p>
				`}
			</RichText>
		</section>
	)
}

ContactCard.defaultProps = {
	args: { marginDisabled: false, data: { title: "" } }
}

export default ContactCard
