export const PAGE_TRANSITION_START = 'PAGE_TRANSITION_START';
export function navigate(destination, back = false) {
  return {
    type: PAGE_TRANSITION_START,
    destination,
    back
  };
}

export const PAGE_TRANSITION_END = 'PAGE_TRANSITION_END';
export function reset() {
  return {
    type: PAGE_TRANSITION_END
  };
}

export const ADA_COMPLIANCE = 'ADA_COMPLIANCE'
export function updateAdaCompliance() {
  return {
    type: ADA_COMPLIANCE
  }
}