import { put, takeLatest, call } from 'redux-saga/effects';

import {
  HANDLE_SUBSCRIBE
} from '../actions/subscribe';

import {
  post
} from '../utils/utils';

import {
  subscribeSuccess,
  subscribeFailure
} from '../actions/subscribe';

function* handleSubscribe(request) {
  try {
    const res = yield call(post, `/${request.url}`, request.payload);

    yield put(subscribeSuccess(res));
  } catch (err) {
    yield put(subscribeFailure(err));
  }
}

export const subscribeSaga = [
  takeLatest(HANDLE_SUBSCRIBE, handleSubscribe)
];
