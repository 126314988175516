import React from "react"

const PageLoadPlaceholder = () => (
	<section className="page-load-placeholder">
		<div className="placeholder-container">
			<img src={`https://d1f5kcwhveewqf.cloudfront.net/uploads/loader.gif`} alt="" />
		</div>
	</section>
)

export default PageLoadPlaceholder
