import React, { Component } from "react"
import ReactPlayer from "react-player"

class VideoHero extends Component {
	constructor(props) {
		super(props)

		this.state = {
			videoPlaying: false,
			playerReady: false,
			handleResponsiveStatus: true,
			vidprops: null
		}

		this.exitFullscreen = this.exitFullscreen.bind(this)
		this.stopVideo = this.stopVideo.bind(this)
		this.startVideo = this.startVideo.bind(this)
		this.videoReady = this.videoReady.bind(this)
		this.progress = this.progress.bind(this)
		this.handleResponsive = this.handleResponsive.bind(this)
		this.lastP = 0
	}

	exitFullscreen() {
		if (document.exitFullscreen) {
			document.exitFullscreen()
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen()
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen()
		}
	}

	stopVideo() {
		if (this.state.playerReady) {
			this.setState({
				videoPlaying: false
			})

			if (!this.props.responsive) {
				this.exitFullscreen()
			}
			try {
				window.dataLayer.push({
					event: "video",
					eventCategory: "vimeo",
					eventAction: "vimeo video pause",
					eventLabel: this.state.vidprops.title + " - " + this.state.vidprops.video_id,
					vimeo_playerID: this.state.vidprops.video_id,
					vimeo_playerTitle: this.state.vidprops.title.toLowerCase(),
					vimeo_playerAuthor: this.state.vidprops.author_name.toLowerCase(),
					vimeo_playerAuthorURL: this.state.vidprops.author_url.toLowerCase(),
					vimeo_playerUploadDate: this.state.vidprops.upload_date.toLowerCase(),
					nonInteractive: true,
					videoUrl: this.props.data.iframeWeb,
					videoTitle: this.state.vidprops.title.toLowerCase(),
					videoProvider: 'vimeo',
					videoDuration: this.state.vidprops.duration,
				});
			}
			catch (err) {
				console.error(err)
			}
		}
	}

	startVideo() {
		if (this.state.playerReady) {
			this.setState({
				videoPlaying: true
			})
			try {
				window.dataLayer.push({
					event: "video",
					eventCategory: "vimeo",
					eventAction: "vimeo play",
					eventLabel: this.state.vidprops.title + " - " + this.state.vidprops.video_id,
					vimeo_playerID: this.state.vidprops.video_id,
					vimeo_playerTitle: this.state.vidprops.title.toLowerCase(),
					vimeo_playerAuthor: this.state.vidprops.author_name.toLowerCase(),
					vimeo_playerAuthorURL: this.state.vidprops.author_url.toLowerCase(),
					vimeo_playerUploadDate: this.state.vidprops.upload_date.toLowerCase(),
					nonInteractive: true,
					videoUrl: this.props.data.iframeWeb,
					videoTitle: this.state.vidprops.title.toLowerCase(),
					videoProvider: 'vimeo',
					videoDuration: this.state.vidprops.duration,
				});
			}
			catch (err) {
				console.error(err)
			}
		}
	}

	progress(data) {
		if (data.played == 0) return
		try {
			const t = (Math.floor((data.played * this.state.vidprops.duration) / this.state.vidprops.duration * 4) / 4).toFixed(2);
			// console.log(t)
			if (t > this.lastP) {
				this.lastP = t
				window.dataLayer.push({
					event: "video",
					eventCategory: "vimeo",
					eventAction: "vimeo video " + Math.floor(data.played * 100) + "% Complete",
					eventLabel: this.state.vidprops.title + " - " + this.state.vidprops.video_id,
					vimeo_playerID: this.state.vidprops.video_id,
					vimeo_playerTitle: this.state.vidprops.title.toLowerCase(),
					vimeo_playerAuthor: this.state.vidprops.author_name.toLowerCase(),
					vimeo_playerAuthorURL: this.state.vidprops.author_url.toLowerCase(),
					vimeo_playerUploadDate: this.state.vidprops.upload_date.toLowerCase(),
					nonInteractive: true,
					videoUrl: this.props.data.iframeWeb,
					videoTitle: this.state.vidprops.title.toLowerCase(),
					videoProvider: 'vimeo',
					videoDuration: this.state.vidprops.duration,
					videoPercent: Math.floor(data.played * 100),
				})
			}
		}
		catch (err) {
			console.error(err)
		}
	}

	videoReady() {
		this.setState({
			playerReady: true
		})
		this.lastP = 0
	}

	handleResponsive() {
		this.setState({
			handleResponsiveStatus: window.innerWidth > 768 ? true : false
		})
	}

	componentDidMount() {
		this.handleResponsive()
		window.addEventListener("resize", this.handleResponsive)
		if (this.props.data.iframeWeb) {
			fetch(`https://vimeo.com/api/oembed.json?url=${this.props.data.iframeWeb}`)
				.then(e => {
					e.json()
						.then(e => {
							this.setState({ vidprops: e })
						}).catch(console.error)
				}).catch(console.error)
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResponsive)
	}

	componentDidUpdate(prevProps) {
		const { playVideo } = this.props

		if (playVideo && playVideo !== prevProps.playVideo) {
			this.startVideo()
		}
	}

	render() {
		const { videoPlaying, handleResponsiveStatus } = this.state
		const {
			className,
			marginDisabled,
			responsive,
			data: { fallbackWeb, fallbackMobile, mp4, webm, iframeWeb, iframeMobile }
		} = this.props

		return (
			<section
				className={
					"video-hero module-margin " +
					(marginDisabled ? "module-margin-disabled " : "") +
					(videoPlaying ? "active " : "") +
					(responsive ? "responsive " : "") +
					(className || '')
				}
			>
				{responsive ? null : (
					<div
						className="media-background"
						title={this.props.vidprops.altTagData}
						style={{ backgroundImage: `url("${(handleResponsiveStatus ? fallbackWeb : fallbackMobile)}")` }}
					>
						{(mp4 || webm) && (
							<video loop playsInline autoPlay muted aria-hidden="true">
								{mp4 && <source src={mp4} type="video/mp4" />}
								{webm && <source src={webm} type="video/webm" />}
							</video>
						)}
					</div>
				)}
				{responsive ? null : (
					<button className="btn close" onClick={this.stopVideo}>
						<span className="ico ico-font ico-close" />
					</button>
				)}
				<button className="btn play" onClick={this.startVideo} />
				{iframeWeb && iframeMobile ? (
					<ReactPlayer
						className="video"
						url={handleResponsiveStatus ? iframeWeb : iframeMobile}
						width="100%"
						height="100%"
						playing={videoPlaying}
						loop={false}
						playsInline={true}
						volume={0.3}
						controls={false}
						onPause={this.stopVideo}
						onReady={this.videoReady}
						onProgress={this.progress}
					/>
				) : iframeWeb ? (
					<ReactPlayer
						className="video"
						url={iframeWeb}
						width="100%"
						height="100%"
						playing={videoPlaying}
						loop={true}
						playsInline={true}
						volume={0.3}
						controls={false}
						onPause={this.stopVideo}
						onReady={this.videoReady}
						onProgress={this.progress}
					/>
				) : null}
			</section>
		)
	}
}

export default VideoHero
