import React, { Component } from "react"
import Clock from "react-live-clock"
import cityTimezones from "city-timezones"
import moment from "moment"
import Link from "./link"
import {connect} from "react-redux";
const { detect } = require("detect-browser")
const browser = detect()
const _ = require("lodash")

class ImageHero extends Component {
	constructor(props) {
		super(props)

		this.state = {
			handleResponsiveStatus: true,
			showImageTitle: true,
			timezone: null
		}

		this.dayNight = this.dayNight.bind(this)
		this.setTimezone = this.setTimezone.bind(this)
		this.handleResponsive = this.handleResponsive.bind(this)

		this.timezoneElm = React.createRef()
	}

	dayNight() {
		const { operatingHours } = this.props

		if (operatingHours) {
			const { timezone } = this.state

			var openHour = parseFloat(operatingHours.opening.replace(":", ".")),
				closedHour = parseFloat(operatingHours.closing.replace(":", ".")),
				elm = this.timezoneElm.current,
				current = moment.tz(new Date(), timezone).format("hh:mm"),
				currentHour = parseFloat(current.replace(":", "."))

			if (elm.classList.contains("night", "day") || elm.classList.contains("day")) {
				elm.classList.remove("night", "day")
			}

			if (currentHour < openHour || currentHour > closedHour) {
				elm.classList.add("night")
			} else {
				elm.classList.add("day")
			}
		}
	}

	setTimezone() {
		const { operatingCity, operatingCountry } = this.props
		const cities = [{ kuwait: "kuwait" }]
		let city = operatingCity
		cities.forEach(country => {
			const stad = country[operatingCountry.toLowerCase()]
			if (stad) {
				city = stad
			}
		})

		let cityTimeZones = cityTimezones.findFromCityStateProvince(city) || []
		cityTimeZones.forEach(ctz => {
			const hasCountry = ctz.country.includes(operatingCountry) || operatingCountry.includes(ctz.country)
			if (hasCountry) {
				const { timezone } = ctz
				console.log(ctz)
				this.setState({
					timezone
				})
			}
		})
	}

	handleResponsive() {
		this.setState({
			handleResponsiveStatus: window.innerWidth > 768 ? true : false
		})
	}

	componentDidMount() {
		const { operatingHours, operatingCity } = this.props

		if (operatingHours && operatingCity) {
			this.setTimezone()
		}

		this.handleResponsive()
		window.addEventListener("resize", this.handleResponsive)
	}

	componentDidUpdate() {
		const video = document.querySelector(".image-hero-video")
		if (video) {
			video.load()
			video.play()
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResponsive)
	}

	buildAdditionalOffices(additionalOffices) {
		const n = []
		if(additionalOffices)
		{
			additionalOffices.map((x,i) => {
				if(x.url && x.url.length > 0) {
					n.push(<span key={i}><a href={x.url}>{x.officeName}</a>{i < additionalOffices.length - 1 ? ',' : ''}</span>)
				} else {
					n.push(<span key={i}>{x.officeName}{i < additionalOffices.length - 1 ? ',' : ''}</span>)
				}
			})
		}
		return n
	}

	render() {
		const { handleResponsiveStatus, showImageTitle, timezone } = this.state
		const { overlayDisabled, marginDisabled, images, subtitle, title, heroTitle, link, workBy, workByUrl, isWorkByNull, additionalOffices, gradientBg, scrollToWork, adaCompliance } = this.props

		if (timezone) {
			this.dayNight()
		}

		return (
		<section className={"image-hero module-margin" + (marginDisabled ? " module-margin-disabled " : " ")}>
			{images && (
				<div
					title={(handleResponsiveStatus ? images.heroWebAltText : images.heroMobileAltText)}
					className={"media-background" + (images.mp4 || images.webm ? " cinemagraph" : "") + (overlayDisabled ? " no-overlay " : "")}
					style={{
						backgroundImage: `url("${(handleResponsiveStatus ? images.heroWeb : images.heroMobile)}")`
					}}
				>
					{ adaCompliance && (images.mp4 || images.webm) && (
						<video
							className="image-hero-video"
							loop
							aria-hidden="true"
							playsInline
							autoPlay
							muted
							poster={'https://d1f5kcwhveewqf.cloudfront.net/uploads/bg-blank.png'}
						>
							{images.mp4 && <source src={images.mp4} type="video/mp4" />}
							{images.webm && <source src={images.webm} type="video/webm" />}
						</video>
					)}
				</div>
			)}
			{(gradientBg || !images || _.isEmpty(images)) && (
				<div className={"gradient" + (browser.name === "safari" || browser.name === "ios" ? " browser-safari " : " ")} />
			)}
			{subtitle || title || heroTitle || images ? (
				<div className="content">
					<div className="grid lrg">
						<div className="row">
							<div className="col">
								{title && <div className="subtitle t-h5">{title}</div>}
								{subtitle && <h1 className="title t-h1">{subtitle}</h1>}
								{heroTitle && <h1 className="hero-title h12">{heroTitle}</h1>}
								{link && (
									<div className="link btn btn-carousel" onClick={scrollToWork}>
										View the Work
									</div>
								)}

								{images && images.titleImage && showImageTitle && (
									<img
										src={images.titleImage}
										onError={() => {
											this.setState({ showImageTitle: false })
										}}
										alt="Office"
										className="img-title img-responsive"
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			) : null}
			{workBy && workByUrl && (
				<div className="work-by-alt ff-cp">
					<span>Work by</span> 
					{!isWorkByNull  && 
						<span><a href={workByUrl}>{`${workBy}`}</a>{`${additionalOffices && additionalOffices.length > 0 ? ',' : ''}`}</span>
					}
					{isWorkByNull &&
						<span>{`${workBy}${`${additionalOffices && additionalOffices.length > 0 ? ',' : ''}`}`}</span>
					}
					{this.buildAdditionalOffices(additionalOffices)}
				</div>
			)}
			<div className="timezone" id="timezone" ref={this.timezoneElm}>
				{timezone && (
					<div className="clock">
						<Clock format={"hh:mm "} ticking={true} timezone={timezone} />
						<span>
							<Clock format={"A"} ticking={true} timezone={timezone} />
						</span>
					</div>
				)}
			</div>
		</section>
		)
	}
}

const mapStateToProps = state => ({
	adaCompliance: state.navigation.adaCompliance,
})
export default connect(mapStateToProps, null)(ImageHero)
//export default ImageHero
