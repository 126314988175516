import React, { Component } from "react"
import { TweenMax, ScrollToPlugin } from "gsap/all"

class Actions extends Component {
	constructor(props) {
		super(props)
		this.animateScroll = this.animateScroll.bind(this)
	}

	animateScroll() {
		TweenMax.to(window, 0.65, { scrollTo: 0 })
	}

	render() {
		const { toggleShare, toggleSubscribe, toggleStopAnimation } = this.props

		return (
			<div className="site-actions">
				<button className="btn btn-subscribe" onClick={toggleSubscribe}>
					<span className="ico ico-font ico-email" />
					<span className="btn-text">Subscribe to our newsletter</span>
				</button>
				<button className="btn btn-share" onClick={toggleShare}>
					<span className="ico ico-font ico-share" />
					<span className="btn-text">Share this page</span>
				</button>
				{/*<button className="btn btn-share" onClick={toggleStopAnimation}>
					<span className="ico ico-font ico-eye" />
					<span className="btn-text">Stop Animations</span>
				</button>*/}
				<button className="btn btn-totop" onClick={this.animateScroll}>
					<span className="ico ico-font ico-chevron-up" />
					<span className="btn-text">Back to top</span>
				</button>
			</div>
		)
	}
}

export default Actions
