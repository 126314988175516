import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import { Helmet } from "react-helmet"

import ImageHero from "../layout/imageHero"
import Intro from "../layout/intro"
import RichText from "../layout/richText"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"

class TermsAndConditions extends Component {
	constructor(props) {
		super(props)

		this.state = {
			requestData: null
		}
	}

	componentWillMount() {
		const { Fetch } = this.props

		Fetch("pages/view/terms")
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data
		})
	}

	render() {
		const { requestData } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const title = requestData.termsPage.title,
				hideTitle = requestData.termsPage.hideTitle,
				content = requestData.termsPage.content,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href

			return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<ImageHero marginDisabled gradientBg heroTitle={hideTitle ? "" : title} />
				<section className="site-content">
					<Intro gridSize="med">
						<RichText marginDisabled dropLastPadding textAlign="left" dangerouslySet>
							{content}
						</RichText>
					</Intro>
				</section>
			</>
			)
		}
	}
}

TermsAndConditions.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	Fetch: category => dispatch(fetch(category))
})

let connectedPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(TermsAndConditions)
connectedPage = withRouter(connectedPage)

export default connectedPage
