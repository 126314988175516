import React, { Component } from "react"
import Link from "./link"
import HeaderRive from './headerRive';
import Logo from "../../../img/img/fcb-logo.png";

class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {
			lastScrollPos: 0
		}

		this.handleScroll = this.handleScroll.bind(this)
	}

	handleScroll(target) {
		if (this.state.lastScrollPos > target.pageYOffset) {
			this.setState({
				lastScrollPos: target.pageYOffset
			})
		} else if (this.state.lastScrollPos < target.pageYOffset) {
			this.setState({
				lastScrollPos: target.pageYOffset
			})
		}
		if(this.state.lastScrollPos >= 300) {
			document.querySelector('header').classList.add('fixed')
		} else {
			document.querySelector('header').classList.remove('fixed')
		}
	}

	componentDidMount() {
		window.addEventListener("scroll", event => {
			this.handleScroll(event.currentTarget)
		})
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", event => {
			this.handleScroll(event.currentTarget)
		})
	}

	render() {
		const { lastScrollPos } = this.state
		const { busy, active, openNav, toggleMenu, toggleSearch } = this.props

		return (
			<header className={"header"}>
				<div className="header-flex">
					<div className="left">
						<Link url="/" classProp="logo" title="FCB">
							{/* <span className="ico ico-font ico-fcb-logo" /> */}
							{/* <HeaderRive /> */}
							<img src={Logo} alt="FCB" className="static-logo" style={{marginTop:"5px"}}/>
						</Link>
					</div>
					{!active && (
						<div className="right">
							<button className="btn btn-search" onClick={() => [openNav(), toggleSearch()]}>
								<span className="ico ico-font ico-search" />
							</button>
							<button className="btn btn-menu" onClick={() => [openNav(), toggleMenu()]}>
								<span className="icon" />
							</button>
						</div>
					)}
				</div>
			</header>
		)
	}
}

export default Header
