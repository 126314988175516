import React, { Component } from "react"
import { TweenMax, EasePack, Power0, Power1, ScrollToPlugin } from "gsap/all"

import SiteLoader from "./siteLoader"

class PageTransition extends Component {
	constructor(props) {
		super(props)

		this.animateIn = this.animateIn.bind(this)
		this.animateOut = this.animateOut.bind(this)
		this.animateReset = this.animateReset.bind(this)
	}

	animateIn(cb) {
		const overlay = document.getElementById("transition"),
			html = document.querySelector("html"),
			body = document.querySelector("body")

		TweenMax.to(overlay, 0.6, {
			y: "-100%",
			ease: Power1.easeOut,
			onComplete: () => {
				cb()

				html.classList.add("on-load-fixed")
				body.classList.add("on-load-fixed")
			}
		})
	}

	animateOut() {
		const overlay = document.getElementById("transition")

		TweenMax.to(window, 0.1, { scrollTo: 0 })
		TweenMax.to(overlay, 0.8, {
			y: "-200%",
			ease: Power0.easeNone,
			onComplete: () => {
				const html = document.querySelector("html"),
					body = document.querySelector("body")

				if (html.classList.contains("on-load-fixed")) {
					html.classList.remove("on-load-fixed")
				}
				if (body.classList.contains("on-load-fixed")) {
					body.classList.remove("on-load-fixed")
				}
				this.animateReset()
			}
		}).delay(1)

		// Animation hack see public/js/components/layout/headerRive.jsx
		if(window._rive)
		{
			window._rive.value = false
		}
	}

	animateReset() {
		const overlay = document.getElementById("transition")
		TweenMax.set(overlay, { y: "0%" })
		// Animation hack see public/js/components/layout/headerRive.jsx
		setTimeout(_ => {
			if(window._rive)
			{
				window._rive.value = true
			} else {
				//this.animateReset();
			}
		}, 250)
	}

	componentDidUpdate(prevProps) {
		const { history, dest, transition, reset, busy, back } = this.props
		const loc = location.pathname
		let destination = dest !== null && dest.indexOf("?") > -1 ? dest.substring(0, dest.indexOf("?")) : dest
		
		// encountered weird bug that will break site if dest != loc because of missing leading /
		if(destination && destination[0] !== '/') {
			destination = '/' + destination
		}

		if (!prevProps.transition && transition) {
			this.animateIn(() => {
				if (!back) {
					history.push(dest)
				}
				reset()
			})
		}

		if (prevProps.transition && transition && destination === loc && busy === false) {
			setTimeout(this.animateOut(), 500)
		}
	}

	componentDidMount() {
		this.animateReset();
	};

	render() {
		const { busy } = this.props

		return (
			<section id="" className="site-transition">
{/* 				<SiteLoader busy={busy} scrollfix={this.animateReset} />  */}
			</section>
		)
	}
}

export default PageTransition
