import React from "react"
import Link from "./link"
import Fade from "react-reveal/Fade"

const CtaHero = ({ marginDisabled, gridSize, url, image, imageAltText, subtitle, title }) => (
	<div className={"grid " + (gridSize ? gridSize : "")}>
		<div className="row">
			<div className="col">
				<Link url={url} classProp={"cta-hero module-margin " + (marginDisabled ? "module-margin-disabled " : "")}>
					{/* Image */}
					<div className="over-hidden">
						<Fade left duration={600} fraction={0.4}>
							<div className="image">
								<Fade delay={700} duration={500}>
									<div className="image-container">
										{image && <img src={image} alt={imageAltText} className="img-responsive-max" />}
									</div>
								</Fade>
							</div>
						</Fade>
					</div>

					{/* Information block */}
					<div className="content-container">
						<Fade bottom delay={200} duration={400}>
							<div className="content">
								<Fade delay={400} duration={300}>
									<div>
										{subtitle && (
											<div className="card" style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "start",
											}}>
												<div className="subtitle">
													<span className="line" />
													<div className="t-link">{subtitle}</div>
												</div>
											</div>
										)}
										{title && (
											<div className="title">
												<div className="t-h3">{title}</div>
											</div>
										)}
									</div>
								</Fade>
							</div>
						</Fade>
					</div>
				</Link>
			</div>
		</div>
	</div>
)

export default CtaHero
