import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetch } from "../../actions/request"
import Fade from "react-reveal/Fade"
import { Helmet } from "react-helmet"

import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import VideoHero from "../layout/videoHero"
import LineBreak from "../layout/lineBreak"
import FeatureCta from "../layout/featureCta"
import NeverFinishedCube from "../layout/neverFinishedCube"
import MediaItem from "../layout/mediaItem"
import CtaHero from "../layout/ctaHero"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import CapabilitiesList from "../layout/capabiltiesList"
import CapabilitiesCard from "../layout/capabilitiesCard"
import Grid from "../layout/grid"
import Link from "../layout/link"
import GridContainer from '../blocks/GridContainer';
import FadeComponent from '../blocks/FadeComponent';
import PrimaryLink from '../PrimaryLink';
import RainbowText from '../blocks/rainbowText';
import Modal from 'react-modal';

class Approach extends Component {
	constructor(props) {
		super(props)

		this.state = {
			requestData: null,
			modalOpen: false,
		}

		Modal.setAppElement('#app')
	}

	afterOpenModal = () => {
		const stripeHeight = document.querySelector('.ReactModal__Content').getClientRects()[0].height * 1.25
		document.documentElement.style.setProperty("--bg-modal-height", `${stripeHeight + 300 }px`)
	}

	// componentWillMount() {
	// 	const { Fetch } = this.props

	// 	Fetch("pages/view/approach")
	// }

	// componentWillReceiveProps(nextProps) {
	// 	this.setState({
	// 		requestData: nextProps.Request.data,
	// 	})
	// }

	render() {
		// const { requestData } = this.state
		const customStyles = {
			content: {
				top: '50%',
				left: '50%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '-50%',
				transform: 'translate(-50%, -50%)'
			}
		};
		// if (requestData === null || requestData instanceof Array || requestData === undefined) {
		// 	return <PageLoadPlaceholder />
		// } else {
		const heroImage = {
			heroMobile: "https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/dei-header.jpg",
			heroWeb: "https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/dei/dei-header.jpg"
		},
			title = "Approach",
			hideTitle = false,
			leadershipCta = "",
			neverFinishedCta = "",
			metaTitle = "Approach",
			metaDesc = "",
			metaImage = "",
			metaUrl = location.href

		return (
			<>
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>



				<ImageHero
					marginDisabled
					overlayDisabled
					images={heroImage}
					heroTitle={hideTitle ? "" : title}
				/>

				<section className={`site-content `}>
					<Fade fraction={0.2}>
						<Intro gridSize="med">
							<div className=" pb40 fz24 lh1.66 fz16@md lh1.66@md  pb100@md">
								<div
									className="ff-nfd pb40 pb0@md p-override fz24 lh1.66 fz16@md lh1.66@md"

								>
									<div className="t-h4">Marketing hasn’t changed.<br />Our
									<RainbowText className="mr10 ml10 ff-nfd fsi">
											<span>Never Finished</span>
										</RainbowText>
									approach has.</div>
								</div>
							</div>
						</Intro>
					</Fade>


					{/* Brand Bedrock */}
					<GridContainer
						className="db aic mb100 df@md fxdrrr jcsb"
						size="medium"
					>
						<div className="w100% w45%@md">
							<FadeComponent
								className="oh"
								fadeSettings={{
									right: true,
									duration: 600,
									fraction: 0.3
								}}
								innerWrapperClasses="image bgc-true-black"
								innerFadeSettings={{
									delay: 700,
									duration: 500,
								}}
							>
								<div className="purple-border">
									<MediaItem
										image="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/approach/Tree_v006_Clouds.gif"
										ratio="1-1"
									/>
								</div>
							</FadeComponent>
						</div>
						<div className="w100% pt25 pt0@md w48%@md">
							<div className="t-h4 fsi cta-heading mb5">
								Brand Bedrock&trade;
							</div>
							<div className="mb20">Define the brand purpose</div>
							<div className="t-h5-sentance mb20">
								The North Star for how, where and why the brand shows up
							</div>
							<div
								className="cta-description fz16 fz18@md ff-nfd mb24"
								dangerouslySetInnerHTML={{ __html: `<div class="t-bc">To help define brand purpose, we start by interrogating the brand's past, understanding its present and anticipating its future. Once the brand purpose is defined, the team is able to make better decisions more quickly and with more confidence. A strong Brand Bedrock is the first step toward building brand value over time.</div>` }}
							></div>
							<PrimaryLink
								className="btn btn-link black"
								url={`mailto:development@fcb.com?subject=Request a Brand Bedrock Workshop`}
							>
								<span className="ff-cp fz18">
									Request A Brand Bedrock Workshop
								</span>
							</PrimaryLink>
						</div>
					</GridContainer>

					{/* DEFINITIVE DESIGN */}
					<GridContainer
						className="db aic mb100 df@md jcsb"
						size="medium"
					>
						<div className="w100% w45%@md">
							<FadeComponent
								className="oh"
								fadeSettings={{
									left: true,
									duration: 600,
									fraction: 0.3
								}}
								innerWrapperClasses="image bgc-true-black"
								innerFadeSettings={{
									delay: 700,
									duration: 500,
								}}
							>
								<div className="orange-border">
									<MediaItem
										image="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/approach/hand_ring.gif"
										ratio="1-1"
									/>
								</div>
							</FadeComponent>
						</div>
						<div className="w100%  pt25 pt0@md w48%@md">
							<div className="t-h4 fsi cta-heading mb5">
								Definitive Design&trade;
							</div>
							<div className="mb20">Build powerful brand equity</div>
							<div className="t-h5-sentance mb20">
								Harnessing the branding power of <div className="fsi">distinctive design assets.</div>
							</div>
							<div
								className="cta-description fz16 fz18@md ff-nfd mb24"
								dangerouslySetInnerHTML={{ __html: `<div class="t-bc mr60@md">In this accelerated world, people make decisions in the blink of an eye, through sensory means, without thinking about it logically. Brand design - sonic and visual - is not a "nice to have." It's essential <span class="fsi">to building modern, Never Finished</span> brands.</div>` }}
							></div>
							<PrimaryLink
								className="btn btn-link black"
								url={`mailto:development@fcb.com?subject=Request A Definitive Design Workshop`}
							>
								<span className="ff-cp fz18">
									REQUEST A DEFINITIVE DESIGN WORKSHOP
								</span>
							</PrimaryLink>
						</div>
					</GridContainer>

					{/* The Right BIT */}
					<GridContainer
						className="db aic mb100 df@md fxdrrr jcsb"
						size="medium"
						>
						<div className="w100% w45%@md">
							<FadeComponent
								className="oh"
								fadeSettings={{
									right: true,
									duration: 600,
									fraction: 0.3
								}}
								innerWrapperClasses="image bgc-true-black"
								innerFadeSettings={{
									delay: 700,
									duration: 500,
								}}
							>
								<div className="blue-border">
									<MediaItem
										image="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/approach/Neon_v0051.gif"
										ratio="1-1"
									/>
								</div>
							</FadeComponent>
						</div>
						<div className="w100%  pt25 pt0@md w48%@md">
							<div className="t-h4 fsi cta-heading mb5">
								The Right BIT&trade;
							</div>
							<div className="mb20">Crystallize the business issue</div>
							<div className="t-h5-sentance mb20">
								There's a lot of data out there, but what is the data needed right now?
							</div>
							<div
								className="cta-description fz16 fz18@md ff-nfd mb24"
								dangerouslySetInnerHTML={{ __html: `<div class="t-bc mr60@md">The Right BIT&trade; is designed to connect the data across business and human intelligence. With that information, we can identify a business's fundamental problem or opportunity that will unlock growth.</div>` }}
							></div>
							<PrimaryLink
								className="btn btn-link black"
								url={`mailto:development@fcb.com?subject=Learn More About The Right Bit`}
							>
								<span className="ff-cp fz18">
									LEARN MORE ABOUT THE RIGHT BIT
								</span>
							</PrimaryLink>
						</div>
					</GridContainer>

					{/* People & Patterns */}
					<GridContainer
						className="db aic mb100 df@md jcsb"
						size="medium"
					>
						<div className="w100% w45%@md">
							<FadeComponent
								className="oh"
								fadeSettings={{
									left: true,
									duration: 600,
									fraction: 0.3
								}}
								innerWrapperClasses="image bgc-true-black"
								innerFadeSettings={{
									delay: 700,
									duration: 500,
								}}
							>
								<div className="purple-border">
									<MediaItem
										image="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/approach/People_v0031.gif"
										ratio="1-1"
									/>
								</div>
							</FadeComponent>
						</div>
						<div className="w100% pt25 pt0@md w48%@md">
							<div className="t-h4 fsi cta-heading mb5">
								People &amp; Patterns&trade;
							</div>
							<div className="mb20">Humanize the data</div>
							<div className="t-h5-sentance mb20">
								We put people first.
							</div>
							<div
								className="cta-description fz16 fz18@md ff-nfd mb24"
								dangerouslySetInnerHTML={{ __html: `<div class="t-bc mr60@md">We use data to map behaviors and recognize patterns emerging in the world, both within the category and those that are specific to the business and the brand. With that knowledge, we uncover the most significant opportunities to maximize how and when people engage.</div>` }}
							></div>
							<PrimaryLink
								className="btn btn-link black"
								url={`mailto:development@fcb.com?subject=Request%20A%20People%20%26%20Patterns%20Workshop`}
							>
								<span className="ff-cp fz18">
									REQUEST A PEOPLE &amp; PATTERNS WORKSHOP
								</span>
							</PrimaryLink>
						</div>
					</GridContainer>

					{/* Embracing Creativity: The 456 */}
					<GridContainer
						className="db aic mb100 df@md fxdrrr jcsb"
						size="medium"
					>
						<div className="w100% w45%@md">
							<FadeComponent
								className="oh"
								fadeSettings={{
									right: true,
									duration: 600,
									fraction: 0.3
								}}
								innerWrapperClasses="image bgc-true-black"
								innerFadeSettings={{
									delay: 700,
									duration: 500,
								}}
							>
								<MediaItem
									image="https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/approach/4-5-6.png"
									ratio="1-1"
								/>
							</FadeComponent>
						</div>
						<div className="w100% pt25 pt0@md w48%@md">
							<div className="t-h4 fsi cta-heading mb20">
								Embracing Creativity
							</div>
							<div className="t-h5-sentance mb20">
								Make people care about the brand
							</div>
							<div
								className="cta-description fz16 fz18@md ff-nfd mb24"
								dangerouslySetInnerHTML={{ __html: `<div class="t-bc mr60@md">All of our tools play a part in helping us deliver 456 creative solutions. The 456 benchmarking tool allows us to have an objective conversation about something that's inherently subjective: creativity. With the 456, we can ask more informed questions to arrive at more specific feedback that will lead to big ideas that are <span class="fsi">Never Finished</span>.</div>` }}
							></div>
							<div
								className="btn btn-link black"
								onClick={_ => this.setState({ modalOpen: true })}
							>
								<span className="ff-cp fz18" >Learn about our 456 scale</span>
							</div>
						</div>
					</GridContainer>
				</section>
				<Modal
					isOpen={this.state.modalOpen}
					style={customStyles}
					contentLabel="456 Scale"
					overlayClassName="ReactModal__Overlay_Mod"
					onAfterOpen={this.afterOpenModal}
				>
					<div className="w50vw@md w100% pl1rem mr1rem mr2.5rem@md df fxdrc posr">
						<div className="df fxdrc jcc aifs pl2rem pr1rem pl2rem@md pr2rem@md">
							<div onClick={_ => this.setState({ modalOpen: false })} className="modal-close df jcfe aife curp posa">
								<div className="df fxdrc jcc aic">
									<div>
										<svg width="20px" height="20px" viewBox="0 0 28 28">
											<g id="People-North-America" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
												<g id="FCBCHI_People_Modal" transform="translate(-1037.000000, -296.000000)">
													<g id="@Modal" transform="translate(325.000000, 257.000000)">
														<rect id="Rectangle" stroke="#979797" fill="#FFFFFF" x="0.5" y="1" width="791" height="527"></rect>
														<g id="Close" transform="translate(701.000000, 40.500000)" fillRule="nonzero" stroke="#000000" strokeLinecap="square" strokeWidth="4">
															<path d="M13.5,0 L38.5,25 L13.5,0 Z" id="Line"></path>
															<path d="M37.5,0 L12.5,25 L37.5,0 Z" id="Line"></path>
														</g>
													</g>
												</g>
											</g>
										</svg>
									</div>
								</div>
							</div>
							<div className="t-h4 fsi cta-heading pb20">Embracing Creativity</div>
							<div className="t-h5-sentance` pb20">Make people care about the brand</div>
							<div className="mb-sm fz18 pb20">We’ve created the 456 to consistently and objectively evaluate creative. We use this system to rate our competitors and, more important, rate ourselves. It keeps us honest. It helps ensure our work is working. It pushes us to keep innovating and creating equity-building creative for our clients.</div>
							<div className="mb-sm w100% grid-456">
								{
									Array.from(['Damaging', 'Invisible', 'Noticed', 'Provocative', 'Creates Behavior', 'Never Finished'], (x, i) => {
										return (
											<div key={i} className="df fxdrc jic aic number-holder">
												<div className="">
													<img className="responsive" src={`https://d1f5kcwhveewqf.cloudfront.net/uploads/pages/approach/scale-${i + 1}.jpeg`} alt={`scale number  `} />
												</div>
												<div className="ff-cp fz10 fw700 lh1.5" dangerouslySetInnerHTML={{ __html: x }}></div>
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
				</Modal>
			</>
		)
		// }
	}
}

Approach.defaultProps = {
	Request: new Object(),
}

const mapStateToProps = (state) => ({
	Request: state.request,
})

const mapDispatchToProps = (dispatch) => ({
	Fetch: (category) => dispatch(fetch(category)),
})
let connectedPage = connect(mapStateToProps, mapDispatchToProps)(Approach)
connectedPage = withRouter(connectedPage)

export default connectedPage
